/*Global Variable section  */

:root {

  --primary-color: #c5261b;
  --secondry-color: #18206f;
  --ternary-color: green;
  --black-color: #333;

}

body {
  font-family: Raleway, Arial, sans-serif !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  line-height: 1.9 !important;
}

:root {

  --span-primary-font-size: 17px;
  --span-secondry-font-size: 20px;
  --span-color: #c5261b;
  --span-font-weight: 400;
  --span-line-height: 20px;

}

:root {

  --p-primary-font-size: 17px;
  --p-secondry-font-size: 20px;
  --p-color: blue;
  --p-font-weight: 400;
  --p-line-height: 20px;

}

:root {
  --button-border: none;
  --button-bg-color: red;
  --button-padding: 10px;
  --button-line-height: 10px;
  --button-text-color: white;
}

a {
  text-decoration: none !important;
  color: var(--secondry-color);
}



/* loader section start */

.loader {
  width: 100%;
  height: 100vh;
  background-color: transparent;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 9999;
}

.spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid var(--secondry-color);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

*:focus {
  box-shadow: none !important;
}

/* loader section end */



/*Global Variable section  */

html {
  scroll-behavior: smooth;
}

.twitter-hover {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  background-color: none !important;
  transition: all 0.3s ease;
}

.twitter-hover:hover span {
  transform: translateX(4px);
  display: block;
  position: relative;
}

.twitter-hover span {
  color: red;
  transform: translateX(320px);
  transition: all 0.3s ease;
  display: none;
  position: absolute;
}

.twitter-hover i {
  color: red;
}


.fixed-top {
  top: -60px !important;
  transform: translateY(60px);
  transition: transform .6s;
  opacity: .9;
  -webkit-box-shadow: 0 2px 5px rgb(0 0 0 / 25%);
  /* box-shadow: 0 2px 5px rgb(0 0 0 / 25%); */
  /* -webkit-transform: translateY(-100%); */
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  /* transform: translateY(-100%); */

}

/* click to scroll start*/


.scrollShow {
  position: fixed;
  right: 20px;
  bottom: 80px;
  transition: 0.3s;
  padding: 14px;
  width: 45px;
  text-decoration: none;
  font-size: 20px;
  height: 45px;
  z-index: 9;
  /* background-color: #c5261b; */
  background-color: #18206f;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
}

.scrollShow:hover {
  background-color: var(--primary-color);
  transition: all ease-in-out .3s;
  font-size: 23px;
  padding: 12px;
}

.moveScroll-icon {
  position: absolute;
  animation: moveTopBottom 2s ease-in-out infinite alternate-reverse both;
}

@keyframes moveTopBottom {
  0% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(1px);
  }
}

.top-header {
  margin-top: 4px;
  margin-bottom: 3px;
}

.left-item {
  display: flex;
  justify-content: space-around;
  margin-top: 6px;
}

.left-item span a {
  color: var(--secondry-color);
  font-size: 12px;
  font-weight: 500;
}

.left-item span a:hover {
  color: var(--primary-color);
}

.social-media-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.social-media .btIcoHolder {
  background-color: var(--primary-color) !important;
}

/* .social-media {
  background-color: transparent;
  padding: 9px;
  margin: 7px 5px 0 0;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
  transition: all ease-in-out .3s;
} */

.social-media {
  padding: 9px;
  margin: 7px 5px 0 0;
  color: white;
  cursor: pointer;
  font-size: 20px;
  transition: all ease-in-out .3s;
}

.social-media:hover {
  /* border: 2px solid var(--primary-color); */
  color: var(--primary-color);
  transition: all ease-in-out .3s;
}

.custom-facebook {
  padding: 9px 7px 0 0;
}

.custom-youtube {
  padding: 8px 7px;
}

.footer-social-media {
  /* background-color: white; */
  margin-top: 8px;
}

.footer-social-media .topRight-icon-style {
  font-size: 15px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  padding-left: 8px;
  cursor: pointer;
  transition: all ease-in-out .1s;

}

.topRight-icon-style:hover {
  transform: scale(1.144);
  transition: all ease-in-out .1s;
}

.footer-button button {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 6px;
  line-height: 27px;
  margin: 5px 5px 3px 0px;
  padding: 3px 9px;
}

.footer-button button:hover {
  color: var(--primary-color);
}

.footer-playstore-icon img {
  display: inline;
  margin: 0 8px 5px 0px;
  border-radius: 6px;
  padding: 10;
  width: 120px;
  object-fit: fill;
  /* border: 1px solid white; */
  /* box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); */
}

.footer-playstore-icon img:hover {
  opacity: .6;
  cursor: pointer;
}

.f-copywrite,
.f-copywrite a {
  color: #c3c4c7;
}

nav {

  background-color: white;
  height: 90px;
  position: fixed;
  width: 100%;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  background-color: white;
  list-style: none;
  padding: 0;
  line-height: 1;
  position: relative;
  z-index: 99;
}

nav .navigation-brand {
  line-height: 50px;
  margin-left: 20px;
  color: black;
  font-weight: bold;
  font-size: 18px;
}

nav .navigation-list {
  height: 50px;
  line-height: 50px;
}

nav .navigation-list li {
  display: inline-block;
  margin: 0 10px;
  font-size: 16px;
}

nav .navigation-list li a {
  color: white;
}

nav .toggle {
  display: none;
}

button {
  border: 0;

}

a:hover {
  color: var(--primary-color);
}

.nav-collsaped {
  position: fixed !important;
  background-color: black;
  left: 0 !important;
  transition: 0.5s;
}

.nav-link {
  display: block;
  padding: 0.1rem 1rem;
  color: var(--secondry-color) !important;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  text-decoration: none;
  border-bottom: 3px solid white;
  transition: all ease-in-out .8s;
  transition: all 300ms ease;
  font-family: Lato, Arial, sans-serif;

}


/* ######################## cool link bottom border start ########################################*/
.cool-link {
  display: inline-block;
  color: var(--black-color);
  text-decoration: none;
  cursor: pointer;

}

.cool-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: var(--primary-color);
  transition: width .3s;
}

.cool-link:hover::after {
  width: 100%;
  transition: width .3s;
}

.nav-link:focus,
.nav-link:hover {
  color: var(--primary-color) !important;
}

.cool-link:focus::after {
  width: 100%;
  transition: width .3s;
}


/* ################################# cool link bottom border end ###################################*/



.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: center !important;
}


hr {
  margin: 0.1rem 0rem !important;
  opacity: 0.2;
}

hr:not([size]) {
  height: 0.1px;
}

.icon-style {
  font-size: 14px;
  width: 18px;
  line-height: 30px;
  color: var(--secondry-color);
}

.topRight-icon-style {
  font-size: 17px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  transition: all ease-in-out .1s;
}

.topRight-icon-style:hover {
  font-size: 17px;
  transition: all ease-in-out .1s;
}

.social-media-section div a:nth-child(1),
.f-social-media-section span a:nth-child(1) {
  color: var(--secondry-color);
  font-size: 12px !important;
}

.social-media-section div a:nth-child(3),
.f-social-media-section span a:nth-child(3) {
  color: #4267B2;
  font-size: 15px;
}

/* .social-media-section div:nth-child(2),
.f-social-media-section span:nth-child(2) {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
} */
.social-media-section div:nth-child(2) {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  -webkit-background-clip: text;
  background-clip: text;
  /* -webkit-text-fill-color: transparent; */
}

.social-media-section div:nth-child(4),
.f-social-media-section span:nth-child(4) {
  color: #1da1f2;
}

.social-media-section div:nth-child(5),
.f-social-media-section span:nth-child(5) {
  color: var(--primary-color);
  font-size: 15px;
}

.social-media-section div:nth-child(6),
.f-social-media-section span:nth-child(6) {
  color: #25D366;
}

.topHeaderBtn button {
  background: var(--secondry-color);
  color: #fff;
  font-size: 12px;
  margin-left: 14px;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  padding: 6px 30px 6px 20px;
  text-align: start;
  position: relative;
  border-radius: 2px;
  font-family: Lato, Arial, sans-serif;
}

.highlightObitTitle {
  font-size: 26px;
  font-weight: 700;
  color: #18206f;
  text-transform: uppercase;
}

.fa-calculator {
  font-size: 15px;
  position: absolute;
  left: 8px;
  top: 8px;
  transition: all ease-in-out .6s;
}

.topHeaderBtn:hover .fa-calculator {
  transform: scale(1.2);
  transition: all ease-in-out .6s;
}


.body-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  display: inline !important;
  transition: 5s;
}

.focus {
  outline: none;
}

/* .dropdown:hover .dropdown-menu {
  display: block;
  transition: all ease-in-out .3s;
  background-color: white;
  color: black;
  border-right: 4px solid white;
  font-weight: 700;
  padding: 0px;
  text-align: right;
  line-height: 35px;
  margin-top: 1px;
} */


.dropdown-menu {
  margin-top: 0;
  border-radius: 0;
  box-shadow: 0px 1px 4px !important;
  width: 23rem;
  line-height: 35px;
}

.dropdown-item {
  transition: all ease-in-out .2s !important;
  transform: translateX(1px) !important;
  border-bottom: 1px solid rgba(0, 0, 0, .1) !important;
  color: var(--secondry-color) !important;
  text-align: right !important;
  align-self: center !important;
  font-size: 15px !important;
  font-weight: 700 !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: var(--primary-color) !important;
  background-color: transparent !important;
  border-right: 4px solid var(--primary-color) !important;
  transition: all ease-in-out .2s !important;
  transform: translateX(-1px) !important;
  font-weight: 700;
}

.dropdown-toggle::after {
  content: none !important;
}

.top-header {
  display: flex;
  justify-content: space-between;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0rem;
  padding-left: 3rem !important;
}

.navbar-toggler {
  line-height: 1;
  background-color: var(--primary-color) !important;
  border: 1px solid transparent;
  border-radius: 0 !important;
  transition: none !important;
  padding: 7px 10px !important;
  color: black;
}

.fa-stream:before {
  content: "\2630";
  color: white;
  line-height: 20px;
  font-size: 20px;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none !important;
}

@media (max-width: 767px) {
  nav .toggle {
    display: inline-block;
    background-color: transparent;
    border: 1px solid transparent;
    outline: none !important;
    font-size: 33px;
    color: white;
    font-family: serif;
    font-weight: bold;
    margin-top: 6px;
    right: 0;
    position: absolute;
  }

  nav .toggle:hover,
  nav .toggle:focus {
    color: #d6d6d6;
  }

  nav .toggle>div {
    width: 25px;
    height: 2px;
    background-color: white;
    margin: 6px 0;
    transition: 0.3s;
  }

  nav .navigation-list {
    height: 100%;
    position: fixed;
    left: -300px;
    margin-top: 0px;
    width: 240px;
    transition: 600ms cubic-bezier(0.42, 0, 0, 0.99);
    overflow-x: hidden;
  }

  nav .navigation-list li {
    display: block;
  }

  nav .overlay {
    width: 100%;
    height: 100%;
  }
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 1%;
  right: 100%;
  margin-top: -1px;
}

.navbar-nav li:hover>ul.dropdown-menu {
  display: block;
}

/* Home Video section start */
.home-video-section {
  position: relative;
  background-color: black;
  height: 80vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}

.home-video-section video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.home-video-section .container {
  position: relative;
  /* z-index: 2; */
}

.service-contact-number {
  color: var(--secondry-color) !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: .1;
  /* z-index: 1; */
}

.home-video-section p {
  font-size: 20px !important;
  font-weight: 500 !important;
}


.home-video-section h1 {
  font-size: 50px !important;
  font-weight: 500 !important;
}

/* Home Video section start */

/* space creator start  */
.space-creator hr {
  margin-top: 20px;
  color: transparent;
  padding-left: 10px;
  padding-right: 16px;
}

/* space creator end  */


.rowItem {
  padding: 0 20px;
}

.btCounterHolder {
  color: var(--secondry-color);
}

.btSiteFooter {
  border-top: 2px solid rgba(128, 128, 128, .1);
  background: var(--secondry-color);
  color: #fff;
}

/* Design s dream effects start  */
.card-hover {
  transition: all ease-in-out .6s;
}

.card-hover:hover {
  background-color: var(--secondry-color);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  color: white;
  transition: all ease-in-out .6s;
}
.card-hover:hover .events-buuton{
  background-color: white;
  color: var(--secondry-color);
  transition: all ease-in-out .3s;

}
.other-blogs-detail-active {
  color: var(--primary-color) !important;
}

.product-feature:hover {
  /* background-position: right 40%;
  background-image: url(../public/assets/images/tracking2.png);
  background-repeat: no-repeat;
  box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, .1); */
  /* background-image: linear-gradient(0deg, rgba(6, 6, 6, 0.578), rgba(4, 4, 4, 0.573)),url(./images/ai-dash-cams.jpg);
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    color: white;
    transition: all ease-in-out .6s; */
}

/* .product-feature:hover header.extrasmall h4, .product-feature:hover .btIco .btIcoHolder:before{
  color: white;
} */

/* top insta hover dropdown style start */

/* Hover dropdown */
/* .hover_drop_down.input-group-btn ul.dropdown-menu{margin-top: 0px;}
.hover_drop_down.btn-group ul.dropdown-menu{margin-top:2px;} */
.hover_drop_down:hover ul.dropdown-menu {
  display: block;
}

.top-insta-dropdown-style {
  margin-top: 30px !important;
  border-radius: 0;
  box-shadow: 0 !important;
  line-height: 18px;
  color: var(--secondry-color) !important;
  width: 15em !important;
  white-space: nowrap;
  transition: all ease-in-out .2s !important;
  background-color: white !important;
  /* right: 1.4em; */
}
.react-tel-input .form-control {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 0 !important;
  height: 42px !important;
  width: 100% !important;
}
.top-insta-dropdown-style li{
  text-align: right;
}

.top-insta-dropdown-style li:hover {
  cursor: pointer;
  /* transform: scaleX(1.06); */
  transition: all ease-in-out .2s;
  color: var(--primary-color) !important;
  border-right: 3px solid var(--primary-color);
}
.social-media-section .top-insta-dropdown-style a:nth-child(1):hover{
  color: var(--primary-color) !important;
}
.card-hover:hover .header.extrasmall h4,
.card-hover:hover .btIco .btIcoHolder:before {
  color: white;
  transition: all ease-in-out .6s;
}

/* top insta hover dropdown style end */

/* Design s dream effects end  */


/* client card effects start */
.client-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all ease-in-out .7s;
  background-color: rgb(224 234 245);
  min-height: 14em;
  height: 100%;
}

.client-card:hover {
  box-shadow: rgba(153, 140, 235, 0.25) 0px 14px 28px, rgba(59, 56, 104, 0.584) 0px 10px 10px;
  transition: all ease-in-out .7s;
}


/* client card effects end */

/* why join card start */
.why-join-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all ease-in-out .7s;
  background-color: rgb(224 234 245);
  border: 1px solid white;
}

.why-join-card:hover {
  transform: translateY(-10px);
  border: 1px solid var(--secondry-color);
}

/* why join card  end */

/* click to scroll start*/


.scrollShow {
  position: fixed;
  right: 20px;
  bottom: 50px;
  transition: 0.3s;
  padding: 14px;
  width: 45px;
  text-decoration: none;
  font-size: 20px;
  height: 45px;
  z-index: 9;
  /* background-color: #c5261b; */
  background-color: var(--primary-color);
  border-radius: 6%;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
}

.scrollShow:hover {
  background-color: var(--secondry-color);
  transition: all ease-in-out .3s;
}

.moveScroll-icon {
  position: absolute;
  animation: moveTopBottom 2s ease-in-out infinite alternate-reverse both;
}

@keyframes moveTopBottom {
  0% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(1px);
  }
}


/* click to scroll end*/
/* service card hover start */
.service-card {
  transition: all ease-in-out .6s;
  height: 20em !important;
}

.service-card:hover {
  transition: all ease-in-out .6s;
  transform: scale(1.1);
}


/* service card hover end */


/* login card section start new theme*/
.login-card-container {
  position: relative;
}

.login-card {
  position: absolute;
  top: 6em;
  left: 15%;
  right: 15%;
  margin: auto 0;
  background-color: white;
  border-radius: 30px;
  height: 80vh;
  padding: 30px;
}

.login-left-item,
.login-right-item {
  position: relative;
}

.login-left-container {
  position: absolute;
  top: 3em;
  left: 20px;
  width: 90%;
  margin: auto 0;
  height: 50vh;
  padding: 20px;
}

.login-left-container h2 {
  font-size: 2.5em;
  font-weight: 900;
  text-align: center;
}

.login-left-container label,
.login-right-container label {
  color: #b9b9bb;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
}

.login-left-container .form-control {
  display: block;
  width: 100%;
  padding: .5rem 0.75rem;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.login-right-container {
  position: absolute;
  top: 2.5em;
  right: 5%;
  width: 100%;
  margin: auto 0;
}

.login-right-container .carousel-indicators [data-bs-target] {
  background-color: white !important;


}

.login-right-container .carousel-item img {
  height: 60vh;
  border-radius: 30px;
}

.password-style {
  display: flex;
  justify-content: space-between;
  margin: 5px 0 0;
}

.login-button button {
  width: 100%;
}

.dont-have-account {
  text-align: center;
}

.dont-have-account a {
  font-size: 16px;
}

.dont-have-account label {
  color: var(--secondry-color);
  font-size: 12px;
}

/* login card section end */

/* slick slider start */
.slick-slide img {
  display: block;
  width: 100%;
  height: 350px;
  overflow: hidden;
  opacity: .8;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-prev:before,
.slick-next:before {
  opacity: 0;
  display: none;
}

.slick-dots {
  position: absolute;
  bottom: 30px !important;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li button:before {
  color: white !important;
}

/* slick slider end */


/* industry we serve list style start */
.industry-we-serve-custom-list ul {
  --icon-space: 1.3em;
  list-style: none;
  padding: 0;
}

.industry-we-serve-custom-list li {
  padding-left: var(--icon-space);
  margin-bottom: 10px;
}

.industry-we-serve-custom-list li:before {
  content: "\2611";
  /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  margin-left: calc(var(--icon-space) * -1);
  width: var(--icon-space);
  color: var(--primary-color);
  font-size: 15px;

}


/* industry we serve list style end */

/* services sloider start */
.services-carousel-img .slick-slide img {
  height: 250px !important;
  display: block;
  width: 100%;
}

/* services slider end */



/* steps form start */
/* your CSS goes here*/



.checked-item,
.not-checked-item {
  /* background-color: #ccc; */
  color: #767a9b;
  padding: 5px;
  display: flex;
  font-family: sans-serif;
  justify-content: center;
  justify-items: center;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  height: 17vh;
  text-align: center;
  width: 11vw;
  transition: all 0.6s ease;
  border: 3px solid #767a9b;
}

.checked-item {
  background-color: white;
  border: 3px solid var(--primary-color);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: var(--primary-color);
}

.hide {
  display: none;
}

.multistepCheckbox {
  display: none;
}

.multiStepCheckFormButton {
  background-color: transparent !important;
  color: grey !important;
  border: none;
  padding: 10px 20px;
  font-size: 17px;
  font-family: Raleway;
  cursor: pointer;
  border-radius: 2px;
}

button {
  background-color: var(--secondry-color);
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 17px;
  font-family: Raleway;
  cursor: pointer;
  border-radius: 2px;
}

.request-card {
  height: 100px;
}

#prevBtn {
  background-color: var(--primary-color);
  border-radius: 2px;
}

#proof {
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 20px;
  box-shadow: 2px 2px 8px 0px #999;
}

.cl-custom-check {
  display: none;
}

.cl-custom-check+.cl-custom-check-label {
  background-color: #ccc;
  color: var(--secondry-color);
  padding: 20px;
  font-family: sans-serif;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 4px;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  height: 100%;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.6s ease;
  border: 2px solid white;
}

.cl-custom-check:checked+.cl-custom-check-label {

  background-color: white;
  border: 2px solid var(--primary-color);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: var(--primary-color);
  /* transform: rotateY(360deg); */
}

.requestDemo-form-icon {
  font-size: 31px;
}


/* On mouse-over, add a grey background color */

.container:hover input~.checkmark {
  background-color: #ccc;
}

/* steps form end */


/* radio button multiple steps form start */

.proof {
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 2px 2px 8px 0px #999;
}

.cl-custom-radio {
  display: none;
}

.cl-custom-radio+.cl-custom-radio-label {
  /* Unchecked style  */
  background-color: #ccc;
  color: var(--secondry-color);
  padding: 20px;
  font-family: sans-serif;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 4px;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  height: 100%;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.6s ease;
  text-align: center;
  border: 2px solid #ccc;
}

.cl-custom-radio:checked+.cl-custom-radio-label {
  /* Checked style  */
  background-color: white;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}



input[type="radio"] {
  display: none;
}

/* The label is what's left to style. 
As long as its 'for' attribute matches the input's 'id', it will maintain the function of a radio button. */
.number-of-vehicles+label {
  padding: 2em;
  display: inline-block;
  border: 1px solid grey;
  cursor: pointer;
}

.blank-label {
  display: none;
}

/* input[type="radio"]:checked + label {
  background: grey;
  color: #fff;
} */
/* radio button multiple steps form end*/

/* ######################### faq style start ################################# */
.accordion-button:not(.collapsed) {
  color: var(--secondry-color) !important;
  background-color: none;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
  font-family: Raleway !important;
}

.accordion-button:not(.collapsed) {
  color: white !important;
  background-color: var(--secondry-color) !important;
  box-shadow: none !important;
  font-family: Raleway !important;
}

.accordion-header {
  margin-bottom: 0;
  font-size: 19px !important;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: baseline !important;
  width: 100%;
  padding: 1rem 1.25rem;
  font-family: Raleway !important;
  font-size: 1.5rem;
  color: white;
  text-align: left;
  background-color: var(--secondry-color);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
}

.accordion-button:not(.collapsed)::after {
  content: "−" !important;
  background-image: none !important;
  transform: rotate(0deg) !important;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "+" !important;
  font-weight: 900;
  background-image: none;

}

.accordion-button:focus {
  z-index: 3;
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

/* ######################### faq style end ################################# */
.pages-faq .accordion-button {
  position: relative;
  display: flex;
  align-items: baseline !important;
  width: 100%;
  padding: 1.3rem 1.25rem;
  font-size: 1.7rem;
  color: white;
  text-align: left;
  background-color: var(--secondry-color);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
}

.pages-faq .accordion-body {
  font-size: 14px;
}

/* ######################### faq pages style start ################################# */



/* ######################### faq pages style end ################################# */

/* ######################### Access  control section start ################################# */

.access_card {
  margin: 0px 10px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  border-radius: 0.375rem;
  word-wrap: break-word;
  background-color: #9bcaef;
  background-clip: border-box;
  transition: 0.25s ease-in-out;
  box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, .1);
  /* border: 1px solid var(--secondry-color); */
  height: 100%;
}

.access_card_img {
  max-height: 100%;
  max-width: 100%;
}

.access_card_img img {
  height: 100px;
  width: 60px;
  transition: 0.25s all;
}

.access_card_img img:hover {
  transform: translateY(-10%);
}

.access_card_content {
  padding: 16px;
}

.access_card_content h4 {
  text-transform: none;
  color: var(--secondry-color);
  font-weight: 500;
}


.access_card:hover {
  transform: scale(1.015);
  border-radius: 0.375rem;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.25);
  /* border: 1px solid white; */
  border-bottom: 3px solid var(--primary-color);
  /* border-radius: 0 60px 0; */
  background-position: center 43%;
  background-repeat: no-repeat;
  box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, .1);
}

.access_card:hover .access_card_img img {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}


/* ######################### Access  control section end ################################# */

/* ######################### security section start ################################# */

/* ######################### security section end ################################# */


/* progressbar style start */


.contact-us-form .form-control,
.multistep-form .form-control {
  padding: 1rem 0.75rem;
  font-size: 1.4rem;
}

.contact-us-form label {
  font-size: 14px;
}

.contact-us-form span {
  font-size: 14px;
}

.contact-us-button {
  background-color: var(--secondry-color);
}

.contact-us-title h4 {
  color: var(--secondry-color) !important;
  font-size: 32px !important;
}

.contact-us-form h2 span {
  color: var(--primary-color) !important;
  font-size: 20px;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  color: #6c7581 !important;
  border: 1px solid rgba(0, 0, 0, .1) !important;
  border-radius: 2px !important;
  background: white !important;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
  z-index: 2 !important;
}


html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}


.btLightSkin .btLatestPostsContainer .btSingleLatestPostContent .header.small h4 a,
.btDarkSkin .btLightSkin .btLatestPostsContainer .btSingleLatestPostContent .header.small h4 a {
  color: var(--secondry-color);
}

.btnFilled {
  background: var(--secondry-color);
  color: #fff;
  cursor: pointer;
}

.btnFilled:hover {
  background: #1c4569;
  color: #fff;
  cursor: pointer;
}

.btLightSkin .btnFilled.btnAccentColor,
.btLightSkin .btnOutline.btnAccentColor:hover,
.btDarkSkin .btLightSkin .btnFilled.btnAccentColor,
.btDarkSkin .btLightSkin .btnOutline.btnAccentColor:hover,
.btDarkSkin .btnFilled.btnAccentColor,
.btDarkSkin .btnOutline.btnAccentColor:hover,
.btLightSkin .btDarkSkin .btnFilled.btnAccentColor,
.btLightSkin .btDarkSkin .btnOutline.btnAccentColor:hover {
  background: #063156;
  color: #fff;
}

.btLightSkin a,
.btDarkSkin .btLightSkin a {
  color: #333;
  -webkit-transition: color 300ms ease;
  -moz-transition: color 300ms ease;
  transition: color 300ms ease;
}

.btIco.extrasmall .btIcoHolder:before {
  font-size: 15px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-weight: 300;
}

.btSiteFooterWidgets,
.btDarkSkin .btSiteFooterWidgets {
  background: var(--secondry-color);
  color: #fff;
}

.btSiteFooterWidgets .btBox h4 {
  /* color: #2995f1; */
  color: white;
  font-weight: 900;
  text-transform: capitalize;
  font-size: 18px;
}

.btSiteFooterWidgets .btBox h4 :after {
  display: block;
  content: " ";
  /* border-top: #fff 3px solid; */
  width: 50px;
  margin-top: 10px;
}

.btSiteFooterWidgets .btBox a {
  color: #FFF;
  -webkit-transition: color 300ms ease;
  -moz-transition: color 300ms ease;
  transition: color 300ms ease;
}

.btSiteFooterWidgets .btBox a {
  color: #c3c4c7;
  transition: color 300ms ease;
  font-size: 14px;
  font-weight: 400;
}

.footer-p p {
  color: #c3c4c7;
}

.btSiteFooterWidgets .btBox a:hover {
  color: var(--primary-color);
  transition: color 300ms ease;
}

/* cargo css start */

html {
  font-size: 62.5%;
}

.wp-caption-text {
  font-size: inherit;
}

.screen-reader-text {
  font-size: inherit;
}

.bypostauthor {
  font-size: inherit;
}

/*

2. Bootstrap grid

Source: css/bootstrap.grid.css
Description: creates main rows and column

*/

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12,
.col-ms-1,
.col-ms-2,
.col-ms-3,
.col-ms-4,
.col-ms-5,
.col-ms-6,
.col-ms-7,
.col-ms-8,
.col-ms-9,
.col-ms-10,
.col-ms-11,
.col-ms-12 {
  position: relative;
  min-height: 1px;
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.66666667%;
}

.col-xs-10 {
  width: 83.33333333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.66666667%;
}

.col-xs-7 {
  width: 58.33333333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.66666667%;
}

.col-xs-4 {
  width: 33.33333333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.66666667%;
}

.col-xs-1 {
  width: 8.33333333%;
}

.col-clear-xs:after {
  content: " ";
  display: block;
  width: 100%;
  clear: both;
}

@media (min-width: 480px) {

  .col-ms-1,
  .col-ms-2,
  .col-ms-3,
  .col-ms-4,
  .col-ms-5,
  .col-ms-6,
  .col-ms-7,
  .col-ms-8,
  .col-ms-9,
  .col-ms-10,
  .col-ms-11,
  .col-ms-12 {
    float: left;
  }

  .col-ms-12 {
    width: 100%;
  }

  .col-ms-11 {
    width: 91.66666667%;
  }

  .col-ms-10 {
    width: 83.33333333%;
  }

  .col-ms-9 {
    width: 75%;
  }

  .col-ms-8 {
    width: 66.66666667%;
  }

  .col-ms-7 {
    width: 58.33333333%;
  }

  .col-ms-6 {
    width: 50%;
  }

  .col-ms-5 {
    width: 41.66666667%;
  }

  .col-ms-4 {
    width: 33.33333333%;
  }

  .col-ms-3 {
    width: 25%;
  }

  .col-ms-2 {
    width: 16.66666667%;
  }

  .col-ms-1 {
    width: 8.33333333%;
  }

  .col-clear-sm:after {
    content: " ";
    display: block;
    width: 100%;
    clear: both;
  }
}

@media (min-width: 768px) {

  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.66666667%;
  }

  .col-sm-10 {
    width: 83.33333333%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.66666667%;
  }

  .col-sm-7 {
    width: 58.33333333%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.66666667%;
  }

  .col-sm-4 {
    width: 33.33333333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.66666667%;
  }

  .col-sm-1 {
    width: 8.33333333%;
  }

  .col-clear-md:after {
    content: " ";
    display: block;
    width: 100%;
    clear: both;
  }
}

@media (min-width: 992px) {

  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-11 {
    width: 91.66666667%;
  }

  .col-md-10 {
    width: 83.33333333%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-8 {
    width: 66.66666667%;
  }

  .col-md-7 {
    width: 58.33333333%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-5 {
    width: 41.66666667%;
  }

  .col-md-4 {
    width: 33.33333333%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-2 {
    width: 16.66666667%;
  }

  .col-md-1 {
    width: 8.33333333%;
  }

  .col-clear-lg:after {
    content: " ";
    display: block;
    width: 100%;
    clear: both;
  }
}

@media (min-width: 1200px) {

  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-11 {
    width: 91.66666667%;
  }

  .col-lg-10 {
    width: 83.33333333%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-8 {
    width: 66.66666667%;
  }

  .col-lg-7 {
    width: 58.33333333%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-5 {
    width: 41.66666667%;
  }

  .col-lg-4 {
    width: 33.33333333%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-2 {
    width: 16.66666667%;
  }

  .col-lg-1 {
    width: 8.33333333%;
  }

  .col-clear-xl:after {
    content: " ";
    display: block;
    width: 100%;
    clear: both;
  }
}

.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: " ";
  display: table;
}

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  clear: both;
}

.visible-xs,
.visible-ms,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}

@media (max-width: 480px) {
  .visible-xs {
    display: block !important;
  }
}

@media (min-width: 480px) and (max-width:767px) {
  .visible-ms {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
}

@media (max-width: 480px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .hidden-ms {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

/*

3. Bold themes grid 

Source: css/grid.css 
Description: styles paddings, spacings and margins for grid

*/

/* Grid elements base
---------------------------------------------------------------*/

.gutter .port {
  max-width: 1160px;
  margin: auto;
}

/* Row and column
---------------------------------------------------------------*/

.boldRow {
  display: block;
  margin: 0 -10px;
}

.rowItem {
  padding: 0 10px;
}

.rowItemContent {
  position: relative;
}

.btDoubleRowPadding .boldRow {
  display: block;
  margin: 0 -20px;
}

.btDoubleRowPadding .rowItem {
  padding: 0 20px;
}

/* Vertical align should be removed om small screens. Otherwise it won't break into the new row
---------------------------------------------------------------*/

@media (min-width: 768px) {
  .boldRow.btTableRow {
    display: table;
    width: 100%;
  }

  .boldRow.btTableRow .rowItem {
    float: none;
    height: 100%;
    display: table-cell;
  }

  .boldRow.btTableRow .rowItem.btMiddleVertical {
    vertical-align: middle;
  }

  .boldRow.btTableRow .rowItem.btBottomVertical {
    vertical-align: bottom;
  }

  /* Cell border, removed on c */
  .rowItem.btLeftBorder {
    border-left: 1px solid rgba(0, 0, 0, .1);
  }

  .rowItem.btLeftBorder {
    border-left: 1px solid rgba(0, 0, 0, .1);
  }

  .rowItem.btRightBorder {
    border-right: 1px solid rgba(0, 0, 0, .1);
  }

  .btDarkSkin .rowItem.btRightBorder,
  .btLightSkin .btDarkSkin .rowItem.btRightBorder {
    border-right: 1px solid rgba(255, 255, 255, .1);
  }

  .btDarkSkin .rowItem.btLeftBorder,
  .btLightSkin .btDarkSkin .rowItem.btLeftBorder {
    border-left: 1px solid rgba(255, 255, 255, .1);
  }
}

/* Grid elements spacing
---------------------------------------------------------------*/

.topExtraSmallSpaced {
  padding-top: 10px;
}

.topSmallSpaced {
  padding-top: 20px;
}

.topSemiSpaced {
  padding-top: 40px;
}

.topSpaced {
  padding-top: 80px;
}

.topMoreSpaced {
  padding-top: 120px;
}

.topExtraSpaced {
  padding-top: 180px;
}

.bottomExtraSmallSpaced {
  padding-bottom: 10px;
}

.bottomSmallSpaced {
  padding-bottom: 20px;
}

.bottomSemiSpaced {
  padding-bottom: 40px;
}

.bottomSpaced {
  padding-bottom: 80px;
}

.custom-bottom-space {
  padding-bottom: 40px;
}

.bottomMoreSpaced {
  padding-bottom: 120px;
}

.bottomExtraSpaced {
  padding-bottom: 180px;
}

/* Horizontal padding
---------------------------------------------------------------*/

.rowItem.btDoublePadding .rowItemContent {
  padding: 0 20px;
}

.rowItem.btNoPadding {
  padding: 0;
}

.rowItem.btNoPadding {
  padding: 0;
}

/* Text indent
---------------------------------------------------------------*/

.btTextIndent .rowItemContent {
  padding: 0 10px;
}

.btTextIndent .rowItemContent .btImage,
.btTextIndent .rowItemContent .bpgPhoto {
  margin: 0 -10px;
}

/* Highlighted section
---------------------------------------------------------------*/

.btHighlight .rowItemContent,
.btSingleLatestPostWrapper,
.bpgPhoto,
.btPriceTable,
.btSlidePane .btQuoteBooking {
  border-radius: 2px;
}

.btHighlight .rowItemContent header,
.btHighlight .rowItemContent .btText,
.btHighlight .rowItemContent .btBtn,
.btHighlight .rowItemContent .btSeparator,
.btHighlight .rowItemContent .btIconImageRow {
  /* margin-left: 10px; */
  margin-right: 10px;
}

.btHighlight .rowItemContent .servicesItem header,
.btHighlight .rowItemContent .servicesItem .btText,
.btHighlight .rowItemContent .servicesItem .btBtn,
.btHighlight .rowItemContent .servicesItem .btSeparator,
.btHighlight .rowItemContent .servicesItem .btIconImageRow {
  margin-left: 0;
  margin-right: 0;
}

.btHighlight .rowItemContent .bpgPhoto {
  border-radius: 2px 2px 0 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.equal-height-cards {
  background: #ffffff;
  padding: 20px;
  border-radius: 2px;
  min-height: 178px;
  height: 100%;
  box-shadow: 0 4px 2px -3px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.btLightSkin .btHighlight .rowItemContent,
.btLightSkin .btSingleLatestPostWrapper,
.btLightSkin bpgPhoto,
.btLightSkin .btPriceTable,
.btLightSkin .btSlidePane .btQuoteBooking,
.btDarkSkin .btLightSkin .btHighlight .rowItemContent,
.btDarkSkin .btLightSkin .btSingleLatestPostWrapper,
.btDarkSkin .btLightSkin bpgPhoto,
.btDarkSkin .btLightSkin .btPriceTable,
.btDarkSkin .btLightSkin .btSlidePane .btQuoteBooking {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .25), 0 -1px 1px rgba(0, 0, 0, .125);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .25), 0 -1px 1px rgba(0, 0, 0, .125);
}

.btDarkSkin .btHighlight .rowItemContent,
.btDarkSkin .btSingleLatestPostWrapper,
.btDarkSkin bpgPhoto,
.btDarkSkin .btPriceTable,
.btDarkSkin .btSlidePane .btQuoteBooking,
.btLightSkin .btDarkSkin .btHighlight .rowItemContent,
.btLightSkin .btDarkSkin .btSingleLatestPostWrapper,
.btLightSkin .btDarkSkin bpgPhoto,
.btLightSkin .btDarkSkin .btPriceTable,
.btLightSkin .btDarkSkin .btSlidePane .btQuoteBooking {
  background-color: rgba(255, 255, 255, .07);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .25), 0 -1px 1px rgba(0, 0, 0, .125);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .25), 0 -1px 1px rgba(0, 0, 0, .125);
}

/* Helpers
---------------------------------------------------------------*/

.boldRow:after,
.btClear:after {
  content: " ";
  display: table;
  width: 100%;
  clear: both;
}

.btTextLeft {
  text-align: left;
}

.btTextRight {
  text-align: right;
}

.btTextCenter {
  text-align: center;
}

.fullScreenHeight {
  height: 100vh;
}

.fullScreenHeight .port {
  height: 100%;
  width: 100%;
  display: table;
  table-layout: fixed;
}

.fullScreenHeight .boldCell {
  height: 100%;
  width: 100%;
  display: table-cell;
}

.fullScreenHeight .boldCellInner {
  display: block;
}

.btMiddleVertical .boldCell {
  vertical-align: middle;
}

.btBottomVertical .boldCell {
  vertical-align: bottom;
}

.btGhost {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: all 1s cubic-bezier(.680, -.550, .265, 1.550);
  -moz-transition: all 1s cubic-bezier(.680, -.550, .265, 1.550);
  transition: all 1s cubic-bezier(.680, -.550, .265, 1.550);
}

.btGhost.btRemoveGhost {
  -webkit-transform: translateY(-100%) scale(.7);
  -moz-transform: translateY(-100%) scale(.7);
  -ms-transform: translateY(-100%) scale(.7);
  transform: translateY(-100%) scale(.7);
  opacity: 0;
}

.wBackground {
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.wBackground.cover {
  background-size: cover;
}

.wBackground.contain {
  background-size: contain;
}

/* Animated elements
---------------------------------------------------------------*/

.btParallax,
.bt_parallax {
  -webkit-transition-property: background-position;
  -moz-transition-property: background-position;
  transition-property: background-position;
  -webkit-transition-duration: 0ms;
  -moz-transition-duration: 0ms;
  transition-duration: 0ms;
  -webkit-transition-delay: 0ms;
  -moz-transition-delay: 0ms;
  transition-delay: 0ms;
  background-attachment: fixed;
}

.animate {
  -webkit-transition: all 3s cubic-bezier(.680, -.550, .265, 1.550);
  -moz-transition: all 3s cubic-bezier(.680, -.550, .265, 1.550);
  transition: all 3s cubic-bezier(.680, -.550, .265, 1.550);
}

.animate-fadein {
  opacity: .2;
  -webkit-transform: scale(.98);
  -moz-transform: scale(.98);
  -ms-transform: scale(.98);
  transform: scale(.98);
}

.touch .animate-fadein {
  opacity: 1;
}

.animate-moveleft {
  -webkit-transform: translateX(100px);
  -moz-transform: translateX(100px);
  -ms-transform: translateX(100px);
  transform: translateX(100px);
}

.animate-moveright {
  -webkit-transform: translateX(-100px);
  -moz-transform: translateX(-100px);
  -ms-transform: translateX(-100px);
  transform: translateX(-100px);
}

.animate-moveup {
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
}

.animate-movedown {
  -webkit-transform: translateY(-100px);
  -moz-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  transform: translateY(-100px);
}

.animated {
  opacity: 1;
  -webkit-transform: translateY(0) scale(1);
  -moz-transform: translateY(0) scale(1);
  -ms-transform: translateY(0) scale(1);
  transform: translateY(0) scale(1);
  -webkit-transition: all 1s cubic-bezier(.190, 1, .220, 1);
  -moz-transition: all 1s cubic-bezier(.190, 1, .220, 1);
  transition: all 1s cubic-bezier(.190, 1, .220, 1);
}

.rowItem:nth-child(0) {
  -webkit-transition-delay: -200ms;
  -moz-transition-delay: -200ms;
  transition-delay: -200ms;
}

.rowItem:nth-child(1) {
  -webkit-transition-delay: 0ms;
  -moz-transition-delay: 0ms;
  transition-delay: 0ms;
}

.rowItem:nth-child(2) {
  -webkit-transition-delay: 200ms;
  -moz-transition-delay: 200ms;
  transition-delay: 200ms;
}

.rowItem:nth-child(3) {
  -webkit-transition-delay: 400ms;
  -moz-transition-delay: 400ms;
  transition-delay: 400ms;
}

.rowItem:nth-child(4) {
  -webkit-transition-delay: 600ms;
  -moz-transition-delay: 600ms;
  transition-delay: 600ms;
}

.rowItem:nth-child(5) {
  -webkit-transition-delay: 800ms;
  -moz-transition-delay: 800ms;
  transition-delay: 800ms;
}

.rowItem:nth-child(6) {
  -webkit-transition-delay: 1000ms;
  -moz-transition-delay: 1000ms;
  transition-delay: 1000ms;
}

.rowItem:nth-child(7) {
  -webkit-transition-delay: 1200ms;
  -moz-transition-delay: 1200ms;
  transition-delay: 1200ms;
}

.rowItem:nth-child(8) {
  -webkit-transition-delay: 1400ms;
  -moz-transition-delay: 1400ms;
  transition-delay: 1400ms;
}

.rowItem:nth-child(9) {
  -webkit-transition-delay: 1600ms;
  -moz-transition-delay: 1600ms;
  transition-delay: 1600ms;
}

.rowItem:nth-child(10) {
  -webkit-transition-delay: 1800ms;
  -moz-transition-delay: 1800ms;
  transition-delay: 1800ms;
}

.rowItem:nth-child(11) {
  -webkit-transition-delay: 2000ms;
  -moz-transition-delay: 2000ms;
  transition-delay: 2000ms;
}

.rowItem:nth-child(12) {
  -webkit-transition-delay: 2200ms;
  -moz-transition-delay: 2200ms;
  transition-delay: 2200ms;
}

.rowItem:nth-child(13) {
  -webkit-transition-delay: 2400ms;
  -moz-transition-delay: 2400ms;
  transition-delay: 2400ms;
}

.rowItem:nth-child(14) {
  -webkit-transition-delay: 2600ms;
  -moz-transition-delay: 2600ms;
  transition-delay: 2600ms;
}

.rowItem:nth-child(15) {
  -webkit-transition-delay: 2800ms;
  -moz-transition-delay: 2800ms;
  transition-delay: 2800ms;
}

.rowItem:nth-child(16) {
  -webkit-transition-delay: 3000ms;
  -moz-transition-delay: 3000ms;
  transition-delay: 3000ms;
}

.rowItem:nth-child(17) {
  -webkit-transition-delay: 3200ms;
  -moz-transition-delay: 3200ms;
  transition-delay: 3200ms;
}

.rowItem:nth-child(18) {
  -webkit-transition-delay: 3400ms;
  -moz-transition-delay: 3400ms;
  transition-delay: 3400ms;
}

.rowItem:nth-child(19) {
  -webkit-transition-delay: 3600ms;
  -moz-transition-delay: 3600ms;
  transition-delay: 3600ms;
}

.rowItem:nth-child(20) {
  -webkit-transition-delay: 3800ms;
  -moz-transition-delay: 3800ms;
  transition-delay: 3800ms;
}

/*

4. Template

Source: css/template.css 
Description: styles headings, preloader, breadcrumbs

*/

/* Base font
---------------------------------------------------------------*/

body {
  font-family: Lato, Arial, sans-serif;
  font-size: 13px;
  font-weight: 500;
  padding-top: 0px !important;
}

/* Headings
---------------------------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  font-family: Lato, Arial, sans-serif;
  margin: 0 0 20px;
  line-height: 1.2;
  padding: 0;
  font-weight: 900;
  text-transform: uppercase;
}


h2 {
  font-size: 45px;
  font-weight: 700;
}

h3 {
  font-size: 30px;
  font-weight: 500;
  color: var(--secondry-color);
}


h5 {
  font-size: 22px !important;
}

h6 {
  font-size: 18px;
}

.multistep-form h6,
.multistep-form h4 {
  font-size: 20px !important;
  font-weight: 700 !important;
}

/* Skin
---------------------------------------------------------------*/

/* .btDarkSkin,
.btLightSkin .btDarkSkin {
  color: #fff;
  background-color: #313131;
}

.btLightSkin,
.btDarkSkin .btLightSkin {
  color: #333;
  background-color: #fff;
} */

/* .btLightSkin h1,
.btLightSkin h2,
.btLightSkin h3,
.btLightSkin h4,
.btLightSkin h5,
.btLightSkin h6,
.btDarkSkin .btLightSkin h1,
.btDarkSkin .btLightSkin h2,
.btDarkSkin .btLightSkin h3,
.btDarkSkin .btLightSkin h4,
.btDarkSkin .btLightSkin h5,
.btDarkSkin .btLightSkin h6 {
  color: var(--secondry-color);
} */

.btDarkSkin h1,
.btDarkSkin h2,
.btDarkSkin h3,
.btDarkSkin h4,
.btDarkSkin h5,
.btDarkSkin h6,
.btLightSkin .btDarkSkin h1,
.btLightSkin .btDarkSkin h2,
.btLightSkin .btDarkSkin h3,
.btLightSkin .btDarkSkin h4,
.btLightSkin .btDarkSkin h5,
.btLightSkin .btDarkSkin h6 {
  color: #fff;
}

.btDarkSkin .btHighlight h1,
.btDarkSkin .btHighlight h2,
.btDarkSkin .btHighlight h3,
.btDarkSkin .btHighlight h4,
.btDarkSkin .btHighlight h5,
.btDarkSkin .btHighlight h6,
.btLightSkin .btDarkSkin .btHighlight h1,
.btLightSkin .btDarkSkin .btHighlight h2,
.btLightSkin .btDarkSkin .btHighlight h3,
.btLightSkin .btDarkSkin .btHighlight h4,
.btLightSkin .btDarkSkin .btHighlight h5,
.btLightSkin .btDarkSkin .btHighlight h6 {
  color: #e8e8e8;
}

.btLightSkin a,
.btDarkSkin .btLightSkin a {
  color: var(--secondry-color);
  -webkit-transition: color 300ms ease;
  -moz-transition: color 300ms ease;
  transition: color 300ms ease;
}

.btLightSkin a:hover,
.btDarkSkin .btLightSkin a:hover {
  color: var(--primary-color);
}

.btDarkSkin a,
.btLightSkin .btDarkSkin a {
  color: #fff;
  -webkit-transition: color 300ms ease;
  -moz-transition: color 300ms ease;
  transition: color 300ms ease;
}

.btDarkSkin a:hover,
.btLightSkin .btDarkSkin a:hover {
  color: var(--secondry-color);
}

.btLightSkin select,
.btLightSkin textarea,
.btLightSkin input,
.btDarkSkin .btLightSkin select,
.btDarkSkin .btLightSkin textarea,
.btDarkSkin .btLightSkin input {
  border: 1px solid rgba(0, 0, 0, .1);
  color: #333;
}

/* .btLightSkin select:hover,
.btLightSkin textarea:hover,
.btLightSkin input:hover,
.btDarkSkin .btLightSkin select:hover,
.btDarkSkin .btLightSkin textarea:hover,
.btDarkSkin .btLightSkin input:hover {
  border-color: #CCC;
  -webkit-box-shadow: 0 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
  box-shadow: 0 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
} */

/* .btLightSkin select:focus,
.btLightSkin textarea:focus,
.btLightSkin input:focus,
.btDarkSkin .btLightSkin select:focus,
.btDarkSkin .btLightSkin textarea:focus,
.btDarkSkin .btLightSkin input:focus {
  border-color: #999;
  -webkit-box-shadow: 5px 0 0 var(--secondry-color) inset, 0 2px 10px rgba(0, 0, 0, .2);
  box-shadow: 5px 0 0 var(--secondry-color) inset, 0 2px 10px rgba(0, 0, 0, .2);
} */

/* .btDarkSkin select,
.btDarkSkin textarea,
.btDarkSkin input,
.btLightSkin .btDarkSkin select,
.btLightSkin .btDarkSkin textarea,
.btLightSkin .btDarkSkin input {
  border: 1px solid rgba(255, 255, 255, .1);
  color: #fff;
} */

/* .btDarkSkin select:hover,
.btDarkSkin textarea:hover,
.btDarkSkin input:hover,
.btLightSkin .btDarkSkin select:hover,
.btLightSkin .btDarkSkin textarea:hover,
.btLightSkin .btDarkSkin input:hover {
  -webkit-box-shadow: 0 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
  box-shadow: 0 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
} */

/* .btDarkSkin select:focus,
.btDarkSkin textarea:focus,
.btDarkSkin input:focus,
.btLightSkin .btDarkSkin select:focus,
.btLightSkin .btDarkSkin textarea:focus,
.btLightSkin .btDarkSkin input:focus {
  -webkit-box-shadow: 5px 0 0 var(--secondry-color) inset, 0 2px 10px rgba(0, 0, 0, .2);
  box-shadow: 5px 0 0 var(--secondry-color) inset, 0 2px 10px rgba(0, 0, 0, .2);
} */

.btLightSkin .btRegularTitle h1,
.btLightSkin .btRegularTitle h2,
.btLightSkin .btRegularTitle h3,
.btLightSkin .btRegularTitle h4,
.btLightSkin .btRegularTitle h5,
.btLightSkin .btRegularTitle h6,
.btDarkSkin .btLightSkin .btRegularTitle h1,
.btDarkSkin .btLightSkin .btRegularTitle h2,
.btDarkSkin .btLightSkin .btRegularTitle h3,
.btDarkSkin .btLightSkin .btRegularTitle h4,
.btDarkSkin .btLightSkin .btRegularTitle h5,
.btDarkSkin .btLightSkin .btRegularTitle h6 {
  color: var(--secondry-color) !important;
}

.btDarkSkin .btRegularTitle h1,
.btDarkSkin .btRegularTitle h2,
.btDarkSkin .btRegularTitle h3,
.btDarkSkin .btRegularTitle h4,
.btDarkSkin .btRegularTitle h5,
.btDarkSkin .btRegularTitle h6,
.btLightSkin .btDarkSkin .btRegularTitle h1,
.btLightSkin .btDarkSkin .btRegularTitle h2,
.btLightSkin .btDarkSkin .btRegularTitle h3,
.btLightSkin .btDarkSkin .btRegularTitle h4,
.btLightSkin .btDarkSkin .btRegularTitle h5,
.btLightSkin .btDarkSkin .btRegularTitle h6 {
  color: #fff !important;
}

.btAccentTitle h1,
.btAccentTitle h2,
.btAccentTitle h3,
.btAccentTitle h4,
.btAccentTitle h5,
.btAccentTitle h6 {
  color: var(--secondry-color) !important;
}

.btLightAccentTitle h1,
.btLightAccentTitle h2,
.btLightAccentTitle h3,
.btLightAccentTitle h4,
.btLightAccentTitle h5,
.btLightAccentTitle h6 {
  color: #2995f1 !important;
}

.btDarkAccentTitle h1,
.btDarkAccentTitle h2,
.btDarkAccentTitle h3,
.btDarkAccentTitle h4,
.btDarkAccentTitle h5,
.btDarkAccentTitle h6 {
  color: #08487e !important;
}

.btAlternateTitle h1,
.btAlternateTitle h2,
.btAlternateTitle h3,
.btAlternateTitle h4,
.btAlternateTitle h5,
.btAlternateTitle h6 {
  color: #686d7a !important;
}

.btLightAlternateTitle h1,
.btLightAlternateTitle h2,
.btLightAlternateTitle h3,
.btLightAlternateTitle h4,
.btLightAlternateTitle h5,
.btLightAlternateTitle h6 {
  color: #9da1ab !important;
}

.btDarkAlternateTitle h1,
.btDarkAlternateTitle h2,
.btDarkAlternateTitle h3,
.btDarkAlternateTitle h4,
.btDarkAlternateTitle h5,
.btDarkAlternateTitle h6 {
  color: #535761 !important;
}

/* Base formating
---------------------------------------------------------------*/

.btContentHolder figure,
.btContentHolder img,
.btContentHolder select,
.btContentHolder embed,
.btContentHolder iframe {
  max-width: 100%;
  height: auto;
}

.btContentHolder .btGoogleMap figure,
.btContentHolder .btGoogleMap img,
.btContentHolder .btGoogleMap select {
  max-width: none;
}

.btContentHolder pre code {
  display: block;
}

.btContentHolder pre {
  overflow: hidden;
}

/* Loader
---------------------------------------------------------------*/

.btLoader {
  margin: 30px auto 30px auto;
  font-size: 6px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid var(--secondry-color);
  border-left: 2px solid rgba(128, 128, 128, .1);
  border-right: 2px solid rgba(128, 128, 128, .1);
  border-bottom: 2px solid rgba(128, 128, 128, .1);
  -webkit-animation: btLoader 1.1s infinite linear;
  -moz-animation: btLoader 1.1s infinite linear;
  animation: btLoader 1.1s infinite linear;
}

.btLoader,
.btLoader:after {
  width: 64px;
  border-radius: 50%;
  height: 64px;
}

@-webkit-keyframes btLoader {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes btLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-moz-keyframes btLoader {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes btLoader {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/* Template base
---------------------------------------------------------------*/

.btPageWrap {
  position: relative;
  overflow: hidden;
  min-height: 101vh;
  background-color: inherit;
  -webkit-transition: transform 1s cubic-bezier(.230, 1, .320, 1);
  -moz-transition: transform 1s cubic-bezier(.230, 1, .320, 1);
  transition: transform 1s cubic-bezier(.230, 1, .320, 1);
}

/* Z indexes
---------------------------------------------------------------*/

.mainHeader {
  z-index: 99996;
}

.btGhost {
  z-index: 99997;
}

.btPreloader {
  z-index: 99998;
}

/* Content holder and sidebars
---------------------------------------------------------------*/

.btNoSidebar .btContentHolder,
.btNoSidebar .btContent {
  width: auto;
  padding: 0;
}

.search.btNoSidebar .btContentHolder,
.blog.btNoSidebar .btContentHolder,
.archive.btNoSidebar .btContentHolder,
.single-portfolio.btNoSidebar .btContentHolder,
.btContentHolder {
  width: 1160px;
  margin: auto;
}

.btContent {
  width: 66.6667%;
  padding-top: 0;
}

.btSidebar {
  padding-top: 0;
  width: 33.3333%;
}

.btNoSidebar .btContent {
  padding-top: 0;
}

.btSidebarLeft .btSidebar {
  float: left;
  padding-right: 40px;
}

.btSidebarRight .btSidebar {
  float: right;
  padding-left: 40px;
}

.btSidebarRight .btContent {
  float: left;
}

.btSidebarLeft .btContent {
  float: right;
}

/* Preloader
---------------------------------------------------------------*/

.btPreloader {
  position: fixed;
  top: 0;
  left: 0;
  background-color: inherit;
  width: 100%;
  vertical-align: middle;
  text-align: center;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -webkit-transition: all .3s ease, opacity .3s ease;
  -moz-transition: all .3s ease, opacity .3s ease;
  transition: all .3s ease, opacity .3s ease;
  z-index: 999999;
}

.btPreloader.removePreloader {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  opacity: 0;
  -webkit-transition: transform .2s ease 1s, opacity .5s ease .5s;
  -moz-transition: transform .2s ease 1s, opacity .5s ease .5s;
  transition: transform .2s ease 1s, opacity .5s ease .5s;
}

.btPreloader .animation .preloaderLogo {
  height: 48px;
  margin-bottom: 10px;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  width: auto;
}

.btPreloader .animation {
  position: absolute;
  display: block;
  width: 100%;
  -webkit-transform: translateY(100px) scale(1);
  -moz-transform: translateY(100px) scale(1);
  -ms-transform: translateY(100px) scale(1);
  transform: translateY(100px) scale(1);
  -webkit-transition: transform .3s ease, opacity .3s ease;
  -moz-transition: transform .3s ease, opacity .3s ease;
  transition: transform .3s ease, opacity .3s ease;
}

.btPreloader.removePreloader .animation {
  -webkit-transform: translateY(36px) scale(1);
  -moz-transform: translateY(36px) scale(1);
  -ms-transform: translateY(36px) scale(1);
  transform: translateY(36px) scale(1);
  opacity: 0;
  -webkit-transition: transform .5s ease, opacity .5s ease .5s;
  -moz-transition: transform .5s ease, opacity .5s ease .5s;
  transition: transform .5s ease, opacity .5s ease .5s;
}

.btPreloader p {
  border-top: 1px solid rgba(0, 0, 0, .1);
  display: inline-block;
  padding: 10px 20px;
}

/* Bradcrumbs
---------------------------------------------------------------*/

.btBreadCrumbs ul {
  margin-left: 0;
  padding-left: 0;
  font-size: 12px;
  text-transform: uppercase;
}

.btBreadCrumbs li {
  display: inline-block;
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
}

.btBreadCrumbs li:after {
  content: " / ";
  padding: 0 5px;
  opacity: .5;
}

.btLightSkin .btBreadCrumbs ul,
.btDarkSkin .btLightSkin .btBreadCrumbs ul {
  color: #7E7E7E;
}

.btDarkSkin .btBreadCrumbs ul,
.btLightSkin .btDarkSkin .btBreadCrumbs ul {
  color: #7E7E7E;
}

/* Page headline
---------------------------------------------------------------*/

.btPageHeadline {
  margin-bottom: 40px;
}

/*

/* Gallery Widget */

.btBox.widget_media_gallery .gallery .gallery-item .gallery-icon img {
  width: 100%;
  height: 100%;
}



.btBox {
  padding-bottom: 40px;
}

.btSidebar .btBox {
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, .07);
}

.btBox h4 {
  margin: 0 0 10px;
  padding: 0 0 5px;
  text-transform: uppercase;
  color: var(--secondry-color);
  font-weight: 700;
  font-size: 18px;
}

.btBox h5 {
  font-size: 14px;
  margin-bottom: 5px;
  letter-spacing: 0;
  color: #686d7a;
  font-weight: 700;
  text-transform: none;
}

.btBox p {
  margin-bottom: 15px;
}

.btBox p.posted {
  font-size: 12px;
}

.btBox ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.btBox ul li {
  margin: 0;
  padding: 4px 0;
}

.btBox ul li .ppImage {
  float: left;
  max-width: 80px;
  padding-right: 10px;
  display: table-cell;
}

.btBox ul li .ppImage img {
  margin: 0 0 10px;
  max-width: 100%;
  width: 100%;
  height: auto;
  border-radius: 2px;
}

.btBox ul li .ppTxt {
  display: table-cell;
}

.btBox .popularPosts ul li:after {
  clear: both;
  content: " ";
  display: block;
}

.btLightSkin .btSidebar .btBox,
.btDarkSkin .btLightSkin .btSidebar .btBox {
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, .07);
}

.btLightSkin .btSidebar .btBox a,
.btDarkSkin .btLightSkin .btSidebar .btBox a {
  color: var(--secondry-color);
}

.btLightSkin .btSidebar .btBox a:hover,
.btDarkSkin .btLightSkin .btSidebar .btBox a:hover {
  color: var(--primary-color);
}

.btDarkSkin .btSidebar .btBox,
.btLightSkin .btDarkSkin .btSidebar .btBox {
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, .07);
}

.btDarkSkin .btSidebar .btBox a,
.btLightSkin .btDarkSkin .btSidebar .btBox a {
  color: #fff;
}

.btDarkSkin .btSidebar .btBox a:hover,
.btLightSkin .btDarkSkin .btSidebar .btBox a:hover {
  color: #686d7a;
}

/* Calendar
---------------------------------------------------------------*/

.btBox.widget_calendar table {
  width: 100%;
  border-collapse: collapse;
}

.btBox.widget_calendar table caption {
  background: var(--secondry-color);
  color: #FFF;
  font-family: Lato, Arial, sans-serif;
  font-weight: 700;
  padding: 5px;
}

.btBox.widget_calendar table thead th {
  background: rgba(0, 0, 0, .07);
  text-align: center;
  padding: 3px;
}

.btBox.widget_calendar table tfoot td {
  padding: 3px;
}

.btBox.widget_calendar table tfoot td#next {
  text-align: right;
}

.btBox.widget_calendar table tbody tr:nth-child(odd) td {
  background: transparent;
}

.btBox.widget_calendar table tbody tr:nth-child(even) td {
  background: rgba(51, 51, 51, .07);
}

.btBox.widget_calendar table tbody td {
  text-align: center;
  padding: 2px 3px;
}

/* Archives
---------------------------------------------------------------*/

.btBox.widget_archive label,
.btBox.widget_categories label {
  display: block;
}

.btBox.widget_archive select,
.btBox.widget_categories select {
  display: block;
  margin: 8px 0 0;
  width: 100%;
}

.btBox.widget_archive ul li,
.btBox.widget_categories ul li {
  margin-bottom: 1px;
  position: relative;
  padding: 0;
}

.btBox.widget_archive ul li a,
.btBox.widget_categories ul li a {
  display: block;
  padding: 10px 50px 10px 10px;
  font-family: Lato, Arial, sans-serif;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.btBox.widget_archive ul li a:hover,
.btBox.widget_categories ul li a:hover {
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.btBox.widget_archive ul li:first-child a,
.btBox.widget_categories ul li:first-child a {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.btBox.widget_archive ul li:last-child a,
.btBox.widget_categories ul li:last-child a {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.btBox.widget_archive ul li:first-child ul li,
.btBox.widget_categories ul li:first-child ul li,
.btBox.widget_archive ul li:last-child ul li,
.btBox.widget_categories ul li:last-child ul li {
  border-radius: 0;
}

.btBox.widget_archive ul li strong,
.btBox.widget_categories ul li strong {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  min-width: 38px;
  text-align: center;
}

.btBox.widget_archive ul li ul li,
.btBox.widget_categories ul li ul li {
  margin-top: 1px;
}

.btLightSkin .btBox.widget_archive ul li a,
.btLightSkin .btBox.widget_categories ul li a,
.btDarkSkin .btLightSkin .btBox.widget_archive ul li a,
.btDarkSkin .btLightSkin .btBox.widget_categories ul li a {
  color: #333;
  background: rgba(0, 0, 0, .05);
}

.btLightSkin .btBox.widget_archive ul li a:hover,
.btLightSkin .btBox.widget_categories ul li a:hover,
.btDarkSkin .btLightSkin .btBox.widget_archive ul li a:hover,
.btDarkSkin .btLightSkin .btBox.widget_categories ul li a:hover {
  color: #fff;
  background: var(--primary-color);
}

.btDarkSkin .btBox.widget_archive ul li a,
.btDarkSkin .btBox.widget_categories ul li a,
.btLightSkin .btDarkSkin .btBox.widget_archive ul li a,
.btLightSkin .btDarkSkin .btBox.widget_categories ul li a {
  color: #fff;
  background: rgba(255, 255, 255, .02);
}

.btDarkSkin .btBox.widget_archive ul li a:hover,
.btDarkSkin .btBox.widget_categories ul li a:hover,
.btLightSkin .btDarkSkin .btBox.widget_archive ul li a:hover,
.btLightSkin .btDarkSkin .btBox.widget_categories ul li a:hover {
  color: #fff;
  background: var(--secondry-color);
}

/* Pages
---------------------------------------------------------------*/

.btBox.widget_pages ul li {
  padding-top: 0;
}

.btBox.widget_pages ul li ul {
  margin-left: 15px;
}

/* Meta
---------------------------------------------------------------*/

.btBox.widget_meta li {
  padding-top: 0;
}

/* Recent comments
---------------------------------------------------------------*/

.btBox.widget_recent_comments a {
  color: #7E7E7E;
}

.btBox.widget_recent_comments .comment-author-link {
  text-transform: uppercase;
  font-weight: 700;
}

.btBox.widget_recent_comments .comment-author-link a {
  color: var(--secondry-color);
}

.btBox.widget_recent_comments .comment-author-link a:hover {
  color: #686d7a;
}

/* RSS
---------------------------------------------------------------*/

.btBox.widget_rss li {
  margin-bottom: 15px;
}

.btBox.widget_rss li a.rsswidget {
  font-family: Lato, Arial, sans-serif;
  font-weight: 700;
}

.btBox.widget_rss li .rss-date {
  display: block;
  margin: 0 0 5px;
}

.btBox.widget_rss li cite {
  font-weight: 700;
  font-style: normal;
}

.btBox.widget_rss li cite:before {
  font-family: FontAwesome;
  content: "\f040";
  display: inline-block;
  color: var(--secondry-color);
  margin: 0 5px 0 0;
}

.btBox.widget_rss li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

/* Search
---------------------------------------------------------------*/

.btBox .btSearch {
  padding-right: 48px;
}

.btBox .btSearch input[type="text"] {
  padding: 10px;
  background: #fff;
  height: 38px;
  width: 100%;
  display: block;
  border-radius: 2px;
}

.btBox .btSearch button {
  width: 38px;
  height: 38px;
  background-color: var(--secondry-color);
  color: #fff;
  border: 1px solid var(--secondry-color);
  -webkit-box-shadow: 0 0 0 2px transparent inset, 0 1px 0 var(--secondry-color) inset;
  box-shadow: 0 0 0 2px transparent inset, 0 1px 0 var(--secondry-color) inset;
  border: 0;
  float: right;
  margin: -38px -48px 0 0;
  cursor: pointer;
  border-radius: 2px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.btBox .btSearch button:before {
  font-family: Icon7Stroke;
  content: "\26B2";
  display: inline-block;
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  margin-left: -4px;
  transform: rotate(-45deg);
}

.btBox .btSearch button:hover {
  background-color: transparent;
  border: 1px solid var(--secondry-color);
  -webkit-box-shadow: 0 0 0 2px var(--secondry-color) inset, 0 1px 0 transparent inset;
  box-shadow: 0 0 0 2px var(--secondry-color) inset, 0 1px 0 transparent inset;
  color: var(--secondry-color);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.btBox .btSearch .btIco {
  display: none;
}

/* Caption
---------------------------------------------------------------*/

.btBox .wp-caption {
  margin-bottom: 10px;
}

/* Select
---------------------------------------------------------------*/

.btBox select {
  padding: 10px;
}

/* Navigation
---------------------------------------------------------------*/

/* Blockquotes
---------------------------------------------------------------*/

.btContent blockquote {
  display: block;
  padding: 0 0 0 60px;
  margin: 0 0 30px;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 1.7;
}

.btContent blockquote :after {
  content: " ";
  display: table;
  width: 100%;
  clear: both;
}

.btContent blockquote:before {
  content: '\f10d';
  font-family: FontAwesome;
  display: block;
  float: left;
  margin: -15px 0 0 -60px;
  font-size: 50px;
  opacity: .2;
}

.btLightSkin blockquote:before,
.btDarkSkin .btLightSkin blockquote:before {
  color: #333;
}

.btDarkSkin blockquote:before,
.btLightSkin .btDarkSkin blockquote:before {
  color: #fff;
}

/* Author avatar in list
---------------------------------------------------------------*/

.articleSideGutter {
  z-index: 3;
  position: absolute;
  top: 0;
  left: -5px;
  background: #686d7a;
  color: #fff;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 16px;
  line-height: 30px;
  display: table;
  border-radius: 2px;
  border-bottom-left-radius: 0;
}

.articleSideGutter .asgItem {
  display: table-cell;
  vertical-align: middle;
}

.articleSideGutter .asgItem.date {
  text-transform: uppercase;
}

.articleSideGutter .asgItem.avatar {
  padding: 5px;
}

.articleSideGutter .asgItem.avatar a {
  display: block;
  margin: -20px 10px;
  position: relative;
  width: 55px;
  height: 55px;
}

.articleSideGutter .asgItem.avatar img {
  max-height: 55px;
  width: auto;
  float: left;
}

.articleSideGutter .asgItem.avatar a:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  display: block;
  content: "";
  -webkit-box-shadow: 0 0 2px #7E7E7E inset;
  box-shadow: 0 0 2px #7E7E7E inset;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.articleSideGutter .asgItem.avatar a:hover:before {
  -webkit-box-shadow: 0 0 0 3px var(--secondry-color) inset;
  box-shadow: 0 0 0 3px var(--secondry-color) inset;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.articleSideGutter .asgItem.title {
  font-weight: 700;
}

.articleSideGutter .asgItem.title a {
  color: #fff;
}

.articleSideGutter:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px 0;
  border-color: transparent;
  display: block;
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
}

.btLightSkin .articleSideGutter:before,
.btDarkSkin .btLightSkin .articleSideGutter:before {
  border-right-color: #9da1ab;
}

.btDarkSkin .articleSideGutter:before,
.btLightSkin .btDarkSkin .articleSideGutter:before {
  border-right-color: #535761;
}

.single-post .btArticle.post {
  padding-top: 0;
}

/* Comment respond
---------------------------------------------------------------*/

.comment-respond .btnOutline.btnNormalColor {
  background: transparent;
}

.comment-respond .btnOutline.btnNormalColor button[type="submit"] {
  font-family: Lato, Arial, sans-serif;
  padding: 12px 21px;
  background: transparent;
  color: #686d7a !important;
  border: 0;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
}

.comment-respond .btnOutline.btnNormalColor:hover {
  background: transparent;
  -webkit-box-shadow: 0 0 0 2px #686d7a inset, 0 1px 0 transparent inset;
  box-shadow: 0 0 0 2px #686d7a inset, 0 1px 0 transparent inset;
}

.comment-respond .btnOutline.btnNormalColor:hover button[type="submit"] {
  background: transparent;
  color: #fff !important;
}

/* Photo slide
---------------------------------------------------------------*/

.boldPhotoSlide {
  position: relative;
}

.gridItem .boldPhotoSlide .slick-arrow {
  display: block !important;
  background: transparent;
  -webkit-box-shadow: 0 0 0 0 transparent inset;
  box-shadow: 0 0 0 0 transparent inset;
  width: 50px;
  height: 50px;
  top: 50%;
  margin-top: -25px;
  border: 0;
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  color: #fff;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  transition: all 200ms ease;
}

.gridItem .boldPhotoSlide .slick-arrow:hover {
  -webkit-box-shadow: 0 0 0 25px var(--secondry-color) inset;
  box-shadow: 0 0 0 25px var(--secondry-color) inset;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
}

.gridItem .boldPhotoSlide .slick-arrow:after {
  font: 30px/50px Cargo;
  text-shadow: 0 1px 0 #000;
}

.gridItem .boldPhotoSlide .slick-arrow.slick-prev {
  left: 10px;
}

.gridItem .boldPhotoSlide .slick-arrow.slick-prev:after {
  content: "\e664";
}

.gridItem .boldPhotoSlide .slick-arrow.slick-next {
  right: 10px;
}

.gridItem .boldPhotoSlide .slick-arrow.slick-next:after {
  content: "\e665";
}

.gridItem .boldPhotoSlide .slick-arrow.slick-disabled {
  display: none !important;
}

/* Galleries
---------------------------------------------------------------*/

.gallery {
  margin-bottom: 1.6em;
}

.gallery-item {
  display: inline-block;
  padding: 0 4px;
  text-align: center;
  vertical-align: top;
  width: 100%;
  margin: 0;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-icon img {
  margin: 0 auto;
}

.gallery-caption {
  display: block;
  padding: 5px 0;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
  display: none;
}

@media (max-width: 1200px) {
  .gallery-item {
    width: 100% !important;
    max-width: 100% !important;
  }
}

/* Image captions
---------------------------------------------------------------*/

figcaption a {
  font-weight: 700;
}

.btLightSkin figcaption,
.btDarkSkin .btLightSkin figcaption {
  color: #7E7E7E;
}

.btDarkSkin figcaption,
.btLightSkin .btDarkSkin figcaption {
  color: #7E7E7E;
}

/* Latest posts
---------------------------------------------------------------*/

.btSingleLatestPost {
  position: relative;
}

.btSingleLatestPost .btLatestPostsDate {
  background: var(--secondry-color);
  left: 20px;
  top: -5px;
  width: 50px;
  position: absolute;
  z-index: 9;
  padding: 5px 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  border-top-right-radius: 0;
}

.btSingleLatestPost .btLatestPostsDate .btLatestPostsDay {
  font-size: 25px;
  font-weight: 400;
  line-height: 25px;
}

.btSingleLatestPost .btLatestPostsDate .btLatestPostsDay:after {
  width: 30px;
  height: 0;
  border-top: 1px solid #fff;
  content: "";
  display: block;
  line-height: 0;
  margin: 8px 0 2px;
}

.btSingleLatestPost .btLatestPostsDate .btLatestPostsMonth {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
}

.btSingleLatestPost .btLatestPostsDate:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 0 5px;
  border-color: transparent transparent transparent #9da1ab;
  content: "";
  display: block;
  position: absolute;
  right: -5px;
  top: 0;
}

.btSingleLatestPost .btSingleLatestPostImage .bpgPhoto {
  border-radius: 0;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btSingleLatestPost .btSuperTitle .btArticleAuthor {
  text-transform: uppercase;
  font-size: 12px;
}

.btSingleLatestPost .header.btDash.bottomDash .dash:after {
  width: 50px;
}

.btSingleLatestPost .btSubTitle {
  text-transform: uppercase;
}

.btSingleLatestPost .btArticleCategories {
  padding-left: 0;
}

.btSingleLatestPost .btArticleCategories:before {
  display: none;
}

.btSingleLatestPostWrapper {
  margin: 0 10px;
  overflow: hidden;
  padding-bottom: 20px;
}

.btLightSkin .btSingleLatestPostWrapper,
.btDarkSkin .btLightSkin .btSingleLatestPostWrapper {
  background-color: #fff;
}

.btLightSkin .btLatestPostsDate:before,
.btDarkSkin .btLightSkin .btLatestPostsDate:before {
  border-left-color: #9da1ab;
}

.btDarkSkin .btSingleLatestPostWrapper,
.btLightSkin .btDarkSkin .btSingleLatestPostWrapper {
  background-color: rgba(255, 255, 255, .07);
}

.btDarkSkin .btLatestPostsDate:before,
.btLightSkin .btDarkSkin .btLatestPostsDate:before {
  border-left-color: #535761;
}

.btLatestPostsContainer.verticalPosts .btSingleLatestPostWrapper {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  overflow: visible;
}

.btLatestPostsContainer.verticalPosts .btSingleLatestPost {
  /* margin-bottom: 20px; */
}

.btLatestPostsContainer.verticalPosts .btSingleLatestPost .btSingleLatestPostImage .bpgPhoto {
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 5px;
  box-shadow: 0 2px 5px;
  height: 17em;
  width: 100%;
}

.btLatestPostsContainer.verticalPosts .btSuperTitle,
.btLatestPostsContainer.verticalPosts header .dash,
.btLatestPostsContainer.verticalPosts .btSubTitle,
.btLatestPostsContainer.verticalPosts .btLatestPostContent,
.btLatestPostsContainer.verticalPosts .btBtn.btReadMore {
  padding: 0;
}

/* Dropdown archiver
---------------------------------------------------------------*/

.fancy-select {
  position: relative;
}

.fancy-select .trigger {
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  background: rgba(0, 0, 0, .05);
  border-radius: 2px;
  padding: 10px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
  width: 100%;
}

.fancy-select .trigger:after {
  color: #666;
  content: '\f078';
  font: normal 14px/1 FontAwesome;
  display: block;
  position: absolute;
  right: 15px;
  top: 15px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.fancy-select .trigger.open {
  color: #fff;
  background: var(--secondry-color);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.fancy-select .trigger.open:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  font: normal 14px/1 FontAwesome;
  color: #fff;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.fancy-select .options {
  display: block;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: #fff;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  -webkit-transition: ease 250ms all;
  -moz-transition: ease 250ms all;
  transition: ease 250ms all;
  -webkit-transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
}

.fancy-select .options.open {
  z-index: 20;
  pointer-events: all;
  opacity: 1;
  -webkit-transition: ease 250ms all;
  -moz-transition: ease 250ms all;
  transition: ease 250ms all;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.fancy-select .options li {
  cursor: pointer;
  display: block;
  padding: 10px !important;
  margin: 0 0 1px;
  /* background-color: rgba(0, 0, 0, .07); */
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  transition: all 200ms ease;
}
.blogs-card-hover:hover{
  box-shadow: rgba(153, 140, 235, 0.25) 0px 14px 28px, rgba(59, 56, 104, 0.584) 0px 10px 10px;
    transition: all ease-in-out .7s
}
.fancy-select .options li.selected {
  background: #686d7a;
  color: #fff;
}

.fancy-select .options li:hover,
.fancy-select .options li.selected:hover {
  background-color: #686d7a !important;
  color: #fff;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  transition: all 200ms ease;
}

.fancy-select .options li:before {
  content: '';
}

.btLightSkin .fancy-select .options,
.btDarkSkin .btLightSkin .fancy-select .options {
  background-color: #fff;
}

.btLightSkin .fancy-select .options li,
.btDarkSkin .btLightSkin .fancy-select .options li {
  background-color: rgba(0, 0, 0, .07);
}

.btLightSkin .fancy-select .options li.selected,
.btDarkSkin .btLightSkin .fancy-select .options li.selected {
  background: #686d7a;
  color: #fff;
}

.btLightSkin .fancy-select .options li:hover,
.btDarkSkin .btLightSkin .fancy-select .options li:hover,
.btLightSkin .fancy-select .options li.selected:hover,
.btDarkSkin .btLightSkin .fancy-select .options li.selected:hover {
  background-color: #686d7a !important;
  color: #fff;
}

.btDarkSkin .fancy-select .options,
.btLightSkin .btDarkSkin .fancy-select .options {
  background-color: var(--secondry-color);
  color: white;
}

.btDarkSkin .fancy-select .options li,
.btLightSkin .btDarkSkin .fancy-select .options li {
  background-color: rgba(255, 255, 255, .07);
}

.btDarkSkin .fancy-select .options li.selected,
.btLightSkin .btDarkSkin .fancy-select .options li.selected {
  background: #686d7a;
  color: #fff;
}

.btDarkSkin .fancy-select .options li:hover,
.btLightSkin .btDarkSkin .fancy-select .options li:hover,
.btDarkSkin .fancy-select .options li.selected:hover,
.btLightSkin .btDarkSkin .fancy-select .options li.selected:hover {
  background-color: #686d7a !important;
  color: #fff;
}

.btDarkSkin .fancy-select .trigger:after,
.btLightSkin .btDarkSkin .fancy-select .trigger:after {
  color: #fff;
}

/* Dropdown */

.btDropdown .fancy-select .trigger {
  border: 1px solid #FFF;
  padding: 5px 10px;
}

.btDropdown .fancy-select .trigger.open {
  border-color: var(--secondry-color);
}

.btDropdown .fancy-select .trigger:after {
  top: 9px;
}

.btDropdown .fancy-select .options.open {
  top: 44px;
}

/* Sliders */

.slidedItem.firstItem .port:after,
.slidedItem .variableImg:after,
.btGhost>.port:after {
  content: ' ';
  display: block;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

.slidedItem.firstItem .port:after,
.btGhost>.port:after {
  background-color: rgba(0, 0, 0, .5);
}

.slidedItem .port .btSlideGutter,
.btGhost>.port .rowItem {
  position: relative;
  z-index: 2;
}

.fullScreenHeight .boldCell {
  z-index: 2;
  position: relative;
}

.recentTweets small:before {
  content: '\f099';
  font: 19px/1 FontAwesome;
  position: relative;
  top: 3px;
  margin-right: 3px;
  color: var(--secondry-color);
}

/*

   8. Elements 

Source: css/elements.css 
Description: handles content elements

*/

/* Divider
---------------------------------------------------------------*/

.btDivider {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

/* Icons
---------------------------------------------------------------*/

.btIco {
  display: inline-block;
  vertical-align: middle;
  margin-left: 3px;
  margin-right: 3px;
}

.btIco .btIcoHolder {
  line-height: inherit;
}

.btIco .btIcoHolder:before {
  /* color: var(--secondry-color); */
  color: var(--secondry-color);
  border: 1px solid var(--secondry-color);
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  float: left;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all .3s cubic-bezier(.250, .460, .450, .940);
  -moz-transition: all .3s cubic-bezier(.250, .460, .450, .940);
  transition: all .3s cubic-bezier(.250, .460, .450, .940);
}

.btnIconRightPosition .btIco .btIcoHolder:before {
  float: right;
}

.btIco:hover a.btIcoHolder:before {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.btIco .btIcoHolder span {
  margin-left: 5px;
}

.btIco .btIcoHolder[data-ico-fa]:before {
  font-family: FontAwesome;
  content: attr(data-ico-fa);
}

.btIco .btIcoHolder[data-ico-s7]:before {
  font-family: Icon7Stroke;
  content: attr(data-ico-s7);
}

.btIco .btIcoHolder[data-ico-cs]:before {
  font-family: Cargo;
  content: attr(data-ico-cs);
}

.btIco.white .btIcoHolder:before {
  background-color: #fff;
  color: var(--secondry-color);
}

.btIco.accent .btIcoHolder:before {
  background-color: var(--secondry-color);
  color: #fff;
}

.btReadMore .btIco.default .btIcoHolder:before {
  background: #686d7a;
  border-color: #686d7a;
  color: #fff;
}

.btReadMore .btIco.default:hover .btIcoHolder:before {
  background: var(--secondry-color);
  border-color: #08487e;
  color: #fff;
}

.btLightSkin .btIco.colorless .btIcoHolder:before,
.btDarkSkin .btLightSkin .btIco.colorless .btIcoHolder:before {
  color: #333;
  border-color: #333;
}

.btDarkSkin .btIco.colorless .btIcoHolder:before,
.btLightSkin .btDarkSkin .btIco.colorless .btIcoHolder:before {
  color: #fff;
  border-color: #fff;
}

.btIco.borderless .btIcoHolder:before {
  border-color: transparent !important;
}

.btIco.extrasmall {
  line-height: 30px;
}

.btIco.extrasmall .btIcoHolder:before {
  font-size: 15px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-weight: 300;
}

.btIco.small {
  line-height: 40px;
}

.btIco.small .btIcoHolder:before {
  font-size: 20px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-weight: 300;
}

.btIco.medium {
  line-height: 64px;
}

.btIco.medium .btIcoHolder:before {
  font-size: 32px;
  width: 64px;
  height: 64px;
  line-height: 64px;
}

.btIco.large {
  line-height: 120px;
}

.btIco.large .btIcoHolder:before {
  font-size: 60px;
  width: 120px;
  height: 120px;
  line-height: 120px;
  border-width: 2px;
}

.white-icon:before {
  color: white !important;
}

.btTextLeft .btIco {
  margin-left: 0;
  margin-right: 5px;
}

.btTextLeft .btIco .btIcoHolder span {
  margin-right: 5px;
  /* color: white; */
  font-size: 14px;
}

.find-out-more-btn {
  color: var(--secondry-color) !important;
}

.btTextRight .btIco {
  margin-left: 5px;
  margin-right: 0;
}

.btTextRight .btIco .btIcoHolder span {
  margin-left: 5px;
}

.btIco.borderless .btIcoHolder span {
  margin-left: 0;
  margin-right: 0;
}

/* Icon and images row
---------------------------------------------------------------*/

.btIconImageRow {
  display: inline-block;
  margin: auto;
}

.btIconImageRow .bpgPhoto {
  display: inline-block;
  margin: 0 5px;
  width: auto;
}

.btIconImageRow .btIco.large {
  margin: 0 5px;
}

/* Divider
---------------------------------------------------------------*/

.btSeparator {
  clear: both;
}

.btSeparator hr {
  clear: both;
  border: none;
  padding: 0;
  margin: 0;
}

.btLightSkin .btSeparator hr,
.btDarkSkin .btLightSkin .btSeparator hr {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.btDarkSkin .btSeparator hr,
.btLightSkin .btDarkSkin .btSeparator hr {
  border-bottom: 1px solid rgba(255, 255, 255, .1);
}

.btSeparator.noBorder hr {
  border-bottom: 1px solid transparent !important;
}

/* Counter
---------------------------------------------------------------*/

.btCounterHolder {
  font-family: Lato, Arial, sans-serif;
  font-weight: 500;
  font-size: 60px;
  line-height: 60px;
}

.btCounterHolder .btCounter {
  display: block;
  height: 60px;
  overflow: hidden;
}

.btCounterHolder span.onedigit {
  display: inline-block;
  height: 60px;
  overflow: hidden;
  margin: 0;
}

.btCounterHolder span.onedigit span {
  display: block;
  position: relative;
  height: 60px;
  overflow: hidden;
  -webkit-transition: all 1s ease 0s;
  -moz-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
}

.btLightSkin .btCounterHolder,
.btDarkSkin .btLightSkin .btCounterHolder {
  color: var(--secondry-color);
}

.btDarkSkin .btCounterHolder,
.btLightSkin .btDarkSkin .btCounterHolder {
  color: #fff;
}

/* Progress bar
---------------------------------------------------------------*/

.btProgressContent {
  line-height: 1.9;
  overflow: hidden;
}

.btProgressContent .btProgressAnim {
  text-align: right;
  -webkit-transition: all 1s ease 0s;
  -moz-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  width: auto;
}

.btProgressContent .btProgressAnim span {
  display: block;
  padding: 0 8px;
  vertical-align: middle;
  font-weight: 700;
}

.btLightSkin .btProgressContent,
.btDarkSkin .btLightSkin .btProgressContent {
  background-color: rgba(0, 0, 0, .07);
}

.btLightSkin .btProgressContent .btProgressAnim,
.btDarkSkin .btLightSkin .btProgressContent .btProgressAnim {
  background-color: var(--secondry-color);
  color: #fff;
}

.btLightSkin .btProgressContent .btProgressAnim span,
.btDarkSkin .btLightSkin .btProgressContent .btProgressAnim span {
  text-shadow: 0 1px 0 rgba(0, 0, 0, .1);
}

.btDarkSkin .btProgressContent,
.btLightSkin .btDarkSkin .btProgressContent {
  background-color: rgba(255, 255, 255, .07);
}

.btDarkSkin .btProgressContent .btProgressAnim,
.btLightSkin .btDarkSkin .btProgressContent .btProgressAnim {
  background-color: var(--secondry-color);
  color: #fff;
}

.btDarkSkin .btProgressContent .btProgressAnim span,
.btLightSkin .btDarkSkin .btProgressContent .btProgressAnim span {
  text-shadow: 0 1px 0 rgba(0, 0, 0, .1);
}

/* Image
---------------------------------------------------------------*/

.bpgPhoto {
  overflow: hidden;
  position: relative;
  border-radius: 2px;
}

.btCircleImage {
  border-radius: 50%;
  border: 3px solid white;
  overflow: hidden;
}

.btCircleImage .captionPane {
  border-radius: 50%;
}

.btCircleImage .btImage img {
  width: auto;
}

.bpgPhoto a {
  display: block;
  position: absolute;
  z-index: 5;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
}

.btImage img {
  max-width: 100%;
  height: auto;
  display: block;
  width: 100%;
}

.btImage {
  display: block;
}

.btTextIndent .btImage img {
  width: 100%;
  max-width: none;
  display: block;
  margin: 0;
}

.btTextRight .bpgPhoto {
  float: right;
}

.btTextLeft .bpgPhoto {
  float: left;
}

.btTextCenter .bpgPhoto {
  display: inline-block;
  float: none;
}

.btTextCenter .tilesWall .bpgPhoto {
  display: block;
}

.cellRight .bpgPhoto {
  float: right;
}

.captionPane {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(25, 26, 28, .5);
  right: 0;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  transition: all 400ms ease;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  .captionPane {
    right: -1px;
  }
}

.captionTable {
  display: table;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.captionCell {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}

.captionTxt {
  display: block;
  padding: 0 20px;
  color: #FFF;
}

.in-top .captionPane {
  -webkit-animation: in-top 300ms ease 0ms 1 forwards;
  -moz-animation: in-top 300ms ease 0ms 1 forwards;
  animation: in-top 300ms ease 0ms 1 forwards;
}

.in-right .captionPane {
  -webkit-animation: in-right 300ms ease 0ms 1 forwards;
  -moz-animation: in-right 300ms ease 0ms 1 forwards;
  animation: in-right 300ms ease 0ms 1 forwards;
}

.in-bottom .captionPane {
  -webkit-animation: in-bottom 300ms ease 0ms 1 forwards;
  -moz-animation: in-bottom 300ms ease 0ms 1 forwards;
  animation: in-bottom 300ms ease 0ms 1 forwards;
}

.in-left .captionPane {
  -webkit-animation: in-left 300ms ease 0ms 1 forwards;
  -moz-animation: in-left 300ms ease 0ms 1 forwards;
  animation: in-left 300ms ease 0ms 1 forwards;
}

.out-top .captionPane {
  -webkit-animation: out-top 300ms ease 0ms 1 forwards;
  -moz-animation: out-top 300ms ease 0ms 1 forwards;
  animation: out-top 300ms ease 0ms 1 forwards;
}

.out-right .captionPane {
  -webkit-animation: out-right 300ms ease 0ms 1 forwards;
  -moz-animation: out-right 300ms ease 0ms 1 forwards;
  animation: out-right 300ms ease 0ms 1 forwards;
}

.out-bottom .captionPane {
  -webkit-animation: out-bottom 300ms ease 0ms 1 forwards;
  -moz-animation: out-bottom 300ms ease 0ms 1 forwards;
  animation: out-bottom 300ms ease 0ms 1 forwards;
}

.out-left .captionPane {
  -webkit-animation: out-left 300ms ease 0ms 1 forwards;
  -moz-animation: out-left 300ms ease 0ms 1 forwards;
  animation: out-left 300ms ease 0ms 1 forwards;
}

@-webkit-keyframes in-top {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-moz-keyframes in-top {
  from {
    -moz-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes in-top {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes out-top {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@-moz-keyframes out-top {
  from {
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -moz-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes out-top {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@-webkit-keyframes in-right {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-moz-keyframes in-right {
  from {
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes in-right {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes out-right {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@-moz-keyframes out-right {
  from {
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes out-right {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@-webkit-keyframes in-bottom {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-moz-keyframes in-bottom {
  from {
    -moz-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes in-bottom {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes out-bottom {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@-moz-keyframes out-bottom {
  from {
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -moz-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes out-bottom {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@-webkit-keyframes in-left {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-moz-keyframes in-left {
  from {
    -moz-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes in-left {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    -ms-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes out-left {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@-moz-keyframes out-left {
  from {
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -moz-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes out-left {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    -ms-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.btLightSkin .bpgPhoto h4,
.btDarkSkin .btLightSkin .bpgPhoto h4,
.btLightSkin .btpPhoto h4,
.btDarkSkin .btLightSkin .btpPhoto h4 {
  text-transform: uppercase;
  color: #fff;
}

.btLightSkin .bpgPhoto p,
.btDarkSkin .btLightSkin .bpgPhoto p {
  color: #fff;
}

.btDarkSkin .bpgPhoto h4,
.btLightSkin .btDarkSkin .bpgPhoto h4,
.btDarkSkin .btpPhoto h4,
.btLightSkin .btDarkSkin .btpPhoto h4 {
  text-transform: uppercase;
  color: #fff;
}

.btDarkSkin .bpgPhoto p,
.btLightSkin .btDarkSkin .bpgPhoto p {
  color: #fff;
}

.captionTxt:before {
  content: "\e618";
  content: "⚲";
  font: normal 50px/2 Icon7Stroke;
  display: block;
  margin: 0 auto;
  opacity: .4;
}

.captionTxt .header {
  margin-top: 20;
}

.touch .captionPane {
  display: none !important;
}

.btShowTitle {
  position: absolute;
  top: auto;
  bottom: 0;
  margin: 0;
  padding: 10px 20px;
  width: 100%;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  transition: all 200ms ease;
  display: none;
  color: #fff;
  text-shadow: 1px 1px 2px #313131;
  background-color: rgba(0, 0, 0, .5);
}

.btHasTitles .btShowTitle {
  display: block;
}

.btShowTitle span,
.btShowTitle strong {
  display: block;
}

.bpgPhoto:hover .captionPane {
  opacity: 1;
}

.bpgPhoto:hover .btShowTitle {
  opacity: 0;
}

/* Price table
---------------------------------------------------------------*/

.btPriceTable {
  position: relative;
  border-top: 0;
  overflow: visible;
}

.btPriceTable .btPriceTableHeader {
  background: var(--secondry-color);
  padding: 10px;
}

.btPriceTable .btPriceTableHeader .header {
  margin-bottom: 0;
}

.btPriceTable .btPriceTableHeader .header .dash:after,
.btPriceTable .btPriceTableHeader .header .dash:before {
  border-color: #fff;
  margin-bottom: 10px;
}

.btPriceTable .btPriceTableHeader .header .btSuperTitle {
  font-weight: 300;
}

.btPriceTable .btPriceTableHeader .header .btSubTitle {
  text-transform: uppercase;
  font-size: 15px;
}

.btPriceTable .btPriceTableHeader .header .btPriceTableCurrency {
  font-size: 24px;
  font-weight: normal;
  margin: -8px 0 0 -20px;
}

.btPriceTable .btPriceTableHeader .header h1 .headline {
  position: relative;
}

.btPriceTable .btPriceTableHeader .header h1 .headline .btPriceTableCurrency {
  position: absolute;
}

.btPriceTable .btPriceTableSticker {
  padding: 10px;
  position: absolute;
  top: -30px;
  left: 15px;
  right: 0;
  background: #686d7a;
  -webkit-box-shadow: 0 0 0 3px rgba(0, 0, 0, .25) inset;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, .25) inset;
  color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  font-weight: 800;
  font-size: 14px;
}

.btPriceTable .btPriceTableSticker>div {
  display: table;
  height: 100%;
  width: 100%;
}

.btPriceTable .btPriceTableSticker>div>div {
  width: 100%;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.btPriceTable .btSubTitle {
  font-weight: 300;
}

.btPriceTable ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.btPriceTable ul li {
  display: block;
  padding: 15px;
  margin: 0;
}

.btLightSkin .btPriceTable ul li:nth-child(odd),
.btDarkSkin .btLightSkin .btPriceTable ul li:nth-child(odd) {
  background: rgba(0, 0, 0, .05);
}

.btDarkSkin .btPriceTable ul li:nth-child(odd),
.btLightSkin .btDarkSkin .btPriceTable ul li:nth-child(odd) {
  background: rgba(255, 255, 255, .02);
}

/* Service icon
---------------------------------------------------------------*/

.btHighlight .servicesItem {
  padding-left: 10px;
  padding-right: 10px;
}

.btTextCenter .sIcon {
  margin-bottom: 20px;
}

.btTextRight .sIcon {
  float: right;
  display: block;
  position: relative;
  z-index: 2;
  margin: 0 0 0 -100%;
}

.sTxt p:last-of-type,
.btText p:last-of-type {
  margin-bottom: 0;
}

.btTextRight .sTxt {
  position: relative;
  float: right;
}

.btTextLeft .sIcon {
  float: left;
  display: block;
  position: relative;
  z-index: 2;
  margin: 0 -100% 0 0;
}

.btTextLeft .sTxt {
  position: relative;
  float: left;
}

.sTxt h1,
.sTxt h2,
.sTxt h3,
.sTxt h4 {
  clear: none;
}

.servicesItem.mediumIcon .header {
  margin-top: 16px;
  margin-bottom: 5px;
}

.servicesItem.mediumIcon .header.extrasmall {
  margin-top: 0;
  margin-bottom: 10px;
}

.servicesItem.smallIcon .header {
  margin-top: 7px;
}

.servicesItem.borderlessIconType .header {
  margin-top: -6px;
}

.servicesItem.borderlessIconType .btIcoHolder:before {
  line-height: 1;
}

.btTextLeft .smallIcon .sTxt {
  margin-left: 50px;
}

.btTextRight .smallIcon .sTxt {
  margin-right: 50px;
}

.btTextLeft .mediumIcon .sTxt {
  margin-left: 84px;
}

.btTextRight .mediumIcon .sTxt {
  margin-right: 84px;
}

.btTextLeft .largeIcon .sTxt {
  margin-left: 140px;
}

.btTextRight .largeIcon .sTxt {
  margin-right: 140px;
}

.btTextCenter .smallIcon.servicesItem.borderlessIconType .btIcoHolder:before,
.btTextCenter .mediumIcon.servicesItem.borderlessIconType .btIcoHolder:before,
.btTextCenter .largeIcon.servicesItem.borderlessIconType .btIcoHolder:before {
  width: auto;
  height: auto;
  line-height: 1;
}

/* Borderless needs less space */

.btTextRight .smallIcon.borderlessIconType .sTxt {
  margin-right: 40px;
}

.btTextLeft .smallIcon.borderlessIconType .sTxt {
  margin-left: 40px;
}

.btTextLeft .mediumIcon.borderlessIconType .sTxt {
  margin-left: 64px;
}

.btTextRight .mediumIcon.borderlessIconType .sTxt {
  margin-right: 64px;
}

.btTextLeft .largeIcon.borderlessIconType .sTxt {
  margin-left: 120px;
}

.btTextRight .largeIcon.borderlessIconType .sTxt {
  margin-right: 120px;
}

.servicesItem:after {
  display: table;
  content: " ";
  clear: both;
  width: 100%;
}

.btTextLeft .mediumIcon .sTxt {
  margin-left: 84px;
}

/* Header
---------------------------------------------------------------*/

.header {
  display: block;
}

.header .dash {
  display: block;
}

.header .btSuperTitle {
  font-family: Raleway, Arial, sans-serif;
  clear: both;
}

.header .btSubTitle {
  font-family: Raleway, Arial, sans-serif;
  clear: both;
}

.header .btSuperTitle {
  font-weight: 600;
}

.header .btSuperTitle,
.header .btSubTitle,
.header h2,
.header h3,
.header .dash:after,
.header .dash:before {
  margin: 0;
}

.header .headline {
  display: block;
}

.header h1,
.header h2,
.header h3,
.header h4,
.header h5,
.header h6 {
  margin: 0;
}

.btDash.bottomDash .dash:after,
.btDash.topDash .dash:before {
  content: '';
  clear: both;
  width: 50px;
  display: block;
  border-bottom: 3px solid var(--secondry-color) !important;
}

.header.extrasmall {
  margin-bottom: 10px;
}

.header.extrasmall h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  text-transform: none;
  color: var(--secondry-color);
}

.header.extrasmall .btSuperTitle,
.header.extrasmall .btSubTitle {
  font-size: 13px;
  line-height: 23px;
}

.header.extrasmall .dash:after,
.header.extrasmall .dash:before {
  margin-top: 5px;
  margin-bottom: 5px;
  border-width: 1px !important;
}

.header.small {
  margin-bottom: 10px;
}

.header.small h4 {
  font-size: 21px;
  font-weight: 700;
  line-height: 31px;
  text-transform: none;
}

.header.small .btSuperTitle,
.header.small .btSubTitle {
  font-size: 13px;
  line-height: 23px;
}

.header.small .dash:after,
.header.small .dash:before {
  margin-top: 5px;
  margin-bottom: 5px;
  border-width: 1px !important;
}

.header.medium {
  margin-bottom: 14px;
}

.header.medium h3 {
  font-size: 30px;
  line-height: 44px;
  color: var(--secondry-color);
}

.header.medium .btSuperTitle,
.header.medium .btSubTitle {
  font-size: 15px;
  line-height: 29px;
  color: var(--secondry-color);
}

.header.medium .dash:after,
.header.medium .dash:before {
  margin-top: 10px;
  margin-bottom: 7px;
}

.header.large {
  margin-bottom: 18px;
}

.header.large h2 {
  font-size: 40px;
  line-height: 58px;
  letter-spacing: 1px;
  color: var(--secondry-color);
}

.header.large .btSuperTitle,
.header.large .btSubTitle {
  font-size: 18px;
  line-height: 36px;
}

.header.large .dash:after,
.header.large .dash:before {
  border-color: var(--primary-color);
  width: 65px;
  margin-top: 9px;
  margin-bottom: 9px;
}

.header.extralarge {
  margin-bottom: 20px;
}

.header.extralarge h1 {
  font-size: 50px;
  line-height: 70px;
  letter-spacing: 1px;
  font-weight: 800 !important;
}

.header.extralarge .btSuperTitle {
  font-size: 21px;
  line-height: 41px;
}

.header.extralarge .btSubTitle {
  font-size: 21px;
  padding: 3px 0;
  line-height: 35px;
  color: white;
}

.header.extralarge .dash:after,
.header.extralarge .dash:before {
  border-color: var(--secondry-color);
  width: 85px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.header.huge {
  margin-bottom: 20px;
}

.header.huge h1 {
  font-size: 80px;
  line-height: 100px;
  letter-spacing: 2px;
}

.header.huge .btSuperTitle {
  letter-spacing: 1px;
}

.header.huge .btSuperTitle,
.header.huge .btSubTitle {
  font-size: 24px;
  line-height: 44px;
}

.header.huge .dash:after,
.header.huge .dash:before {
  border-color: var(--secondry-color);
  border-width: 4px;
  width: 85px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.header.small.btDarkAccentDash .dash:after,
.header.medium.btDarkAccentDash .dash:after,
.header.large.btDarkAccentDash .dash:after,
.header.extralarge.btDarkAccentDash .dash:after,
.header.huge.btDarkAccentDash .dash:after,
.header.small.btDarkAccentDash .dash:before,
.header.medium.btDarkAccentDash .dash:before,
.header.large.btDarkAccentDash .dash:before,
.header.extralarge.btDarkAccentDash .dash:before,
.header.huge.btDarkAccentDash .dash:before {
  border-color: #08487e;
}

.header.small.btLightAccentDash .dash:after,
.header.medium.btLightAccentDash .dash:after,
.header.large.btLightAccentDash .dash:after,
.header.extralarge.btLightAccentDash .dash:after,
.header.huge.btLightAccentDash .dash:after,
.header.small.btLightAccentDash .dash:before,
.header.medium.btLightAccentDash .dash:before,
.header.large.btLightAccentDash .dash:before,
.header.extralarge.btLightAccentDash .dash:before,
.header.huge.btLightAccentDash .dash:before {
  border-color: #2995f1;
}

.header.small.btAlternateDash .dash:after,
.header.medium.btAlternateDash .dash:after,
.header.large.btAlternateDash .dash:after,
.header.extralarge.btAlternateDash .dash:after,
.header.huge.btAlternateDash .dash:after,
.header.small.btAlternateDash .dash:before,
.header.medium.btAlternateDash .dash:before,
.header.large.btAlternateDash .dash:before,
.header.extralarge.btAlternateDash .dash:before,
.header.huge.btAlternateDash .dash:before {
  border-color: #686d7a;
}

.header.small.btDarkAlternateDash .dash:after,
.header.medium.btDarkAlternateDash .dash:after,
.header.large.btDarkAlternateDash .dash:after,
.header.extralarge.btDarkAlternateDash .dash:after,
.header.huge.btDarkAlternateDash .dash:after,
.header.small.btDarkAlternateDash .dash:before,
.header.medium.btDarkAlternateDash .dash:before,
.header.large.btDarkAlternateDash .dash:before,
.header.extralarge.btDarkAlternateDash .dash:before,
.header.huge.btDarkAlternateDash .dash:before {
  border-color: #535761;
}

.header.small.btLightAlternateDash .dash:after,
.header.medium.btLightAlternateDash .dash:after,
.header.large.btLightAlternateDash .dash:after,
.header.extralarge.btLightAlternateDash .dash:after,
.header.huge.btLightAlternateDash .dash:after,
.header.small.btLightAlternateDash .dash:before,
.header.medium.btLightAlternateDash .dash:before,
.header.large.btLightAlternateDash .dash:before,
.header.extralarge.btLightAlternateDash .dash:before,
.header.huge.btLightAlternateDash .dash:before {
  border-color: #9da1ab;
}

.btTextRight .dash:before,
.btTextRight .dash:after {
  float: right;
}

.btTextLeft .dash:before,
.btTextLeft .dash:after {
  float: left;
}

.btTextCenter .dash:before,
.btTextCenter .dash:after,
.captionTxt .dash:before,
.captionTxt .dash:after {
  margin-left: auto;
  margin-right: auto;
}

/* Buttons
---------------------------------------------------------------*/

.btBtn {
  border-radius: 2px;
  font-family: Raleway, Arial, sans-serif;
  font-weight: 600;
  font-size: 13px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
  text-transform: uppercase;
}

.btBtn a,
.btBtn .btIco a:before,
.btBtn button {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

.btBtn a {
  padding: 3px 20px;
  display: inline-block;
}

.btBtn a.btIcoHolder:before {
  margin-left: -10px;
  margin-right: 5px;
}

.btBtn .btIco.extrasmall a {
  line-height: 30px;
}

.btBtn .btIco.extrasmall a:before {
  line-height: 26px;
}

.btBtn .btIco.small a {
  line-height: 40px;
}

.btBtn .btIco.small a:before {
  line-height: 36px;
}

.btBtn .btIco.medium a {
  line-height: 64px;
}

.btBtn .btIco.medium a:before {
  line-height: 60px;
}

.btBtn .btIco.large a {
  line-height: 120px;
}

.btBtn.btnBig a {
  padding: 8px 20px;
}

.btBtn.btnFull .btIco {
  width: 100%;
}

.btBtn.btnFull .btIco a.btIcoHolder {
  width: 100%;
}

.btBtn.btnBorderless,
.btBtn.btnBorderless:hover {
  border: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background: transparent !important;
  font-weight: 700 !important;
}

.btBtn.btnBorderless a:link,
.btBtn.btnBorderless a:hover {
  padding: 0 !important;
  background: transparent !important;
}

.btBtn.btnBorderless.btnIconRightPosition a.btIcoHolder:before {
  font-weight: 300 !important;
}

.btBtn.btnBorderless.btnIconRightPosition .btIco:hover a.btIcoHolder:before {
  -webkit-transform: translate3d(0, 0, 0) !important;
  -moz-transform: translate3d(0, 0, 0) !important;
  -ms-transform: translate3d(0, 0, 0) !important;
  transform: translate3d(0, 0, 0) !important;
}

.btBtn.btnBorderless.btBtnAccentLight a,
.btBtn.btnBorderless.btBtnAccentLight a:before {
  color: #2995f1 !important;
}

.btLightSkin .btBtn.btnBorderless.btBtnAccentLight a:hover,
.btLightSkin .btBtn.btnBorderless.btBtnAccentLight a:before,
.btLightSkin .btBtn.btnBorderless.btBtnAccentLight a:hover:before,
.btDarkSkin .btLightSkin .btBtn.btnBorderless.btBtnAccentLight a:hover,
.btDarkSkin .btLightSkin .btBtn.btnBorderless.btBtnAccentLight a:before,
.btDarkSkin .btLightSkin .btBtn.btnBorderless.btBtnAccentLight a:hover:before {
  color: var(--secondry-color) !important;
}

.btLightSkin .btBtn.btnBorderless.btnAccentColor a:hover,
.btLightSkin .btBtn.btnBorderless.btnAccentColor a:before,
.btLightSkin .btBtn.btnBorderless.btnAccentColor a:hover:before,
.btDarkSkin .btLightSkin .btBtn.btnBorderless.btnAccentColor a:hover,
.btDarkSkin .btLightSkin .btBtn.btnBorderless.btnAccentColor a:before,
.btDarkSkin .btLightSkin .btBtn.btnBorderless.btnAccentColor a:hover:before {
  color: #08487e !important;
}

.btLightSkin .btBtn.btnBorderless.btnNormalColor a:hover,
.btLightSkin .btBtn.btnBorderless.btnNormalColor a:before,
.btLightSkin .btBtn.btnBorderless.btnNormalColor a:hover:before,
.btDarkSkin .btLightSkin .btBtn.btnBorderless.btnNormalColor a:hover,
.btDarkSkin .btLightSkin .btBtn.btnBorderless.btnNormalColor a:before,
.btDarkSkin .btLightSkin .btBtn.btnBorderless.btnNormalColor a:hover:before {
  color: #535761 !important;
}

.btDarkSkin .btBtn.btnBorderless.btBtnAccentLight a:hover,
.btDarkSkin .btBtn.btnBorderless.btBtnAccentLight a:before,
.btDarkSkin .btBtn.btnBorderless.btBtnAccentLight a:hover:before,
.btDarkSkin .btBtn.btnBorderless.btnAccentColor a:hover,
.btDarkSkin .btBtn.btnBorderless.btnAccentColor a:before,
.btDarkSkin .btBtn.btnBorderless.btnAccentColor a:hover:before,
.btDarkSkin .btBtn.btnBorderless.btnNormalColor a:hover,
.btDarkSkin .btBtn.btnBorderless.btnNormalColor a:before,
.btDarkSkin .btBtn.btnBorderless.btnNormalColor a:hover:before,
.btLightSkin .btDarkSkin .btBtn.btnBorderless.btBtnAccentLight a:hover,
.btLightSkin .btDarkSkin .btBtn.btnBorderless.btBtnAccentLight a:before,
.btLightSkin .btDarkSkin .btBtn.btnBorderless.btBtnAccentLight a:hover:before,
.btLightSkin .btDarkSkin .btBtn.btnBorderless.btnAccentColor a:hover,
.btLightSkin .btDarkSkin .btBtn.btnBorderless.btnAccentColor a:before,
.btLightSkin .btDarkSkin .btBtn.btnBorderless.btnAccentColor a:hover:before,
.btLightSkin .btDarkSkin .btBtn.btnBorderless.btnNormalColor a:hover,
.btLightSkin .btDarkSkin .btBtn.btnBorderless.btnNormalColor a:before,
.btLightSkin .btDarkSkin .btBtn.btnBorderless.btnNormalColor a:hover:before {
  color: #fff !important;
}

.btBtn.btnIconRightPosition a.btIcoHolder:before {
  margin-left: 0;
  margin-right: -10px;
}

.btBtn:hover {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

.btBtn.btnBig {
  font-size: 16px;
}

.btBtn.btnSmall {
  font-size: 13px;
}

.btTextLeft .btBtn {
  margin-right: 20px;
}

.btTextRight .btBtn {
  margin-left: 20px;
}

.btTextCenter .btBtn {
  margin-left: 10px;
  margin-right: 10px;
}

.btTextRight .btBtn .btIco,
.btTextLeft .btBtn .btIco {
  margin-left: 0;
  margin-right: 0;
}

.btnNormal {
  display: inline-block;
}

.btLightSkin .btnFilled.btnAccentColor,
.btLightSkin .btnOutline.btnAccentColor:hover,
.btDarkSkin .btLightSkin .btnFilled.btnAccentColor,
.btDarkSkin .btLightSkin .btnOutline.btnAccentColor:hover,
.btDarkSkin .btnFilled.btnAccentColor,
.btDarkSkin .btnOutline.btnAccentColor:hover,
.btLightSkin .btDarkSkin .btnFilled.btnAccentColor,
.btLightSkin .btDarkSkin .btnOutline.btnAccentColor:hover {
  background: var(--secondry-color);
  color: #fff;
}

.btLightSkin .btnFilled.btnAccentColor a,
.btLightSkin .btnOutline.btnAccentColor:hover a,
.btDarkSkin .btLightSkin .btnFilled.btnAccentColor a,
.btDarkSkin .btLightSkin .btnOutline.btnAccentColor:hover a,
.btDarkSkin .btnFilled.btnAccentColor a,
.btDarkSkin .btnOutline.btnAccentColor:hover a,
.btLightSkin .btDarkSkin .btnFilled.btnAccentColor a,
.btLightSkin .btDarkSkin .btnOutline.btnAccentColor:hover a,
.btLightSkin .btnFilled.btnAccentColor .btIco a:before,
.btLightSkin .btnOutline.btnAccentColor:hover .btIco a:before,
.btDarkSkin .btLightSkin .btnFilled.btnAccentColor .btIco a:before,
.btDarkSkin .btLightSkin .btnOutline.btnAccentColor:hover .btIco a:before,
.btDarkSkin .btnFilled.btnAccentColor .btIco a:before,
.btDarkSkin .btnOutline.btnAccentColor:hover .btIco a:before,
.btLightSkin .btDarkSkin .btnFilled.btnAccentColor .btIco a:before,
.btLightSkin .btDarkSkin .btnOutline.btnAccentColor:hover .btIco a:before,
.btLightSkin .btnFilled.btnAccentColor button,
.btLightSkin .btnOutline.btnAccentColor:hover button,
.btDarkSkin .btLightSkin .btnFilled.btnAccentColor button,
.btDarkSkin .btLightSkin .btnOutline.btnAccentColor:hover button,
.btDarkSkin .btnFilled.btnAccentColor button,
.btDarkSkin .btnOutline.btnAccentColor:hover button,
.btLightSkin .btDarkSkin .btnFilled.btnAccentColor button,
.btLightSkin .btDarkSkin .btnOutline.btnAccentColor:hover button {
  color: #fff;
}

.btLightSkin .btnOutline.btnAccentColor,
.btDarkSkin .btLightSkin .btnOutline.btnAccentColor,
.btDarkSkin .btnOutline.btnAccentColor,
.btLightSkin .btDarkSkin .btnOutline.btnAccentColor {
  background-color: transparent;
  border: 2px solid var(--secondry-color);
  color: var(--secondry-color);
}

.btLightSkin .btnOutline.btnAccentColor a,
.btDarkSkin .btLightSkin .btnOutline.btnAccentColor a,
.btDarkSkin .btnOutline.btnAccentColor a,
.btLightSkin .btDarkSkin .btnOutline.btnAccentColor a,
.btLightSkin .btnOutline.btnAccentColor a:before,
.btDarkSkin .btLightSkin .btnOutline.btnAccentColor a:before,
.btDarkSkin .btnOutline.btnAccentColor a:before,
.btLightSkin .btDarkSkin .btnOutline.btnAccentColor a:before,
.btLightSkin .btnOutline.btnAccentColor button,
.btDarkSkin .btLightSkin .btnOutline.btnAccentColor button,
.btDarkSkin .btnOutline.btnAccentColor button,
.btLightSkin .btDarkSkin .btnOutline.btnAccentColor button {
  color: var(--secondry-color);
}

.btLightSkin .btnFilled.btnAccentColor:hover,
.btDarkSkin .btLightSkin .btnFilled.btnAccentColor:hover,
.btDarkSkin .btnFilled.btnAccentColor:hover,
.btLightSkin .btDarkSkin .btnFilled.btnAccentColor:hover {
  background: var(--secondry-color);
  color: #fff;
}

.btLightSkin .btnFilled.btnAccentColor:hover a,
.btDarkSkin .btLightSkin .btnFilled.btnAccentColor:hover a,
.btDarkSkin .btnFilled.btnAccentColor:hover a,
.btLightSkin .btDarkSkin .btnFilled.btnAccentColor:hover a,
.btLightSkin .btnFilled.btnAccentColor:hover .btIco a:before,
.btDarkSkin .btLightSkin .btnFilled.btnAccentColor:hover .btIco a:before,
.btDarkSkin .btnFilled.btnAccentColor:hover .btIco a:before,
.btLightSkin .btDarkSkin .btnFilled.btnAccentColor:hover .btIco a:before,
.btLightSkin .btnFilled.btnAccentColor:hover button,
.btDarkSkin .btLightSkin .btnFilled.btnAccentColor:hover button,
.btDarkSkin .btnFilled.btnAccentColor:hover button,
.btLightSkin .btDarkSkin .btnFilled.btnAccentColor:hover button {
  color: #fff;
}

.btLightSkin .btnFilled.btnNormalColor,
.btLightSkin .btnOutline.btnNormalColor:hover,
.btDarkSkin .btLightSkin .btnFilled.btnNormalColor,
.btDarkSkin .btLightSkin .btnOutline.btnNormalColor:hover,
.btDarkSkin .btnFilled.btnNormalColor,
.btDarkSkin .btnOutline.btnNormalColor:hover,
.btLightSkin .btDarkSkin .btnFilled.btnNormalColor,
.btLightSkin .btDarkSkin .btnOutline.btnNormalColor:hover {
  background: transparent;
  color: #fff;
  border: 1px solid white;
}

.btLightSkin .btnFilled.btnNormalColor a,
.btLightSkin .btnOutline.btnNormalColor:hover a,
.btDarkSkin .btLightSkin .btnFilled.btnNormalColor a,
.btDarkSkin .btLightSkin .btnOutline.btnNormalColor:hover a,
.btDarkSkin .btnFilled.btnNormalColor a,
.btDarkSkin .btnOutline.btnNormalColor:hover a,
.btLightSkin .btDarkSkin .btnFilled.btnNormalColor a,
.btLightSkin .btDarkSkin .btnOutline.btnNormalColor:hover a,
.btLightSkin .btnFilled.btnNormalColor .btIco a:before,
.btLightSkin .btnOutline.btnNormalColor:hover .btIco a:before,
.btDarkSkin .btLightSkin .btnFilled.btnNormalColor .btIco a:before,
.btDarkSkin .btLightSkin .btnOutline.btnNormalColor:hover .btIco a:before,
.btDarkSkin .btnFilled.btnNormalColor .btIco a:before,
.btDarkSkin .btnOutline.btnNormalColor:hover .btIco a:before,
.btLightSkin .btDarkSkin .btnFilled.btnNormalColor .btIco a:before,
.btLightSkin .btDarkSkin .btnOutline.btnNormalColor:hover .btIco a:before,
.btLightSkin .btnFilled.btnNormalColor button,
.btLightSkin .btnOutline.btnNormalColor:hover button,
.btDarkSkin .btLightSkin .btnFilled.btnNormalColor button,
.btDarkSkin .btLightSkin .btnOutline.btnNormalColor:hover button,
.btDarkSkin .btnFilled.btnNormalColor button,
.btDarkSkin .btnOutline.btnNormalColor:hover button,
.btLightSkin .btDarkSkin .btnFilled.btnNormalColor button,
.btLightSkin .btDarkSkin .btnOutline.btnNormalColor:hover button {
  color: #fff;
}

.btLightSkin .btnOutline.btnNormalColor,
.btDarkSkin .btLightSkin .btnOutline.btnNormalColor,
.btDarkSkin .btnOutline.btnNormalColor,
.btLightSkin .btDarkSkin .btnOutline.btnNormalColor {
  background-color: transparent;
  border: 2px solid #686d7a;
  color: #686d7a;
}

.btLightSkin .btnOutline.btnNormalColor a,
.btDarkSkin .btLightSkin .btnOutline.btnNormalColor a,
.btDarkSkin .btnOutline.btnNormalColor a,
.btLightSkin .btDarkSkin .btnOutline.btnNormalColor a,
.btLightSkin .btnOutline.btnNormalColor a:before,
.btDarkSkin .btLightSkin .btnOutline.btnNormalColor a:before,
.btDarkSkin .btnOutline.btnNormalColor a:before,
.btLightSkin .btDarkSkin .btnOutline.btnNormalColor a:before,
.btLightSkin .btnOutline.btnNormalColor button,
.btDarkSkin .btLightSkin .btnOutline.btnNormalColor button,
.btDarkSkin .btnOutline.btnNormalColor button,
.btLightSkin .btDarkSkin .btnOutline.btnNormalColor button {
  color: #686d7a;
}

.btLightSkin .btnFilled.btnNormalColor:hover,
.btDarkSkin .btLightSkin .btnFilled.btnNormalColor:hover,
.btDarkSkin .btnFilled.btnNormalColor:hover,
.btLightSkin .btDarkSkin .btnFilled.btnNormalColor:hover {
  background: var(--secondry-color);
  color: #fff;
  border: 1px solid var(--secondry-color);
}

.btLightSkin .btnFilled.btnNormalColor:hover a,
.btDarkSkin .btLightSkin .btnFilled.btnNormalColor:hover a,
.btDarkSkin .btnFilled.btnNormalColor:hover a,
.btLightSkin .btDarkSkin .btnFilled.btnNormalColor:hover a,
.btLightSkin .btnFilled.btnNormalColor:hover .btIco a:before,
.btDarkSkin .btLightSkin .btnFilled.btnNormalColor:hover .btIco a:before,
.btDarkSkin .btnFilled.btnNormalColor:hover .btIco a:before,
.btLightSkin .btDarkSkin .btnFilled.btnNormalColor:hover .btIco a:before,
.btLightSkin .btnFilled.btnNormalColor:hover button,
.btDarkSkin .btLightSkin .btnFilled.btnNormalColor:hover button,
.btDarkSkin .btnFilled.btnNormalColor:hover button,
.btLightSkin .btDarkSkin .btnFilled.btnNormalColor:hover button {
  color: #fff;
}

.btLightSkin .btnFilled.btnLightColor,
.btLightSkin .btnOutline.btnLightColor:hover,
.btDarkSkin .btLightSkin .btnFilled.btnLightColor,
.btDarkSkin .btLightSkin .btnOutline.btnLightColor:hover {
  background: rgba(0, 0, 0, .1);
  color: var(--primary-color);
}

.btLightSkin .btnFilled.btnLightColor a,
.btLightSkin .btnOutline.btnLightColor:hover a,
.btDarkSkin .btLightSkin .btnFilled.btnLightColor a,
.btDarkSkin .btLightSkin .btnOutline.btnLightColor:hover a,
.btLightSkin .btnFilled.btnLightColor .btIco a:before,
.btLightSkin .btnOutline.btnLightColor:hover .btIco a:before,
.btDarkSkin .btLightSkin .btnFilled.btnLightColor .btIco a:before,
.btDarkSkin .btLightSkin .btnOutline.btnLightColor:hover .btIco a:before,
.btLightSkin .btnFilled.btnLightColor button,
.btLightSkin .btnOutline.btnLightColor:hover button,
.btDarkSkin .btLightSkin .btnFilled.btnLightColor button,
.btDarkSkin .btLightSkin .btnOutline.btnLightColor:hover button {
  color: var(--primary-color);
}

.btLightSkin .btnOutline.btnLightColor,
.btDarkSkin .btLightSkin .btnOutline.btnLightColor {
  background-color: var(--secondry-color);
  border: 2px solid var(--secondry-color);
  color: var(--primary-color);
}

.btLightSkin .btnOutline.btnLightColor a,
.btDarkSkin .btLightSkin .btnOutline.btnLightColor a,
.btLightSkin .btnOutline.btnLightColor a:before,
.btDarkSkin .btLightSkin .btnOutline.btnLightColor a:before,
.btLightSkin .btnOutline.btnLightColor button,
.btDarkSkin .btLightSkin .btnOutline.btnLightColor button {
  color: white;
}

.btLightSkin .btnFilled.btnLightColor:hover,
.btDarkSkin .btLightSkin .btnFilled.btnLightColor:hover {
  background: rgba(0, 0, 0, .2);
  color: var(--primary-color);
}

.btLightSkin .btnFilled.btnLightColor:hover a,
.btDarkSkin .btLightSkin .btnFilled.btnLightColor:hover a,
.btLightSkin .btnFilled.btnLightColor:hover .btIco a:before,
.btDarkSkin .btLightSkin .btnFilled.btnLightColor:hover .btIco a:before,
.btLightSkin .btnFilled.btnLightColor:hover button,
.btDarkSkin .btLightSkin .btnFilled.btnLightColor:hover button {
  color: #666;
}

.btDarkSkin .btnFilled.btnLightColor,
.btDarkSkin .btnOutline.btnLightColor:hover,
.btLightSkin .btDarkSkin .btnFilled.btnLightColor,
.btLightSkin .btDarkSkin .btnOutline.btnLightColor:hover {
  background: rgba(255, 255, 255, .85);
  color: #666;
}

.btDarkSkin .btnFilled.btnLightColor a,
.btDarkSkin .btnOutline.btnLightColor:hover a,
.btLightSkin .btDarkSkin .btnFilled.btnLightColor a,
.btLightSkin .btDarkSkin .btnOutline.btnLightColor:hover a,
.btDarkSkin .btnFilled.btnLightColor .btIco a:before,
.btDarkSkin .btnOutline.btnLightColor:hover .btIco a:before,
.btLightSkin .btDarkSkin .btnFilled.btnLightColor .btIco a:before,
.btLightSkin .btDarkSkin .btnOutline.btnLightColor:hover .btIco a:before,
.btDarkSkin .btnFilled.btnLightColor button,
.btDarkSkin .btnOutline.btnLightColor:hover button,
.btLightSkin .btDarkSkin .btnFilled.btnLightColor button,
.btLightSkin .btDarkSkin .btnOutline.btnLightColor:hover button {
  color: #666;
}

.btDarkSkin .btnOutline.btnLightColor,
.btLightSkin .btDarkSkin .btnOutline.btnLightColor {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
}

.btDarkSkin .btnOutline.btnLightColor a,
.btLightSkin .btDarkSkin .btnOutline.btnLightColor a,
.btDarkSkin .btnOutline.btnLightColor a:before,
.btLightSkin .btDarkSkin .btnOutline.btnLightColor a:before,
.btDarkSkin .btnOutline.btnLightColor button,
.btLightSkin .btDarkSkin .btnOutline.btnLightColor button {
  color: #fff;
}

.btDarkSkin .btnFilled.btnLightColor:hover,
.btLightSkin .btDarkSkin .btnFilled.btnLightColor:hover {
  background: rgba(255, 255, 255, .75);
  color: #666;
}

.btDarkSkin .btnFilled.btnLightColor:hover a,
.btLightSkin .btDarkSkin .btnFilled.btnLightColor:hover a,
.btDarkSkin .btnFilled.btnLightColor:hover .btIco a:before,
.btLightSkin .btDarkSkin .btnFilled.btnLightColor:hover .btIco a:before,
.btDarkSkin .btnFilled.btnLightColor:hover button,
.btLightSkin .btDarkSkin .btnFilled.btnLightColor:hover button {
  color: #666;
}

/* Grid and tiles
---------------------------------------------------------------*/

.btGridContainer {
  overflow: hidden;
}

.gridItem {
  opacity: .05;
  -webkit-transition: transform 500ms cubic-bezier(.175, .885, .320, 1.275) 300ms, opacity 500ms ease 300ms, background-color 300ms ease;
  -moz-transition: transform 500ms cubic-bezier(.175, .885, .320, 1.275) 300ms, opacity 500ms ease 300ms, background-color 300ms ease;
  transition: transform 500ms cubic-bezier(.175, .885, .320, 1.275) 300ms, opacity 500ms ease 300ms, background-color 300ms ease;
  overflow: hidden;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.gridItem .slick-prev,
.gridItem .slick-next {
  display: none !important;
}

.gridItem .btTileBox .captionTxt .btSubTitle,
.gridItem .btGridContent .btSuperTitle,
.gridItem .btTileBox .btShowTitle .btSubTitle {
  text-transform: uppercase;
}

.gridItem .btGridContent .btSuperTitle {
  font-weight: 400;
}

.gridItem.btMarkedImage {
  -webkit-transform: scale(.9);
  -moz-transform: scale(.9);
  -ms-transform: scale(.9);
  transform: scale(.9);
  opacity: .8;
}

.btLightSkin .gridItem,
.btDarkSkin .btLightSkin .gridItem {
  background-color: rgba(0, 0, 0, .07);
}

.btLightSkin .gridItem .btGridContent .btSuperTitle,
.btDarkSkin .btLightSkin .gridItem .btGridContent .btSuperTitle,
.btLightSkin .gridItem .btGridContent .btSuperTitle a,
.btDarkSkin .btLightSkin .gridItem .btGridContent .btSuperTitle a {
  color: #7E7E7E;
}

.btLightSkin .gridItem .btGridContent .btSuperTitle a:hover,
.btDarkSkin .btLightSkin .gridItem .btGridContent .btSuperTitle a:hover {
  color: #686d7a;
}

.btLightSkin .gridItem:hover,
.btDarkSkin .btLightSkin .gridItem:hover {
  background-color: rgba(0, 0, 0, .1);
  -webkit-transition: background-color 300ms ease;
  -moz-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
}

.btDarkSkin .gridItem,
.btLightSkin .btDarkSkin .gridItem {
  background-color: rgba(255, 255, 255, .07);
}

.btDarkSkin .gridItem .btGridContent .btSuperTitle,
.btLightSkin .btDarkSkin .gridItem .btGridContent .btSuperTitle,
.btDarkSkin .gridItem .btGridContent .btSuperTitle a,
.btLightSkin .btDarkSkin .gridItem .btGridContent .btSuperTitle a {
  color: #7E7E7E;
}

.btDarkSkin .gridItem .btGridContent .btSuperTitle a:hover,
.btLightSkin .btDarkSkin .gridItem .btGridContent .btSuperTitle a:hover {
  color: #686d7a;
}

.btDarkSkin .gridItem:hover,
.btLightSkin .btDarkSkin .gridItem:hover {
  background-color: rgba(255, 255, 255, .1);
  -webkit-transition: background-color 300ms ease;
  -moz-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
}

.gridItem.btGridItemLoaded {
  opacity: 1;
}

.gridItem:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 10;
}

.gridItem:before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  pointer-events: none;
  z-index: 11;
}

.btDarkSkin .gridItem:after,
.btLightSkin .btDarkSkin .gridItem:after {
  border-color: #313131 !important;
}

.btDarkSkin .gridItem:before,
.btLightSkin .btDarkSkin .gridItem:before {
  border-color: #313131 !important;
}

.btLightSkin .gridItem:after,
.btDarkSkin .btLightSkin .gridItem:after {
  border-color: #fff !important;
}

.btLightSkin .gridItem:before,
.btDarkSkin .btLightSkin .gridItem:before {
  border-color: #fff !important;
}

.btNoMore {
  display: none;
  opacity: 1;
}

.btGridContent {
  padding: 10px;
}

.btGridContent .btGridShare {
  margin-top: 13.33333px;
  margin-bottom: 13.33333px;
}

.btDarkSkin .btGridContent .btGridShare .btIco .btIcoHolder:before,
.btLightSkin .btDarkSkin .btGridContent .btGridShare .btIco .btIcoHolder:before {
  background: #313131;
}

.btLightSkin .btGridContent .btGridShare .btIco .btIcoHolder:before,
.btDarkSkin .btLightSkin .btGridContent .btGridShare .btIco .btIcoHolder:before {
  background: #fff;
}

.btGridGap0 .gridItem:after {
  border: 0 solid white;
  border-radius: 3px;
}

.btGridGap0 .gridItem:before {
  border: 0 solid white;
}

.btGridGap0 .gridItem:after,
.btGridGap0 .gridItem .bpgPhoto,
.btGridGap .gridItem:after,
.btGridGap .gridItem .bpgPhoto {
  border-radius: 0;
}

.btGridGap0 .btGridContent {
  padding: 10px;
}

.boldSection .btGridGap0 .tilesWall {
  margin: 0;
}

.boldSection.gutter .btGridGap0 .tilesWall {
  margin: 0 -0px;
}

.btGridGap1 .gridItem:after {
  border: 1px solid white;
  border-radius: 4px;
}

.btGridGap1 .gridItem:before {
  border: 1px solid white;
}

.btGridGap0 .gridItem:after,
.btGridGap0 .gridItem .bpgPhoto,
.btGridGap .gridItem:after,
.btGridGap .gridItem .bpgPhoto {
  border-radius: 0;
}

.btGridGap1 .btGridContent {
  padding: 11px;
}

.boldSection .btGridGap1 .tilesWall {
  margin: 1px;
}

.boldSection.gutter .btGridGap1 .tilesWall {
  margin: 1px -1px;
}

.btGridGap2 .gridItem:after {
  border: 2px solid white;
  border-radius: 5px;
}

.btGridGap2 .gridItem:before {
  border: 2px solid white;
}

.btGridGap0 .gridItem:after,
.btGridGap0 .gridItem .bpgPhoto,
.btGridGap .gridItem:after,
.btGridGap .gridItem .bpgPhoto {
  border-radius: 0;
}

.btGridGap2 .btGridContent {
  padding: 12px;
}

.boldSection .btGridGap2 .tilesWall {
  margin: 2px;
}

.boldSection.gutter .btGridGap2 .tilesWall {
  margin: 2px -2px;
}

.btGridGap3 .gridItem:after {
  border: 3px solid white;
  border-radius: 6px;
}

.btGridGap3 .gridItem:before {
  border: 3px solid white;
}

.btGridGap0 .gridItem:after,
.btGridGap0 .gridItem .bpgPhoto,
.btGridGap .gridItem:after,
.btGridGap .gridItem .bpgPhoto {
  border-radius: 0;
}

.btGridGap3 .btGridContent {
  padding: 13px;
}

.boldSection .btGridGap3 .tilesWall {
  margin: 3px;
}

.boldSection.gutter .btGridGap3 .tilesWall {
  margin: 3px -3px;
}

.btGridGap4 .gridItem:after {
  border: 4px solid white;
  border-radius: 7px;
}

.btGridGap4 .gridItem:before {
  border: 4px solid white;
}

.btGridGap0 .gridItem:after,
.btGridGap0 .gridItem .bpgPhoto,
.btGridGap .gridItem:after,
.btGridGap .gridItem .bpgPhoto {
  border-radius: 0;
}

.btGridGap4 .btGridContent {
  padding: 14px;
}

.boldSection .btGridGap4 .tilesWall {
  margin: 4px;
}

.boldSection.gutter .btGridGap4 .tilesWall {
  margin: 4px -4px;
}

.btGridGap5 .gridItem:after {
  border: 5px solid white;
  border-radius: 8px;
}

.btGridGap5 .gridItem:before {
  border: 5px solid white;
}

.btGridGap0 .gridItem:after,
.btGridGap0 .gridItem .bpgPhoto,
.btGridGap .gridItem:after,
.btGridGap .gridItem .bpgPhoto {
  border-radius: 0;
}

.btGridGap5 .btGridContent {
  padding: 15px;
}

.boldSection .btGridGap5 .tilesWall {
  margin: 5px;
}

.boldSection.gutter .btGridGap5 .tilesWall {
  margin: 5px -5px;
}

.btCatFilter {
  padding: 10px 0;
  line-height: 3;
  text-transform: uppercase;
}

.btCatFilter .btCatFilterItem {
  margin: 0 5px;
  padding: 0 5px;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  color: var(--secondry-color);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
  position: relative;
}

.btCatFilter .btCatFilterItem:after {
  content: " ";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: -5px;
  left: 0;
  opacity: 0;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
  display: block;
}

.btCatFilter .btCatFilterItem:hover {
  color: #686d7a;
  border-bottom-color: #686d7a;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.btCatFilter .btCatFilterItem:hover:after {
  background: #686d7a;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
  opacity: 1;
  bottom: 0;
}

.btCatFilter .btCatFilterTitle {
  font-weight: 300;
  display: inline-block;
}

.btCatFilter .btCatFilterItem.active {
  border-bottom-color: transparent;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.btCatFilter .btCatFilterItem.active:after {
  opacity: 0;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
  bottom: -5px;
}

.boldSection.gutter .btCatFilter {
  border-radius: 2px;
}

.btLightSkin .btCatFilter,
.btDarkSkin .btLightSkin .btCatFilter {
  background: rgba(0, 0, 0, .05);
}

.btLightSkin .btCatFilterItem.active,
.btDarkSkin .btLightSkin .btCatFilterItem.active,
.btLightSkin .btCatFilterTitle,
.btDarkSkin .btLightSkin .btCatFilterTitle {
  color: #7E7E7E;
}

.btDarkSkin .btCatFilter,
.btLightSkin .btDarkSkin .btCatFilter {
  background: rgba(255, 255, 255, .02);
}

.btDarkSkin .btCatFilterItem,
.btLightSkin .btDarkSkin .btCatFilterItem {
  color: #fff;
}

.btDarkSkin .btCatFilterItem.active,
.btLightSkin .btDarkSkin .btCatFilterItem.active,
.btDarkSkin .btCatFilterTitle,
.btLightSkin .btDarkSkin .btCatFilterTitle {
  color: #7E7E7E;
}

/* Media box
---------------------------------------------------------------*/

.btMediaBox {
  position: relative;
  overflow: hidden;
}

.btMediaBox img {
  display: block;
  width: 100%;
  height: auto;
}

.btMediaBox .aspectVideo {
  visibility: hidden;
}

.btMediaBox iframe {
  display: block;
  border: none;
}

.btMediaBox.video iframe {
  display: block;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
  border: none;
}

.btMediaBox.audio iframe {
  display: block;
  width: 100% !important;
}

.btMediaBox blockquote {
  text-align: center;
  position: relative;
  display: block;
  padding: 20px;
  margin: 0;
  background-color: var(--secondry-color);
  font-weight: 300;
}

.btArticle .btMediaBox blockquote {
  font-size: 40px;
}

.btMediaBox blockquote cite {
  display: inline-block;
  padding: 20px 0;
  border-top: 3px solid #fff;
  font-size: 21px;
}

.btMediaBox.btLink {
  padding: 20px 20px 40px 20px;
  background-color: var(--secondry-color);
  text-align: center;
  display: block;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.btMediaBox blockquote {
  background-color: var(--secondry-color);
}

.btLightSkin .gridItem:hover .btMediaBox.btLink a,
.btDarkSkin .btLightSkin .gridItem:hover .btMediaBox.btLink a,
.btLightSkin .btArticle .btMediaBox.btLink:hover a,
.btDarkSkin .btLightSkin .btArticle .btMediaBox.btLink:hover a,
.btLightSkin .gridItem:hover .btMediaBox blockquote a,
.btDarkSkin .btLightSkin .gridItem:hover .btMediaBox blockquote a,
.btLightSkin .btArticle .btMediaBox blockquote :hover a,
.btDarkSkin .btLightSkin .btArticle .btMediaBox blockquote :hover a {
  color: #fff;
}

.btDarkSkin .gridItem:hover .btMediaBox.btLink a,
.btLightSkin .btDarkSkin .gridItem:hover .btMediaBox.btLink a,
.btDarkSkin .btArticle .btMediaBox.btLink:hover a,
.btLightSkin .btDarkSkin .btArticle .btMediaBox.btLink:hover a,
.btDarkSkin .gridItem:hover .btMediaBox blockquote a,
.btLightSkin .btDarkSkin .gridItem:hover .btMediaBox blockquote a,
.btDarkSkin .btArticle .btMediaBox blockquote :hover a,
.btLightSkin .btDarkSkin .btArticle .btMediaBox blockquote :hover a {
  color: #fff;
}

.btMediaBox.btLink a strong {
  display: block;
  font-size: 26px;
  padding: 10px;
}

.btArticle .btMediaBox.btLink a strong {
  font-size: 52px;
}

/* Prev next button
---------------------------------------------------------------*/

h4.nbs {
  height: 100px;
  z-index: 1;
}

h4.nbs a {
  display: table;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100px;
  cursor: pointer;
}

h4.nbs.nsPrev a:before,
h4.nbs.nsNext a:after {
  color: #fff;
  display: block;
  width: 100px;
  height: 100px;
  text-align: center;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center center;
  font: 100 56px/56px Cargo;
  position: absolute;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 22px 0;
  top: 0;
  left: 0;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  transition: all 200ms ease;
  -webkit-transform: translate3d(1px, 0, 2px) rotate(.001deg);
  -moz-transform: translate3d(1px, 0, 2px) rotate(.001deg);
  -ms-transform: translate3d(1px, 0, 2px) rotate(.001deg);
  transform: translate3d(1px, 0, 2px) rotate(.001deg);
  -webkit-perspective: 2000;
  -moz-perspective: 2000;
  perspective: 2000;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
  text-shadow: 0 1px 0 #000;
}

.btLightSkin .boldPhotoSlide h4.nbs.nsPrev a:before,
.btDarkSkin .btLightSkin .boldPhotoSlide h4.nbs.nsPrev a:before,
.btLightSkin .boldPhotoSlide h4.nbs.nsNext a:after,
.btDarkSkin .btLightSkin .boldPhotoSlide h4.nbs.nsNext a:after,
.btDarkSkin .boldPhotoSlide h4.nbs.nsPrev a:before,
.btLightSkin .btDarkSkin .boldPhotoSlide h4.nbs.nsPrev a:before,
.btDarkSkin .boldPhotoSlide h4.nbs.nsNext a:after,
.btLightSkin .btDarkSkin .boldPhotoSlide h4.nbs.nsNext a:after {
  color: #fff;
  text-shadow: 0 1px 0 #000;
}

h4.nbs.nsNext a:after {
  left: auto;
  right: 0;
}

.btNextPrevRow .neighboringArticles h4.nbs.nsPrev {
  padding-left: 100px;
}

.btNextPrevRow .neighboringArticles h4.nbs.nsPrev a:before {
  left: -100px;
}

.btNextPrevRow .neighboringArticles h4.nbs.nsPrev a .nbsImage {
  left: -100px;
  top: 0;
  position: absolute;
}

.btNextPrevRow .neighboringArticles h4.nbs.nsNext {
  padding-right: 100px;
}

.btNextPrevRow .neighboringArticles h4.nbs.nsNext a:after {
  right: -100px;
}

.btNextPrevRow .neighboringArticles h4.nbs.nsNext a .nbsImage {
  right: -100px;
  top: 0;
  position: absolute;
}

.neighboringArticles h4.nbs.nsNext a .nbsImage .nbsImgHolder,
.neighboringArticles h4.nbs.nsPrev a .nbsImage .nbsImgHolder {
  opacity: 0;
}

.neighboringArticles h4.nbs.nsNext a:hover .nbsImage .nbsImgHolder,
.neighboringArticles h4.nbs.nsPrev a:hover .nbsImage .nbsImgHolder {
  opacity: 1;
}

h4.nbs.nsPrev a:hover:before {
  -webkit-transform: translate3d(-10px, 0, 2px) rotate(.001deg);
  -moz-transform: translate3d(-10px, 0, 2px) rotate(.001deg);
  -ms-transform: translate3d(-10px, 0, 2px) rotate(.001deg);
  transform: translate3d(-10px, 0, 2px) rotate(.001deg);
}

h4.nbs.nsNext a:hover:after {
  -webkit-transform: translate3d(10px, 0, 2px) rotate(.001deg);
  -moz-transform: translate3d(10px, 0, 2px) rotate(.001deg);
  -ms-transform: translate3d(10px, 0, 2px) rotate(.001deg);
  transform: translate3d(10px, 0, 2px) rotate(.001deg);
}

.btLightSkin .boldClientList h4.nbs.nsPrev a:before,
.btLightSkin .boldClientList h4.nbs.nsNext a:after,
.btLightSkin .btTestimonialsSlider h4.nbs.nsPrev a:before,
.btLightSkin .btTestimonialsSlider h4.nbs.nsNext a:after,
.btDarkSkin .btLightSkin .boldClientList h4.nbs.nsPrev a:before,
.btDarkSkin .btLightSkin .boldClientList h4.nbs.nsNext a:after,
.btDarkSkin .btLightSkin .btTestimonialsSlider h4.nbs.nsPrev a:before,
.btDarkSkin .btLightSkin .btTestimonialsSlider h4.nbs.nsNext a:after {
  color: #333;
  text-shadow: none;
}

.btDarkSkin .boldClientList h4.nbs.nsPrev a:before,
.btDarkSkin .boldClientList h4.nbs.nsNext a:after,
.btDarkSkin .btTestimonialsSlider h4.nbs.nsPrev a:before,
.btDarkSkin .btTestimonialsSlider h4.nbs.nsNext a:after,
.btLightSkin .btDarkSkin .boldClientList h4.nbs.nsPrev a:before,
.btLightSkin .btDarkSkin .boldClientList h4.nbs.nsNext a:after,
.btLightSkin .btDarkSkin .btTestimonialsSlider h4.nbs.nsPrev a:before,
.btLightSkin .btDarkSkin .btTestimonialsSlider h4.nbs.nsNext a:after {
  color: #fff;
  text-shadow: 0;
}

.btLightSkin h4.nbs.nsPrev a:hover:before,
.btDarkSkin .btLightSkin h4.nbs.nsPrev a:hover:before,
.btLightSkin h4.nbs.nsNext a:hover:after,
.btDarkSkin .btLightSkin h4.nbs.nsNext a:hover:after {
  opacity: .75;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.btDarkSkin h4.nbs.nsPrev a:hover:before,
.btLightSkin .btDarkSkin h4.nbs.nsPrev a:hover:before,
.btDarkSkin h4.nbs.nsNext a:hover:after,
.btLightSkin .btDarkSkin h4.nbs.nsNext a:hover:after {
  opacity: .75;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

h4.nbs.nsNext a:after {
  content: "\e665";
  content: "❯";
  font-size: 40px;
}

h4.nbs.nsPrev a:before {
  content: "\e664";
  content: '>';
  font-size: 40px;
}

h4.nbs.nsPrev a:before {
  content: "\e664";
  content: '❮';
  font-size: 40px;
}

.nbsImage {
  display: table-cell;
  width: 100px;
  height: 100px;
}

.nbsImgHolder {
  display: block;
  width: 100px;
  height: 100px;
  background-color: #ccc;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  border: 3px solid var(--secondry-color);
}

h4.nbs,
h4.nbs * {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  perspective: 1000;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

h4.nbs.nsNext a .nbsImage .nbsImgHolder {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

h4.nbs.nsNext a:hover .nbsImage .nbsImgHolder {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

h4.nbs.nsPrev a .nbsImage .nbsImgHolder {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

h4.nbs.nsPrev a:hover .nbsImage .nbsImgHolder {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

.neighboringArticles h4.nbs.nsNext a .nbsImage .nbsImgHolder,
.neighboringArticles h4.nbs.nsPrev a .nbsImage .nbsImgHolder {
  opacity: 0;
}

.neighboringArticles h4.nbs.nsNext a:hover .nbsImage .nbsImgHolder,
.neighboringArticles h4.nbs.nsPrev a:hover .nbsImage .nbsImgHolder {
  opacity: 1;
}

.neighboringArticles h4.nbs.nsNext a:hover:after,
.neighboringArticles h4.nbs.nsPrev a:hover:after,
.neighboringArticles h4.nbs.nsNext a:hover:before,
.neighboringArticles h4.nbs.nsPrev a:hover:before {
  color: #FFF;
}

.nbsItem {
  display: table-cell;
  vertical-align: middle;
  padding: 0 10px;
}

span.nbsDir {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  color: #686d7a;
}

span.nbsTitle {
  display: block;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
}

.btLightSkin span.nbsTitle,
.btDarkSkin .btLightSkin span.nbsTitle {
  color: var(--secondry-color);
}

.btDarkSkin span.nbsTitle,
.btLightSkin .btDarkSkin span.nbsTitle {
  color: #fff;
}

.nsNext {
  float: right;
  right: 0;
}

.nsPrev {
  float: left;
  left: 0;
}

/* Slick Slider defaults
---------------------------------------------------------------*/

.slidedItem .btSliderPort {
  display: table;
  width: 100%;
}

.autoSliderHeight .slidedItem .btSliderPort {
  padding: 50px;
}

.smallSliderHeight .slidedItem .btSliderPort {
  height: 40vh;
}

.mediumSliderHeight .slidedItem .btSliderPort {
  height: 70vh;
}

.largeSliderHeight .slidedItem .btSliderPort {
  height: 100vh;
}

.btSlideGutter {
  max-width: 1160px;
  margin: 0 auto;
  width: auto;
  height: auto;
}

.slidedItem {
  -webkit-transition: opacity 360ms ease 0s;
  -moz-transition: opacity 360ms ease 0s;
  transition: opacity 360ms ease 0s;
  z-index: 3;
}

.btGhost .slidedVariable .slidedItem.firstItem,
.btGhost .slidedVariable .slidedItem.firstItem .btSliderPort {
  height: 100vh;
}

.slidedVariable .slidedItem.firstItem,
.slidedVariable .slidedItem.firstItem .btSliderPort {
  width: 100vw;
}

.slidedItem .btSlidePane {
  -webkit-transition: transform 1s cubic-bezier(.250, .460, .450, .940) .5s, opacity 300ms ease;
  -moz-transition: transform 1s cubic-bezier(.250, .460, .450, .940) .5s, opacity 300ms ease;
  transition: transform 1s cubic-bezier(.250, .460, .450, .940) .5s, opacity 300ms ease;
  -webkit-transform: translate3d(100px, 0, 0) !important;
  -moz-transform: translate3d(100px, 0, 0) !important;
  -ms-transform: translate3d(100px, 0, 0) !important;
  transform: translate3d(100px, 0, 0) !important;
  opacity: 0;
  padding-left: 100px;
  padding-right: 100px;
}

html[data-useragent*="Firefox/52.0"] .slidedItem .btSlidePane {
  opacity: 1;
}

.slidedItem.slick-center .btSlidePane,
.slidedItem.slick-current .btSlidePane,
.slidedItem.slick-active .btSlidePane {
  -webkit-transform: translate3d(0, 0, 0) !important;
  -moz-transform: translate3d(0, 0, 0) !important;
  -ms-transform: translate3d(0, 0, 0) !important;
  transform: translate3d(0, 0, 0) !important;
  opacity: 1;
}

.slidedItem .btSliderPort .btSliderCell {
  display: table-cell;
  vertical-align: middle;
  padding-bottom: 50px;
  padding-top: 50px;
}

.variableImg {
  height: 100vh;
  overflow: hidden;
}

.variableImg img {
  height: 100%;
  width: auto;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-slide {
  display: none;
  float: left;
  min-height: 1px;
}


/* services images grid gallery start */
.image-gallery-grid {
  position: relative;
  height: 630px;
}

.image-gallery-grid .image-gallery-grid-one {
  opacity: 1;
  position: absolute;
  width: 576px;
  height: 288px;
  left: 0;
  top: 0
}

.image-gallery-grid .image-gallery-grid-second {
  opacity: 1;
  position: absolute;
  width: 289px;
  height: 288px;
  left: 576px;
  top: 0
}

.image-gallery-grid .image-gallery-grid-third {
  opacity: 1;
  position: absolute;
  width: 288px;
  height: 288px;
  left: 0;
  top: 288px
}

.image-gallery-grid .image-gallery-grid-four {
  opacity: 1;
  position: absolute;
  width: 577px;
  height: 288px;
  left: 288px;
  top: 288px
}


/* services images grid gallery end */




.btSliderHideArrows.slided h4.nbs {
  display: none;
  height: 0;
  width: 0;
  visibility: hidden;
}

.gutter .boldClientList .slick-slider h4.nbs.nsNext {
  right: -100px;
}

.gutter .boldClientList .slick-slider h4.nbs.nsPrev {
  left: -100px;
}

.gutter .boldPhotoSlide.slick-slider h4.nbs.nsNext {
  right: 5px;
}

.gutter .boldPhotoSlide.slick-slider h4.nbs.Prev {
  left: 5px;
}

.btTestimonialsSlider .slidedItem {
  padding-right: 60px;
  padding-left: 60px;
}

.btTestimonialsSlider .slidedItem .btSliderPort {
  padding: 0;
}

.btTestimonialsSlider .slidedItem .btSliderPort .btSliderCell {
  padding: 0;
}

.btTestimonialsSlider .slidedItem .btSliderPort .btCircleImage {
  border: 0;
  width: 100%;
  border: 0;
  border-radius: 0;
  text-align: center;
}

.btTestimonialsSlider .slidedItem .btSliderPort .btCircleImage .btImage {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  border-width: 4px;
  border-style: solid;
}

.btTestimonialsSlider .slidedItem .btSliderPort .btText {
  text-align: left;
  font-size: 14px;
}

.btTestimonialsSlider .slidedItem .btSliderPort .btText blockquote {
  font-size: 18px;
}

.btTestimonialsSlider .slidedItem .btSliderPort .header h4 {
  margin-bottom: 0;
  text-transform: uppercase;
  text-align: center;
}

.btTestimonialsSlider .slidedItem .btSliderPort .header .btSubTitle,
.btTestimonialsSlider .slidedItem .btSliderPort .header .btSuperTitle {
  text-align: center;
}

.btTestimonialsSlider .slidedItem .btSliderPort .btCircleImage {
  border-width: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btTestimonialsSlider .slidedItem .btSliderPort .bpgPhoto {
  display: inline-block;
  float: none;
}

.btLightSkin .btTestimonialsSlider .slidedItem .btSliderPort .btSliderCell .header .btSubTitle,
.btDarkSkin .btLightSkin .btTestimonialsSlider .slidedItem .btSliderPort .btSliderCell .header .btSubTitle {
  color: #686d7a;
}

.btLightSkin .btTestimonialsSlider .slidedItem .btSliderPort .btSliderCell .btCircleImage,
.btDarkSkin .btLightSkin .btTestimonialsSlider .slidedItem .btSliderPort .btSliderCell .btCircleImage {
  border-color: #686d7a;
}

.btLightSkin .btTestimonialsSlider .slidedItem .btSliderPort .btSliderCell .btSliderPort .btCircleImage .btImage,
.btDarkSkin .btLightSkin .btTestimonialsSlider .slidedItem .btSliderPort .btSliderCell .btSliderPort .btCircleImage .btImage {
  border-color: #686d7a;
}

.btDarkSkin .btTestimonialsSlider .slidedItem .btSliderPort .btSliderCell .header .btSubTitle,
.btLightSkin .btDarkSkin .btTestimonialsSlider .slidedItem .btSliderPort .btSliderCell .header .btSubTitle {
  color: rgba(255, 255, 255, .6);
}

.btDarkSkin .btTestimonialsSlider .slidedItem .btSliderPort .btSliderCell .btCircleImage,
.btLightSkin .btDarkSkin .btTestimonialsSlider .slidedItem .btSliderPort .btSliderCell .btCircleImage {
  border-color: #fff;
}

.btDarkSkin .btTestimonialsSlider .slidedItem .btSliderPort .btSliderCell .btSliderPort .btCircleImage .btImage,
.btLightSkin .btDarkSkin .btTestimonialsSlider .slidedItem .btSliderPort .btSliderCell .btSliderPort .btCircleImage .btImage {
  border-color: #fff;
}

.btSlidePane>.btQuoteBooking {
  padding: 15px 20px 20px 20px;
  position: absolute;
  top: 80px;
  right: 0;
  width: 450px;
  background-color: rgba(0, 0, 0, .4) !important;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .25), 0 -1px 1px rgba(0, 0, 0, .125) !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .25), 0 -1px 1px rgba(0, 0, 0, .125) !important;
  opacity: 1;
}

.btSlidePane>.btQuoteBooking .btQuoteBookingForm {
  margin: 0;
}

.btSlidePane>.btQuoteBooking .btQuoteItem {
  margin-bottom: 20px;
  padding-left: 120px;
}

.btSlidePane>.btQuoteBooking .btQuoteItem label {
  width: 120px;
  margin-left: -120px;
}

.btSlidePane>.btQuoteBooking .btQuoteBookingForm.btActive .btTotalNextWrapper,
.btSlidePane>.btQuoteBooking .btQuoteBookingForm .btTotalNextWrapper {
  padding-right: 0;
}

.btSlidePane>.btQuoteBooking .btQuoteBookingForm.btActive .btTotalNextWrapper .btContactNext,
.btSlidePane>.btQuoteBooking .btQuoteBookingForm .btTotalNextWrapper .btContactNext {
  display: none;
}

.btNarrowHeader {
  max-width: 600px;
}

.btGetInfo {
  display: block;
  position: absolute;
  z-index: 7;
  bottom: 15px;
  left: 20px;
}

.btCloseGhost {
  display: block;
  position: absolute;
  z-index: 7;
  top: 10px;
  right: 10px;
}

.btCloseGhost .btIco.medium .btIcoHolder:before {
  font-size: 54px;
  line-height: 60px;
}

.single-post .btGhost .boldRow.btTableRow .rowItem {
  position: static;
}

.btInfoBar {
  display: table;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 6;
  background-color: rgba(0, 0, 0, .7);
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: transform 260ms ease;
  -moz-transition: transform 260ms ease;
  transition: transform 260ms ease;
}

.btInfoBar.open {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.btInfoBarMeta {
  display: table-cell;
  vertical-align: middle;
  padding: 17px 0 17px 45px;
}

.btInfoBarMeta p {
  display: inline-block;
  margin: 0 0 0 15px;
  font-weight: 400;
  color: white;
  text-transform: uppercase;
  font-weight: 300;
}

.btInfoBarMeta p strong {
  color: var(--secondry-color);
  padding-right: 4px;
  font-weight: 900;
}

.btGhost dd {
  border-bottom: 1px solid rgba(255, 255, 255, .1);
  margin-bottom: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
  font-weight: 500;
}

.btGhost dd:last-child,
.btArticleMeta dd:last-child {
  border-bottom: 0;
}

.btArticleMeta dd {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  margin-bottom: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
  font-weight: 500;
}

.btDarkSkin .btArticleMeta dd,
.btLightSkin .btDarkSkin .btArticleMeta dd {
  border-bottom: 1px solid rgba(255, 255, 255, .1);
}

/* Client list
---------------------------------------------------------------*/

.boldClientList {
  position: relative;
}

.bclPort {
  max-width: 1160px;
  position: static !important;
  margin: 0 auto;
  overflow: hidden;
}

.bclItem {
  position: relative;
  margin: 0;
}

.bclItem .bclItemChild>div {
  -webkit-transform: translate3d(0, .1%, 0);
  -moz-transform: translate3d(0, .1%, 0);
  -ms-transform: translate3d(0, .1%, 0);
  transform: translate3d(0, .1%, 0);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 10;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: transparent;
  background-position: center center;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all ease .5s;
  -moz-transition: all ease .5s;
  transition: all ease .5s;
}

.bclItem .bclItemChild>div:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all ease .5s;
  -moz-transition: all ease .5s;
  transition: all ease .5s;
}

.bclItem .bclItemChild a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.bclItem .bclItemChild {
  display: block;
  margin: 10%;
  position: relative;
}

.bclItem img {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 1;
  visibility: hidden;
  pointer-events: none;
}

.boldClientRegularList {
  position: relative;
  overflow: hidden;
}

.boldClientRegularList .bclItem {
  float: left;
  margin: 0 0 -2px;
  position: relative;
  padding: 0;
}

.boldClientRegularList .bclItem:before {
  display: none;
}

.btDarkSkin .boldClientRegularList .bclItem,
.btLightSkin .btDarkSkin .boldClientRegularList .bclItem {
  border-bottom: 1px solid rgba(255, 255, 255, .1);
  border-right: 1px solid rgba(255, 255, 255, .1);
}

.btLightSkin .boldClientRegularList .bclItem,
.btDarkSkin .btLightSkin .boldClientRegularList .bclItem {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  border-right: 1px solid rgba(0, 0, 0, .1);
}

.boldClientRegularList .bclItem {
  width: 100%;
}

.col-sm-6 .boldClientRegularList .bclItem {
  width: 33.3333333%;
}

.col-sm-6 .boldClientRegularList .bclItem:nth-child(3n + 1) {
  clear: left;
}

.col-sm-6 .boldClientRegularList .bclItem:nth-child(3n) {
  border-right: 1px solid transparent;
}

.col-ms-12 .boldClientRegularList .bclItem {
  width: 16.6666667%;
}

.col-ms-12 .boldClientRegularList .bclItem:nth-child(6n) {
  border-right: 1px solid transparent;
}

.col-ms-12 .boldClientRegularList .bclItem:nth-child(6n + 1) {
  clear: left;
}

@media (max-width: 1200px) {
  .bclPort {
    max-width: 1200px;
  }
}

@media (max-width: 768px) {
  .col-ms-12 .boldClientRegularList .bclItem:nth-child(6n + 1) {
    clear: none;
  }

  .col-ms-12 .boldClientRegularList .bclItem:nth-child(3n + 1) {
    clear: left;
  }

  .col-ms-12 .boldClientRegularList .bclItem {
    width: 33.3333333%;
  }

  .col-ms-12 .boldClientRegularList .bclItem:nth-child(6n) {
    border-right: 1px solid rgba(0, 0, 0, .1);
  }

  .col-ms-12 .boldClientRegularList .bclItem:nth-child(3n) {
    border-right: 1px solid transparent;
  }
}

/* Tabs
---------------------------------------------------------------*/

.btTabs {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .25);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .25);
  border-radius: 2px;
}

.tabsHeader {
  background: rgba(0, 0, 0, .05);
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
  text-align: left;
}

.tabsHeader li {
  margin: 0;
  border-bottom: 0;
  padding: 10px 15px;
  font-size: 15px;
  font-weight: 600;
  clear: none;
  margin-right: 1px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  color: #7e7e7e;
  margin-bottom: -1px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.tabsHeader li:before {
  display: none;
}

.tabsHeader li.on {
  background-color: transparent;
  color: var(--secondry-color);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.btLightSkin .tabsHeader li,
.btDarkSkin .btLightSkin .tabsHeader li {
  color: #7E7E7E;
}

.btLightSkin .tabsHeader li.on,
.btDarkSkin .btLightSkin .tabsHeader li.on {
  color: var(--secondry-color);
}

.btDarkSkin .tabsHeader li,
.btLightSkin .btDarkSkin .tabsHeader li {
  color: #7E7E7E;
}

.btDarkSkin .tabsHeader li.on,
.btLightSkin .btDarkSkin .tabsHeader li.on {
  color: var(--secondry-color);
}

@media (max-width: 768px) {
  .tabsHorizontal .tabsHeader li {
    margin-bottom: 0;
    width: 100%;
  }
}

.tabPanes {
  border-bottom-width: 2px;
  clear: both;
}

.tabPane {
  padding: 20px;
}

.tabsHorizontal .tabPane {
  opacity: 0;
  display: none;
  -webkit-transition: opacity 250ms ease 250ms;
  -moz-transition: opacity 250ms ease 250ms;
  transition: opacity 250ms ease 250ms;
}

.tabsHorizontal .tabPane.on {
  opacity: 1;
  display: block;
}

.btLightSkin .tabsHeader li,
.btDarkSkin .btLightSkin .tabsHeader li {
  border-bottom: 1px solid transparent;
}

.btLightSkin .tabsHeader li:not(on):hover,
.btDarkSkin .btLightSkin .tabsHeader li:not(on):hover {
  color: var(--secondry-color);
  border-bottom-color: var(--secondry-color);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.btLightSkin .tabPanes .tabPane,
.btDarkSkin .btLightSkin .tabPanes .tabPane {
  border-top: 1px solid rgba(0, 0, 0, .1);
}

.btDarkSkin .tabsHeader li,
.btLightSkin .btDarkSkin .tabsHeader li {
  border-bottom: 1px solid transparent;
}

.btDarkSkin .tabsHeader li:not(on):hover,
.btLightSkin .btDarkSkin .tabsHeader li:not(on):hover {
  color: var(--secondry-color);
  border-bottom-color: var(--secondry-color);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.btDarkSkin .tabPanes .tabPane,
.btLightSkin .btDarkSkin .tabPanes .tabPane {
  border-top: 1px solid rgba(255, 255, 255, .1);
}

/* Accordions
---------------------------------------------------------------*/

.tabsVertical .tabPanes {
  border-bottom-width: 2px;
}

.tabsVertical {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.tabsVertical .tabAccordionTitle {
  display: block;
  padding: 0 10px 0 40px;
  margin: 0;
  cursor: pointer;
  position: relative;
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  color: #7E7E7E;
  background: rgba(0, 0, 0, .05);
  border-bottom: 1px solid #fff;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.tabsVertical .tabAccordionTitle span {
  display: inline-block;
  padding: 10px;
  position: relative;
}

.tabsVertical .tabAccordionTitle.on {
  border-bottom-color: rgba(0, 0, 0, .1);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.tabsVertical .tabAccordionTitle:hover {
  color: var(--secondry-color);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.tabsVertical .tabAccordionContent {
  padding: 20px;
  display: none;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .25);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .25);
  margin: 0 0 20px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.tabsVertical .tabPane {
  padding: 0;
}

.tabsVertical .tabPane:first-child .tabAccordionTitle {
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}

.tabsVertical .tabPane:last-child .tabAccordionTitle {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-bottom: 0;
}

.tabsVertical .tabPane:last-child .tabAccordionTitle.on {
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.tabsVertical .tabsHeader {
  display: none;
}

.btLightSkin .tabAccordionTitle,
.btDarkSkin .btLightSkin .tabAccordionTitle {
  border-bottom-color: #fff;
  color: #7E7E7E;
}

.btLightSkin .tabAccordionTitle.on,
.btDarkSkin .btLightSkin .tabAccordionTitle.on {
  border-bottom-color: rgba(0, 0, 0, .1);
  color: var(--secondry-color);
}

.btDarkSkin .tabAccordionTitle,
.btLightSkin .btDarkSkin .tabAccordionTitle {
  border-bottom-color: #313131;
  color: #7E7E7E;
}

.btDarkSkin .tabAccordionTitle.on,
.btLightSkin .btDarkSkin .tabAccordionTitle.on {
  border-bottom-color: rgba(255, 255, 255, .1);
  color: var(--secondry-color);
}

.btLightSkin .tabsVertical .tabPanes .tabPane,
.btDarkSkin .tabsVertical .tabPanes .tabPane {
  border-top: 0;
}

.tabsVertical .tabPane:last-child .tabAccordionTitle {
  border-bottom: 0;
}

.tabsVertical .tabAccordionTitle:before {
  content: "\f067";
  font: 14px/52px FontAwesome;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  width: 56px;
  text-align: center;
}

.tabsVertical .tabAccordionTitle.on:before {
  content: "\f068";
}

.tabsVertical .tabPane:last-child .tabAccordionContent {
  border-bottom: 0;
}

/* Latest posts
---------------------------------------------------------------*/

.btLatestPostsContainer.horizontalPosts {
  margin: 0 -10px;
}

.btLatestPostsContainer.horizontalPosts .btSingleLatestPostContent {
  padding: 0 20px;
  color: var(--secondry-color) !important;
}

.btLightSkin .btLatestPostsContainer .btSingleLatestPostContent .header.small h4 a,
.btDarkSkin .btLightSkin .btLatestPostsContainer .btSingleLatestPostContent .header.small h4 a {
  color: var(--secondry-color);
}

.btLightSkin .btLatestPostsContainer .btSingleLatestPostContent .header.small h4 a:hover,
.btDarkSkin .btLightSkin .btLatestPostsContainer .btSingleLatestPostContent .header.small h4 a:hover {
  color: var(--secondry-color);
}

.btDarkSkin .btLatestPostsContainer .btSingleLatestPostContent .header.small h4 a,
.btLightSkin .btDarkSkin .btLatestPostsContainer .btSingleLatestPostContent .header.small h4 a {
  color: #fff;
}

.btDarkSkin .btLatestPostsContainer .btSingleLatestPostContent .header.small h4 a:hover,
.btLightSkin .btDarkSkin .btLatestPostsContainer .btSingleLatestPostContent .header.small h4 a:hover {
  color: var(--secondry-color);
}

.btLatestPostsContainer.horizontalPosts .btSingleLatestPostImage {
  padding: 0;
}

.btLatestPostsContainer.verticalPosts {
  margin: 0 -10px;
}

.verticalPosts .btTextIndent .btImage,
.verticalPosts .btTextIndent .bpgPhoto {
  margin: 0;
}

.btSingleLatestPostImage {
  margin-bottom: 10px;
}

.verticalPosts .btSingleLatestPostImage {
  float: left;
  width: 240px;
  margin: 0 0 20px;
  padding-right: 20px;
}

@media (max-width: 768px) {
  .verticalPosts .btSingleLatestPostImage {
    width: 100%;
    padding-right: 0;
    float: none;
  }
}

.verticalPosts .btSingleLatestPostContent {
  display: table;
}

/* Gmap
---------------------------------------------------------------*/

.btGoogleMap {
  width: 100%;
}

/* Custom menu
---------------------------------------------------------------*/

.btCustomMenu {
  margin-bottom: 20px;
  margin-right: 20px;
  font-family: Lato, Arial, sans-serif;
}

.btCustomMenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 2px;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, .1);
}

.btCustomMenu ul li {
  padding: 0;
  margin: 0;
  text-transform: uppercase;
}

.btCustomMenu ul li a {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding: 8px 0;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  transition: all 200ms;
  font-weight: 700;
  -webkit-box-shadow: 0 0 0 var(--secondry-color) inset;
  box-shadow: 0 0 0 var(--secondry-color) inset;
}

.btCustomMenu ul li a:hover {
  -webkit-transition: all 300ms ease !important;
  -moz-transition: all 300ms ease !important;
  transition: all 300ms ease !important;
  -webkit-box-shadow: -5px 0 0 var(--primary-color) inset;
  box-shadow: -5px 0 0 var(--primary-color) inset;
}

.btCustomMenu ul li .customSubToggler {
  float: right;
  margin-top: 6px;
}

.btCustomMenu ul li .customSubToggler a.btIcoHolder {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
  float: right;
}

.btCustomMenu ul li .customSubToggler a.btIcoHolder:before {
  font-size: 20px;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  transform: none;
  line-height: 28px;
  color: #686d7a;
}

.btCustomMenu ul li .customSubToggler a.btIcoHolder:hover {
  background: transparent;
}

.btCustomMenu ul li .customSubToggler.on a {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.btCustomMenu ul li.current-menu-item>ul {
  display: block;
}

.btCustomMenu ul li.current-menu-item>a {
  color: var(--secondry-color);
}

.btCustomMenu ul ul {
  border-bottom: 1px solid;
  border-radius: 0;
}

.btCustomMenu ul ul li ul li {
  text-transform: none;
}

.btCustomMenu ul ul li ul li a {
  padding-left: 30px;
  border: 0;
  font-weight: 400;
}

.btCustomMenu ul ul.sub-menu,
.btCustomMenu ul li.current-menu-item>ul.sub-menu.on {
  display: none;
}

.btCustomMenu ul ul.sub-menu.on,
.btCustomMenu ul li.current-menu-item>ul.sub-menu {
  display: block;
}


.btSiteFooterWidgets .btBox.widget_bt_text_image img {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.btSiteFooterWidgets .recentTweets small:before {
  color: #2995f1;
}

.btSiteFooterCurve {
  display: none;
  height: 14px;
  width: 100%;
  line-height: 11px;
  margin-top: -14px;
  position: absolute;
  clear: both;
}

.btSiteFooterCurve .port {
  padding: 0 50px;
}

.btSiteFooterCurve .btSiteFooterCurveSleeve {
  background: var(--secondry-color);
  height: 14px;
  width: 100%;
}

.header-curve .header-Curve-Sleeve {
  background-color: #423939;
}


.btSiteFooterCurve .btCurveLeftHolder {
  float: left;
  margin-left: -50px;
}

.btSiteFooterCurve .btCurveRightHolder {
  float: right;
  margin-right: -50px;
}

.btSiteFooterCurve .btCurveLeft,
.btSiteFooterCurve .btCurveRight {
  fill: var(--secondry-color);
}

.btSiteFooterWidgets .btBox.widget_nav_menu.col-md-3 ul li {
  width: 50%;
  padding-right: 10px;
  float: left;
}

.btSiteFooterWidgets .btBox.widget_nav_menu.col-md-3 ul li:nth-child(2n + 1) {
  width: 50%;
  clear: left;
}

.btSiteFooterWidgets .btBox.widget_nav_menu.col-md-3 ul:after {
  clear: both;
  content: "";
  display: block;
}

.btLightSkin .btSiteFooterWidgets,
.btDarkSkin .btLightSkin .btSiteFooterWidgets {
  background: #084274;
  color: #fff;
}

.btLightSkin .btSiteFooter,
.btDarkSkin .btLightSkin .btSiteFooter {
  border-top: 1px solid rgba(128, 128, 128, .1);
  background: #084274;
  color: #fff;
}

.btDarkSkin .btSiteFooterWidgets,
.btLightSkin .btDarkSkin .btSiteFooterWidgets {
  background: #084274;
  color: #fff;
}

.btDarkSkin .btSiteFooter,
.btLightSkin .btDarkSkin .btSiteFooter {
  border-top: 1px solid rgba(128, 128, 128, .1);
  background: #084274;
  color: #fff;
}

.btSiteFooterWidgets.btDarkFooterSkin,
.btSiteFooterCurve.btDarkFooterSkin .btSiteFooterCurveSleeve,
.btSiteFooter.btDarkFooterSkin {
  background: #313131 !important;
}

.btSiteFooterCurve.btDarkFooterSkin .btCurveLeft,
.btSiteFooterCurve.btDarkFooterSkin .btCurveRight {
  fill: #313131;
}


/* Main header curve
---------------------------------------------------------------*/

.mainHeader .btCurveHeader,
.mainHeader .btCurveHeader * {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 9;
  position: relative;
  fill: white;

}

.mainHeader .btCurveHeader {
  display: block;
  position: absolute;
  width: 100%;
}

.mainHeader .btCurveHeader .btCurveHolder {
  height: 20px;
  line-height: 15px;
  padding: 0 80px;
  margin: -5px auto 0;
  max-width: 1280px;
}

.mainHeader .btCurveHeader .btCurveHolder .btSiteHeaderCurveSleeve {
  height: 20px;
  width: 100%;
  background: #fff;
}

.mainHeader .btCurveHeader .btCurveHolder .btCurveLeft,
.mainHeader .btCurveHeader .btCurveHolder .btCurveRight {
  background: #fff;
}

.mainHeader .btCurveHeader .btCurveLeftHolder {
  float: left;
  margin-left: -80px;

}

.mainHeader .btCurveHeader .btCurveRightHolder {
  float: right;
  margin-right: -80px;
}

.btBelowMenu .mainHeader .btCurveHeader {
  opacity: 0;
}

.btBelowMenu.btStickyHeaderActive .mainHeader .btCurveHeader {
  opacity: 1;
}

.btWithSidebar .btCurveHeader {
  display: none;
}

.btLightSkin .mainHeader .btCurveHolder .btSiteHeaderCurveSleeve,
.btDarkSkin .btLightSkin .mainHeader .btCurveHolder .btSiteHeaderCurveSleeve {
  background: #fff;
}

.btLightSkin .mainHeader .btCurveHolder .btCurveLeftFill,
.btDarkSkin .btLightSkin .mainHeader .btCurveHolder .btCurveLeftFill,
.btLightSkin .mainHeader .btCurveHolder .btCurveRightFill,
.btDarkSkin .btLightSkin .mainHeader .btCurveHolder .btCurveRightFill,
.btLightSkin .mainHeader .btCurveHolder .btCurveLeft,
.btDarkSkin .btLightSkin .mainHeader .btCurveHolder .btCurveLeft,
.btLightSkin .mainHeader .btCurveHolder .btCurveRight,
.btDarkSkin .btLightSkin .mainHeader .btCurveHolder .btCurveRight {
  fill: #fff;
}

.btDarkSkin .mainHeader .btCurveHolder .btSiteHeaderCurveSleeve,
.btLightSkin .btDarkSkin .mainHeader .btCurveHolder .btSiteHeaderCurveSleeve {
  background: #313131;
}

.btDarkSkin .mainHeader .btCurveHolder .btCurveLeftFill,
.btLightSkin .btDarkSkin .mainHeader .btCurveHolder .btCurveLeftFill,
.btDarkSkin .mainHeader .btCurveHolder .btCurveRightFill,
.btLightSkin .btDarkSkin .mainHeader .btCurveHolder .btCurveRightFill,
.btDarkSkin .mainHeader .btCurveHolder .btCurveLeft,
.btLightSkin .btDarkSkin .mainHeader .btCurveHolder .btCurveLeft,
.btDarkSkin .mainHeader .btCurveHolder .btCurveRight,
.btLightSkin .btDarkSkin .mainHeader .btCurveHolder .btCurveRight {
  fill: white;
}

.mainHeader.btDarkSkin,
.btLightSkin .mainHeader.btDarkSkin {
  background-color: hite;
}

.mainHeader.btLightSkin,
.btDarkSkin .mainHeader.btLightSkin {
  background-color: #fff;
}

.btMenuGutter .mainHeader .port,
.btMenuGutter .mainHeader .topBarPort {
  max-width: 1160px;
  margin: auto;
  padding-left: 0;
  padding-right: 0;
}

.mainHeader .btCurveHeader {
  display: block;
  position: absolute;
  width: 100%;
}

.btLightSkin .mainHeader .btCurveHolder .btCurveLeftFill,
.btDarkSkin .btLightSkin .mainHeader .btCurveHolder .btCurveLeftFill,
.btLightSkin .mainHeader .btCurveHolder .btCurveRightFill,
.btDarkSkin .btLightSkin .mainHeader .btCurveHolder .btCurveRightFill,
.btLightSkin .mainHeader .btCurveHolder .btCurveLeft,
.btDarkSkin .btLightSkin .mainHeader .btCurveHolder .btCurveLeft,
.btLightSkin .mainHeader .btCurveHolder .btCurveRight,
.btDarkSkin .btLightSkin .mainHeader .btCurveHolder .btCurveRight {
  fill: #fff;
}




.btCustomMenu ul li.current-menu-item .customSubToggler a.btIcoHolder {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.btCustomMenu ul li.current-menu-item .customSubToggler.on a.btIcoHolder {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.btHighlight .btCustomMenu {
  margin-left: 10px;
  margin-right: 10px;
}

.btLightSkin .btCustomMenu ul li,
.btDarkSkin .btLightSkin .btCustomMenu ul li {
  border-color: rgba(0, 0, 0, .1);
}

.btDarkSkin .btCustomMenu ul li,
.btLightSkin .btDarkSkin .btCustomMenu ul li {
  border-color: rgba(255, 255, 255, .1);
}

/* Contact 7 buttons
---------------------------------------------------------------*/

.btLightSkin .wpcf7-submit,
.btDarkSkin .btLightSkin .wpcf7-submit,
.btDarkSkin .wpcf7-submit,
.btLightSkin .btDarkSkin .wpcf7-submit {
  background: var(--secondry-color);
  color: #fff;
  line-height: 40px;
  display: inline-block;
  padding: 3px 20px;
  width: auto;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  -webkit-appearance: none;
}

.btLightSkin .wpcf7-submit:hover,
.btDarkSkin .btLightSkin .wpcf7-submit:hover,
.btDarkSkin .wpcf7-submit:hover,
.btLightSkin .btDarkSkin .wpcf7-submit:hover {
  background-color: transparent;
  color: var(--secondry-color);
}

/* Google maps info
---------------------------------------------------------------*/

.btGoogleMapsWrapper .btGoogleMapsContent {
  position: absolute;
  top: 40px;
  bottom: 40px;
  left: auto;
  right: 50%;
  width: 373px;
  padding: 30px;
  margin-right: -579px;
  border-radius: 2px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
  opacity: .85;
}

.btLightSkin .btGoogleMapsWrapper .btGoogleMapsContent,
.btDarkSkin .btLightSkin .btGoogleMapsWrapper .btGoogleMapsContent {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .25);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .25);
}

.btDarkSkin .btGoogleMapsWrapper .btGoogleMapsContent,
.btLightSkin .btDarkSkin .btGoogleMapsWrapper .btGoogleMapsContent {
  background-color: #313131;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .25);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .25);
}

/* Newsletter */

.btNewsletter {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.btNewsletter .btNewsletterColumn {
  -webkit-flex: 1 0 46%;
  -ms-flex: 1 0 46%;
  flex: 1 0 46%;
  max-width: 46%;
  padding-right: 10px;
}

.btNewsletter .btNewsletterColumn input {
  height: 48px;
}

@media (max-width: 991px) {
  .btNewsletter {
    display: block;
  }

  .btNewsletter .btNewsletterColumn {
    max-width: 100%;
    padding-right: 0;
    padding-bottom: 10px;
  }
}

/*

   9. Screens

Source: css/screens.css 
Description: handles media query visibility

*/

/* Bootstrap large
---------------------------------------------------------------*/

@media (max-width: 1200px) {

  .search.btNoSidebar .btContentHolder,
  .blog.btNoSidebar .btContentHolder,
  .archive.btNoSidebar .btContentHolder,
  .btWithSidebar .btContentHolder,
  .btMenuGutter .mainHeader .port,
  .btMenuGutter .topBar .topBarPort,
  .btNoSidebar .gutter .port {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .btSiteFooterWidgets .btBox.widget_bt_text_image img {
    max-width: 100%;
  }
}

@media (min-width: 1260px) {
  .slidedItem .btSlidePane {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1160px) {
  .btSiteFooterCurve {
    display: block;
  }
}

@media (max-width: 990px) {
  .header.small {
    margin-bottom: 10px;
  }

  .header.small h4 {
    font-size: 22px;
    line-height: 32px;
  }

  .menuHolder {
    margin-top: 0;
  }

  .btGoogleMapsWrapper .btGoogleMapsContent {
    position: relative;
    clear: both;
    width: auto;
    margin: 20px;
    right: 0;
    left: 0;
    top: 0;
  }

  .btSiteFooterWidgets .btBox.widget_bt_text_image img {
    max-width: 40%;
  }
}

/* Bootstrap medium
---------------------------------------------------------------*/

@media (max-width: 768px) {

  .btContent,
  .btSidebar {
    width: 100%;
    float: none;
  }

  .btSidebarLeft .btSidebar,
  .btSidebarRight .btSidebar {
    padding-left: 0;
    padding-right: 0;
  }

  h1,
  .header.huge h1 {
    font-size: 50px;
    line-height: 70px;
  }


  h2,
  .header.large h2 {
    font-size: 30px;
    line-height: 45px;
  }

  .header.extralarge .btSuperTitle,
  .header.extralarge .btSubTitle {
    font-size: 18px;
  }

  .nbs {
    display: none !important;
  }

  .btBtn {
    padding: 5px 10px;
  }

  .btTextLeft .btBtn {
    margin-right: 10px;
  }

  .btTextRight .btBtn {
    margin-left: 10px;
  }

  .btBtn.btBtnNoBorder {
    padding: 0;
  }

  .btArticleMeta dd,
  .btArticleMeta dt {
    padding-right: 5px;
  }

  .btArticleMeta dd:after {
    content: "; ";
  }

  .btArticleMeta dt:after {
    content: ": ";
  }

  .btArticleMeta.onTop dd {
    margin-bottom: 0;
  }

  .slidedItem .btSlidePane {
    padding-left: 0;
    padding-right: 0;
  }

  .btSiteFooterWidgets .btBox.widget_bt_text_image img {
    max-width: 55%;
  }
}

/* Bootstrap small-caps---------------------------------------------------------------*/

@media (max-width: 480px) {
  .comment-respond form {
    width: 100%;
  }

  .slided .slick-dots li {
    width: 50px;
  }

  .btCatFilter {
    line-height: 2.5;
  }

  .btCatFilter .btCatFilterItem,
  .btCatFilter .btCatFilterTitle {
    display: block;
  }

  .btCatFilter .btCatFilterItem:after {
    display: none;
  }

  .btSiteFooterWidgets .btBox.widget_bt_text_image img {
    max-width: 80%;
  }

  .header.large h2 {
    font-size: 30px;
  }
}

/* Bootstrap small-caps---------------------------------------------------------------*/

@media (max-width: 440px) {
  .btQuoteBooking .btQuoteBookingForm.btActive .btTotalNextWrapper {
    padding-right: 0 !important;
  }

  .btQuoteBooking .btQuoteBookingForm.btActive .btQuoteTotal,
  .btQuoteBooking .btQuoteBookingForm .btQuoteTotal {
    height: auto;
  }

  .btQuoteBooking .btQuoteBookingForm .boldBtn {
    width: 100% !important;
  }

  .btQuoteBooking .btQuoteBookingForm.btActive .btContactNext,
  .btQuoteBooking .btQuoteBookingForm .btContactNext {
    width: auto !important;
  }
}

/*

   10. Cost calculator

Source: css/costcalc.css 
Description: styles cost calculator dependancies

*/

.btQuoteBooking .ui-slider-horizontal .ui-slider-handle {
  margin-left: -1px;
}

.btQuoteBooking .ddTitleText span,
.btQuoteBooking .ddChild ul li span {
  font-weight: 700;
}

.btQuoteBooking .ddChild ul li span.description {
  font-weight: 500;
}

.btQuoteBooking .ddTitleText .ddlabel {
  font-weight: 700;
}

.btQuoteBooking .ddTitleText .ddlabel .description {
  font-weight: 500;
}

.btQuoteBooking .ddTitleText.btNotSelected .ddlabel,
.btQuoteBooking .ddChild ul li:first-child span {
  color: #A9A9A9;
  font-weight: 500;
}

.btQuoteBooking .ddTitle .arrow {
  line-height: 38px;
  height: 38px;
  width: 38px;
  text-align: center;
  top: 0;
  right: 0;
  margin: 0;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.btQuoteBooking .borderRadiusTp .ddTitle .arrow,
.btQuoteBooking .borderRadiusBtm .ddTitle .arrow {
  line-height: 38px;
  height: 38px;
  width: 38px;
  text-align: center;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.btQuoteBooking .ddTitle .arrow:before {
  border: 0;
  content: '\f078';
  font: normal 14px/38px FontAwesome;
  display: block;
  position: relative;
  width: 38px;
  height: 38px;
  right: 0;
  top: 0;
}

.btDarkSkin .btQuoteBooking .borderRadiusTp .ddTitle .arrow:before,
.btLightSkin .btDarkSkin .btQuoteBooking .borderRadiusTp .ddTitle .arrow:before,
.btLightSkin .btQuoteBooking .borderRadiusTp .ddTitle .arrow:before,
.btDarkSkin .btLightSkin .btQuoteBooking .borderRadiusTp .ddTitle .arrow:before,
.btDarkSkin .btQuoteBooking .borderRadiusBtm .ddTitle .arrow:before,
.btLightSkin .btDarkSkin .btQuoteBooking .borderRadiusBtm .ddTitle .arrow:before,
.btLightSkin .btQuoteBooking .borderRadiusBtm .ddTitle .arrow:before,
.btDarkSkin .btLightSkin .btQuoteBooking .borderRadiusBtm .ddTitle .arrow:before {
  color: #CCC !important;
}

.btLightSkin .btQuoteBooking .ddTitle .arrow:before,
.btDarkSkin .btLightSkin .btQuoteBooking .ddTitle .arrow:before {
  color: #CCC;
}

.btDarkSkin .btQuoteBooking .ddTitle .arrow:before,
.btLightSkin .btDarkSkin .btQuoteBooking .ddTitle .arrow:before {
  color: #FFF;
}

/* Background color
---------------------------------------------------------------*/

.btQuoteBooking .btQuoteSwitch.on .btQuoteSwitchInner,
.btQuoteBooking .ui-slider .ui-slider-handle,
.btQuoteBooking .btQuoteBookingForm .btQuoteTotal,
.btDatePicker .ui-datepicker-header,
.btQuoteBooking .btContactSubmit {
  background: var(--secondry-color);
}

/* Color
---------------------------------------------------------------*/

.btQuoteBooking .btContactNext,
.btQuoteBooking .btSubmitMessage {
  color: #FFF !important;
}

.btQuoteBooking .btSubmitMessage {
  line-height: 50px;
}

.btDarkSkin .btQuoteBooking .btContactSubmit,
.btLightSkin .btDarkSkin .btQuoteBooking .btContactSubmit,
.btLightSkin .btQuoteBooking .btContactSubmit,
.btDarkSkin .btLightSkin .btQuoteBooking .btContactSubmit {
  font-size: 13px;
  line-height: 30px;
  padding: 4px 20px;
  border-radius: 2px;
  font-weight: 600;
}

/* Borders
---------------------------------------------------------------*/

.btQuoteBooking .btContactNext {
  border-color: var(--secondry-color);
}

.btDarkSkin .btQuoteBooking .btContactNext,
.btLightSkin .btDarkSkin .btQuoteBooking .btContactNext,
.btLightSkin .btQuoteBooking .btContactNext,
.btDarkSkin .btLightSkin .btQuoteBooking .btContactNext {
  font-size: 13px;
  line-height: 30px;
  padding: 4px 20px;
  border-radius: 2px;
  font-weight: 600;
  color: #FFF !important;
}

/* Fields box shadows
---------------------------------------------------------------*/

.btQuoteBooking .btQuoteSwitch:hover {
  -webkit-box-shadow: 0 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
  box-shadow: 0 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
}

.btQuoteBooking input[type="text"]:hover,
.btQuoteBooking input[type="email"]:hover,
.btQuoteBooking input[type="password"]:hover,
.btQuoteBooking textarea:hover,
.btQuoteBooking .fancy-select .trigger:hover {
  -webkit-box-shadow: 0 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
  box-shadow: 0 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
}

.btQuoteBooking .dd.ddcommon.borderRadius:hover .ddTitleText {
  -webkit-box-shadow: 0 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
  box-shadow: 0 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
}

.btQuoteBooking input[type="text"]:focus,
.btQuoteBooking input[type="email"]:focus,
.btQuoteBooking textarea:focus,
.btQuoteBooking .fancy-select .trigger.open {
  -webkit-box-shadow: 5px 0 0 var(--secondry-color) inset, 0 2px 10px rgba(0, 0, 0, .2);
  box-shadow: 5px 0 0 var(--secondry-color) inset, 0 2px 10px rgba(0, 0, 0, .2);
}

.btQuoteBooking .dd.ddcommon.borderRadiusTp .ddTitleText,
.btQuoteBooking .dd.ddcommon.borderRadiusBtm .ddTitleText {
  -webkit-box-shadow: 5px 0 0 var(--secondry-color) inset, 0 2px 10px rgba(0, 0, 0, .2);
  box-shadow: 5px 0 0 var(--secondry-color) inset, 0 2px 10px rgba(0, 0, 0, .2);
}

/* Hover states
---------------------------------------------------------------*/

.btQuoteBooking .btContactFieldMandatory input:hover,
.btQuoteBooking .btContactFieldMandatory textarea:hover {
  -webkit-box-shadow: 0 0 0 1px #AAA inset, 0 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
  box-shadow: 0 0 0 1px #AAA inset, 0 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
}

.btQuoteBooking .btContactFieldMandatory .dd.ddcommon.borderRadius:hover .ddTitleText {
  -webkit-box-shadow: 0 0 0 1px #AAA inset, 0 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
  box-shadow: 0 0 0 1px #AAA inset, 0 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
}

.btQuoteBooking .btContactFieldMandatory input:focus,
.btQuoteBooking .btContactFieldMandatory textarea:focus {
  -webkit-box-shadow: 0 0 0 1px #AAA inset, 5px 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
  box-shadow: 0 0 0 1px #AAA inset, 5px 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
}

.btQuoteBooking .btContactFieldMandatory .dd.ddcommon.borderRadiusTp .ddTitleText {
  -webkit-box-shadow: 0 0 0 1px #AAA inset, 5px 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
  box-shadow: 0 0 0 1px #AAA inset, 5px 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
}

/* Error fields validation
---------------------------------------------------------------*/

.btQuoteBooking .btContactFieldMandatory.btContactFieldError input,
.btQuoteBooking .btContactFieldMandatory.btContactFieldError textarea {
  border: 1px solid var(--secondry-color);
  -webkit-box-shadow: 0 0 0 1px var(--secondry-color) inset;
  box-shadow: 0 0 0 1px var(--secondry-color) inset;
}

.btQuoteBooking .btContactFieldMandatory.btContactFieldError .dd.ddcommon.borderRadius .ddTitleText {
  border: 1px solid var(--secondry-color);
  -webkit-box-shadow: 0 0 0 1px var(--secondry-color) inset;
  box-shadow: 0 0 0 1px var(--secondry-color) inset;
}

.btQuoteBooking .btContactFieldMandatory.btContactFieldError input:hover,
.btQuoteBooking .btContactFieldMandatory.btContactFieldError textarea:hover {
  -webkit-box-shadow: 0 0 0 1px var(--secondry-color) inset, 0 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
  box-shadow: 0 0 0 1px var(--secondry-color) inset, 0 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
}

.btQuoteBooking .btContactFieldMandatory.btContactFieldError .dd.ddcommon.borderRadius:hover .ddTitleText {
  -webkit-box-shadow: 0 0 0 1px var(--secondry-color) inset, 0 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
  box-shadow: 0 0 0 1px var(--secondry-color) inset, 0 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
}

.btQuoteBooking .btContactFieldMandatory.btContactFieldError input:focus,
.btQuoteBooking .btContactFieldMandatory.btContactFieldError textarea:focus {
  -webkit-box-shadow: 0 0 0 1px var(--secondry-color) inset, 5px 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
  box-shadow: 0 0 0 1px var(--secondry-color) inset, 5px 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
}

.btQuoteBooking .btContactFieldMandatory.btContactFieldError .dd.ddcommon.borderRadiusTp .ddTitleText {
  -webkit-box-shadow: 0 0 0 1px var(--secondry-color) inset, 5px 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
  box-shadow: 0 0 0 1px var(--secondry-color) inset, 5px 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
}

.btPayPalButton:hover {
  -webkit-box-shadow: 0 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
  box-shadow: 0 0 0 var(--secondry-color) inset, 0 1px 5px rgba(0, 0, 0, .2);
}

.btDarkSkin .btQuoteBooking .dd.ddcommon.borderRadius .ddTitleText.btNotSelected,
.btLightSkin .btDarkSkin .btQuoteBooking .dd.ddcommon.borderRadius .ddTitleText.btNotSelected {
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .5);
}

.btDarkSkin .btQuoteBooking select,
.btLightSkin .btDarkSkin .btQuoteBooking select,
.btDarkSkin .btQuoteBooking textarea,
.btLightSkin .btDarkSkin .btQuoteBooking textarea,
.btDarkSkin .btQuoteBooking input,
.btLightSkin .btDarkSkin .btQuoteBooking input {
  color: #333;
}

.btDarkSkin .btQuoteBooking .btQuoteWrapper,
.btLightSkin .btDarkSkin .btQuoteBooking .btQuoteWrapper {
  background: rgba(255, 255, 255, .1);
}

.btDarkSkin .btQuoteBooking .btQuoteWrapper,
.btLightSkin .btDarkSkin .btQuoteBooking .btQuoteWrapper {
  border-color: rgba(255, 255, 255, .5);
}

.btDarkSkin .btQuoteBooking .btContactFieldMandatory input,
.btLightSkin .btDarkSkin .btQuoteBooking .btContactFieldMandatory input,
.btDarkSkin .btQuoteBooking .btContactFieldMandatory textarea,
.btLightSkin .btDarkSkin .btQuoteBooking .btContactFieldMandatory textarea {
  border-color: #AAA;
}

.btDarkSkin .btQuoteBooking .btContactFieldMandatory.btContactFieldError input,
.btLightSkin .btDarkSkin .btQuoteBooking .btContactFieldMandatory.btContactFieldError input,
.btDarkSkin .btQuoteBooking .btContactFieldMandatory.btContactFieldError textarea,
.btLightSkin .btDarkSkin .btQuoteBooking .btContactFieldMandatory.btContactFieldError textarea {
  border-color: var(--secondry-color);
}

.btDarkSkin .btQuoteBooking .btQuoteSwitch,
.btLightSkin .btDarkSkin .btQuoteBooking .btQuoteSwitch {
  background: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .5) !important;
  -webkit-transition: all 190ms cubic-bezier(.8, .1, .8, .1);
  -moz-transition: all 190ms cubic-bezier(.8, .1, .8, .1);
  transition: all 190ms cubic-bezier(.8, .1, .8, .1);
}

.btDarkSkin .btQuoteBooking .btQuoteSwitch.on,
.btLightSkin .btDarkSkin .btQuoteBooking .btQuoteSwitch.on {
  -webkit-transition: all 190ms cubic-bezier(.8, .1, .8, .1);
  -moz-transition: all 190ms cubic-bezier(.8, .1, .8, .1);
  transition: all 190ms cubic-bezier(.8, .1, .8, .1);
}

.btDarkSkin .btQuoteBooking .btQuoteSwitch .btQuoteSwitchInner,
.btLightSkin .btDarkSkin .btQuoteBooking .btQuoteSwitch .btQuoteSwitchInner {
  background: rgba(255, 255, 255, .5);
}

.btDarkSkin .btQuoteBooking .btQuoteSwitch.on .btQuoteSwitchInner,
.btLightSkin .btDarkSkin .btQuoteBooking .btQuoteSwitch.on .btQuoteSwitchInner {
  background: #fff !important;
}

.btDarkSkin .btQuoteBooking .btSubmitMessage,
.btLightSkin .btDarkSkin .btQuoteBooking .btSubmitMessage {
  color: #fff;
}

/* Cargo specific theme fixes */

.btQuoteBooking .btQuoteBookingForm .btQuoteTotal,
.btQuoteBooking .btQuoteBookingForm.btActive .btQuoteTotal,
.btQuoteBooking .btContactNext {
  border-radius: 2px;
}

.btQuoteBooking .btQuoteBookingForm.btActive .btTotalNextWrapper {
  padding-right: 110px;
}

.btQuoteBooking .btQuoteBookingForm .boldBtn {
  width: 100px;
}

.btQuoteBooking .btQuoteTotalText {
  font-weight: 600;
  text-transform: uppercase;
}

.btQuoteBooking .btQuoteTotalCalc {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  font-size: 13px;
  line-height: 30px;
}

.btQuoteBooking .btQuoteTotalCurrency {
  border-radius: 0;
  font-size: 13px;
  line-height: 30px;
}

.btQuoteBooking .btContactNext {
  background: var(--secondry-color);
  color: #fff;
  border: 1px solid var(--secondry-color);
  -webkit-box-shadow: 0 0 0 1px var(--secondry-color) inset, 0 1px 0 transparent inset;
  box-shadow: 0 0 0 1px var(--secondry-color) inset, 0 1px 0 transparent inset;
  text-shadow: none;
  margin-left: 10px;
}

.btQuoteBooking .btContactNext:hover {
  background: #08487e;
  color: #fff;
  border: 1px solid #08487e;
  -webkit-box-shadow: 0 0 0 1px transparent inset, 0 1px 0 #08487e inset;
  box-shadow: 0 0 0 1px transparent inset, 0 1px 0 #08487e inset;
  text-shadow: none;
  opacity: 1;
}

.btQuoteBooking .btContactSubmit {
  background: var(--secondry-color);
  color: #fff;
  border: 1px solid var(--secondry-color);
  -webkit-box-shadow: 0 0 0 1px var(--secondry-color) inset, 0 1px 0 transparent inset;
  box-shadow: 0 0 0 1px var(--secondry-color) inset, 0 1px 0 transparent inset;
  text-shadow: none;
}

.btQuoteBooking .btContactSubmit:hover {
  background: #08487e;
  color: #fff;
  border: 1px solid #08487e;
  -webkit-box-shadow: 0 0 0 1px transparent inset, 0 1px 0 #08487e inset;
  box-shadow: 0 0 0 1px transparent inset, 0 1px 0 #08487e inset;
  text-shadow: none;
  opacity: 1;
}

.btQuoteBooking .ui-slider {
  width: -webkit-calc(100% - 38px);
  width: -moz-calc(100% - 38px);
  width: calc(100% - 38px);
}

.btQuoteBooking .ui-slider-horizontal,
.btQuoteBooking .ui-slider-horizontal:hover {
  background: transparent;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
}

.btQuoteWrapper {
  height: 20px;
  margin-top: 10px;
  cursor: pointer;
  background: #ededed;
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, .05), rgba(0, 0, 0, 0));
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, .05), rgba(0, 0, 0, 0));
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, .05), rgba(0, 0, 0, 0));
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, .05), rgba(0, 0, 0, 0));
  position: relative;
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 14px;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, .2);
  box-shadow: 0 0 0 rgba(0, 0, 0, .2);
  -webkit-transition: all 190ms ease;
  -moz-transition: all 190ms ease;
  transition: all 190ms ease;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
}

.btQuoteWrapper:hover {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, .2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, .2);
  -webkit-transition: all 380ms ease;
  -moz-transition: all 380ms ease;
  transition: all 380ms ease;
}

/* Item lock */

.btQuoteItem.btCCLock {
  position: relative;
}

.btQuoteItem.btCCLock:before {
  content: ' ';
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: 0;
  right: 0;
  background: transparent;
  z-index: 999;
}

/* Transfer Quote total to Flex
-------------------------------------------*/

.btQuoteBooking .btTotalNextWrapper {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: auto !important;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-right: 0 !important;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: initial;
}

.btQuoteBooking .btTotalNextWrapper:after {
  display: none;
}

.btQuoteBooking .btQuoteBookingForm.btActive .btTotalNextWrapper {
  padding-right: 0 !important;
}

.btQuoteBooking .btQuoteBookingForm.btActive .boldBtn {
  -webkit-flex: 0 0 160px;
  -ms-flex: 0 0 160px;
  flex: 0 0 160px;
  width: 160px;
  max-width: 160px;
}

.btQuoteBooking .btQuoteBookingForm .boldBtn {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 0 1 0;
  -ms-flex: 0 1 0;
  flex: 0 1 0;
  float: none;
  margin-right: 0 !important;
  margin-left: 0 !important;
  width: 0;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
  max-width: 0;
}

.btQuoteBooking .btContactNext {
  -webkit-transform: translate(160px, 0);
  -moz-transform: translate(160px, 0);
  -ms-transform: translate(160px, 0);
  transform: translate(160px, 0);
}

.btQuoteBooking .btQuoteBookingForm .btQuoteTotal {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 0 1 100%;
  -ms-flex: 0 1 100%;
  flex: 0 1 100%;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  float: none;
}

.btQuoteBooking .btQuoteTotalText {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.btQuoteBooking .btQuoteContact:not(.btQuoteContactForm7) {
  margin: 0 -10px 0 -10px;
}

/* Transfer Quote total to Flex, responsive
-------------------------------------------*/

@media (max-width: 440px) {
  .btQuoteBooking .btTotalNextWrapper {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .btQuoteBooking .btQuoteBookingForm.btActive .boldBtn,
  .btQuoteBooking .btQuoteBookingForm .boldBtn {
    -webkit-flex: 1 0 100%;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
    width: 100%;
    max-width: 100%;
    padding-top: 0;
  }

  .btQuoteBooking .btQuoteBookingForm.btActive .btContactNext,
  .btQuoteBooking .btQuoteBookingForm .btContactNext {
    width: 100% !important;
    height: 40px;
  }

  .btQuoteBooking .btQuoteBookingForm .btContactNext {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0;
  }
}

/* Paypal button
-------------------------------------------*/

.btPayPalButton {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin: 0;
  margin-right: auto;
  height: 38px;
  -webkit-flex: 0 0 130px;
  -ms-flex: 0 0 130px;
  flex: 0 0 130px;
  margin-left: 30px;
}

/*

   11. Magnific popup 

Source: css/magnific-popup.css 
Description: handles gallery popup

*/

.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99997;
  overflow: hidden;
  position: fixed;
  background: #151515;
  opacity: .9;
  -webkit-filter: alpha(opacity=90);
  filter: alpha(opacity=90);
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99998;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: -moz-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

button.mfp-close {
  width: 44px;
  height: 44px;
  position: absolute;
  right: 15px;
  top: 5px;
  text-decoration: none;
  text-align: center;
  padding: 0;
  color: #FFF;
  font: normal 55px/1 Icon7Stroke;
  vertical-align: middle;
  opacity: .8;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  transition: all 200ms ease;
}

button.mfp-close:hover {
  opacity: 1;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  transition: all 200ms ease;
}

button.mfp-close:before {
  width: 44px;
  height: 44px;
  display: block;
  vertical-align: middle;
  line-height: 1;
  content: '\292C';
  float: left;
  margin: -10px 0 0 -5px;
  text-shadow: 0 1px 0 #000;
}

button.mfp-close i {
  display: none;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: .65;
  -webkit-filter: alpha(opacity=65);
  filter: alpha(opacity=65);
  margin: 0;
  top: 50%;
  margin-top: -35px;
  padding: 0;
  width: 90px;
  height: 110px;
  content: '\292C';
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  transition: all 200ms ease;
  text-shadow: 0 1px 0 #000;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
  -webkit-filter: alpha(opacity=100);
  filter: alpha(opacity=100);
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  transition: all 200ms ease;
}

.mfp-arrow:before,
.mfp-arrow:after,
.mfp-arrow .mfp-b,
.mfp-arrow .mfp-a {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 20px;
  border: none;
}

.mfp-arrow:after,
.mfp-arrow .mfp-a {
  top: 8px;
}

.mfp-arrow:before,
.mfp-arrow .mfp-b {
  opacity: .7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow:before {
  color: #fff;
  display: block;
  font: 50px/1 cARGO;
}

.mfp-arrow-left:before {
  /* content: '\e664'; */
  content: '\2039';
}

.mfp-arrow-right:before {
  content: '\203A';
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, .6);
  box-shadow: 0 0 8px rgba(0, 0, 0, .6);
  background: #000;
}

/* Main image in popup */

img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */

.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, .6);
  box-shadow: 0 0 8px rgba(0, 0, 0, .6);
  background: var(--secondry-color);
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape),
screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, .6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, .6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(.75);
    -moz-transform: scale(.75);
    -ms-transform: scale(.75);
    transform: scale(.75);
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    -moz-transform-origin: 0;
    -ms-transform-origin: 0;
    transform-origin: 0;
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    -moz-transform-origin: 100%;
    -ms-transform-origin: 100%;
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.mfp-ie7 .mfp-img {
  padding: 0;
}

.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px;
}

.mfp-ie7 .mfp-container {
  padding: 0;
}

.mfp-ie7 .mfp-content {
  padding-top: 44px;
}

.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0;
}

/*

12. Cargo font 

Source: css/cargo.css 
Description: styles custom cargo iconset

*/

[class^="icon-"],
[class*=" icon-"] {
  font-family: 'Cargo';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bt-cargo-icon-24-hrs:before {
  content: "\e600";
}

.bt-cargo-icon-24-full-circle:before {
  content: "\e601";
}

.bt-cargo-icon-24-dashed:before {
  content: "\e602";
}

.bt-cargo-icon-airplane-side:before {
  content: "\e603";
}

.bt-cargo-icon-airplane-top:before {
  content: "\e604";
}

.bt-cargo-icon-airplane-front:before {
  content: "\e605";
}

.bt-cargo-icon-airplane-world:before {
  content: "\e606";
}

.bt-cargo-icon-barscode-search:before {
  content: "\e607";
}

.bt-cargo-icon-umbrella-rain:before {
  content: "\e608";
}

.bt-cargo-icon-cargo-van:before {
  content: "\e609";
}

.bt-cargo-icon-ship-front:before {
  content: "\e60a";
}

.bt-cargo-icon-box-this-way-up:before {
  content: "\e60b";
}

.bt-cargo-icon-boxes-in-cargo-van:before {
  content: "\e60c";
}

.bt-cargo-icon-boxes-in-warehouse:before {
  content: "\e60d";
}

.bt-cargo-icon-call-information:before {
  content: "\e60e";
}

.bt-cargo-icon-call-support:before {
  content: "\e60f";
}

.bt-cargo-icon-chronometer:before {
  content: "\e610";
}

.bt-cargo-icon-clipboard-notes:before {
  content: "\e611";
}

.bt-cargo-icon-commercial-package:before {
  content: "\e612";
}

.bt-cargo-icon-container-lifting:before {
  content: "\e613";
}

.bt-cargo-icon-container-hooked-up:before {
  content: "\e614";
}

.bt-cargo-icon-containers-on-ship:before {
  content: "\e615";
}

.bt-cargo-icon-crane:before {
  content: "\e616";
}

.bt-cargo-icon-crane-on-truck:before {
  content: "\e617";
}

.bt-cargo-icon-delivered-package:before {
  content: "\e618";
}

.bt-cargo-icon-delivery-time:before {
  content: "\e619";
}

.bt-cargo-icon-delivery-truck:before {
  content: "\e61a";
}

.bt-cargo-icon-delivery-truck-with-packages:before {
  content: "\e61b";
}

.bt-cargo-icon-delivery-package-umbrella:before {
  content: "\e61c";
}

.bt-cargo-icon-delivery-forklift:before {
  content: "\e61d";
}

.bt-cargo-icon-delivery-package-up:before {
  content: "\e61e";
}

.bt-cargo-icon-delivery-van-big:before {
  content: "\e61f";
}

.bt-cargo-icon-delivery-van-small:before {
  content: "\e620";
}

.bt-cargo-icon-delivery-package-unopened:before {
  content: "\e621";
}

.bt-cargo-icon-delivery-van-on-time:before {
  content: "\e622";
}

.bt-cargo-icon-delivery-package-in-hand:before {
  content: "\e623";
}

.bt-cargo-icon-delivery-package-every-way:before {
  content: "\e624";
}

.bt-cargo-icon-delivery-package-is-safe:before {
  content: "\e625";
}

.bt-cargo-icon-delivery-package-perspective:before {
  content: "\e626";
}

.bt-cargo-icon-delivery-package-out:before {
  content: "\e627";
}

.bt-cargo-icon-delivery-package-fragile:before {
  content: "\e628";
}

.bt-cargo-icon-delivery-package-this-way-up:before {
  content: "\e629";
}

.bt-cargo-icon-delivery-package-opened:before {
  content: "\e62a";
}

.bt-cargo-icon-delivery-package-weighted:before {
  content: "\e62b";
}

.bt-cargo-icon-delivery-two-persons:before {
  content: "\e62c";
}

.bt-cargo-icon-delivery-hand-forklifter:before {
  content: "\e62d";
}

.bt-cargo-icon-delivery-package-on-time:before {
  content: "\e62e";
}

.bt-cargo-icon-delivery-put-in-package:before {
  content: "\e62f";
}

.bt-cargo-icon-stopwatch:before {
  content: "\e630";
}

.bt-cargo-icon-fragile:before {
  content: "\e631";
}

.bt-cargo-icon-free-van:before {
  content: "\e632";
}

.bt-cargo-icon-truck-trailer-front:before {
  content: "\e633";
}

.bt-cargo-icon-identification-bar-code:before {
  content: "\e634";
}

.bt-cargo-icon-international-van-world:before {
  content: "\e635";
}

.bt-cargo-icon-international-world-map-marker:before {
  content: "\e636";
}

.bt-cargo-icon-international-all-over-the-world:before {
  content: "\e637";
}

.bt-cargo-icon-international-world-phone:before {
  content: "\e638";
}

.bt-cargo-icon-international-world-arrow:before {
  content: "\e639";
}

.bt-cargo-icon-compass:before {
  content: "\e63a";
}

.bt-cargo-icon-locked-package:before {
  content: "\e63b";
}

.bt-cargo-icon-measuring:before {
  content: "\e63c";
}

.bt-cargo-icon-forklifter:before {
  content: "\e63d";
}

.bt-cargo-icon-logistics-van-clock:before {
  content: "\e63e";
}

.bt-cargo-icon-logistics-fast-van:before {
  content: "\e63f";
}

.bt-cargo-icon-logistics-fan-forward:before {
  content: "\e640";
}

.bt-cargo-icon-logistics-package:before {
  content: "\e641";
}

.bt-cargo-icon-man-with-package:before {
  content: "\e642";
}

.bt-cargo-icon-ship-ocean-freight:before {
  content: "\e643";
}

.bt-cargo-icon-package-perspective-one-side:before {
  content: "\e644";
}

.bt-cargo-icon-package-opened:before {
  content: "\e645";
}

.bt-cargo-icon-package-transported:before {
  content: "\e646";
}

.bt-cargo-icon-package-being-moved:before {
  content: "\e647";
}

.bt-cargo-icon-package-in-hand:before {
  content: "\e648";
}

.bt-cargo-icon-package-on-hand-forklifter:before {
  content: "\e649";
}

.bt-cargo-icon-package-put-inside:before {
  content: "\e64a";
}

.bt-cargo-icon-packages-lots-of:before {
  content: "\e64b";
}

.bt-cargo-icon-packages-on-forklifter:before {
  content: "\e64c";
}

.bt-cargo-icon-person-with-package:before {
  content: "\e64d";
}

.bt-cargo-icon-phone-clock:before {
  content: "\e64e";
}

.bt-cargo-icon-phone-clock-bolder:before {
  content: "\e64f";
}

.bt-cargo-icon-placeholder-map:before {
  content: "\e650";
}

.bt-cargo-icon-protection-umbrella:before {
  content: "\e651";
}

.bt-cargo-icon-ship-side:before {
  content: "\e652";
}

.bt-cargo-icon-ship-with-containers:before {
  content: "\e653";
}

.bt-cargo-icon-search-package:before {
  content: "\e654";
}

.bt-cargo-icon-storage:before {
  content: "\e655";
}

.bt-cargo-icon-phone-talking:before {
  content: "\e656";
}

.bt-cargo-icon-telephone-picked-up:before {
  content: "\e657";
}

.bt-cargo-icon-telephone-hang-up:before {
  content: "\e658";
}

.bt-cargo-icon-three-packages:before {
  content: "\e659";
}

.bt-cargo-icon-train-front:before {
  content: "\e65a";
}

.bt-cargo-icon-recycle:before {
  content: "\e65b";
}

.bt-cargo-icon-up-sign:before {
  content: "\e65c";
}

.bt-cargo-icon-verification:before {
  content: "\e65d";
}

.bt-cargo-icon-view-package:before {
  content: "\e65e";
}

.bt-cargo-icon-weight:before {
  content: "\e65f";
}

.bt-cargo-icon-weight-kg-sign:before {
  content: "\e660";
}

.bt-cargo-icon-woman-headphones:before {
  content: "\e661";
}

.bt-cargo-icon-wooden-package:before {
  content: "\e662";
}

.bt-cargo-icon-world-map-pin:before {
  content: "\e663";
}

.bt-cargo-icon-arrow-prev:before {
  content: "\e664";
}

.bt-cargo-icon-arrow-next:before {
  content: "\e665";
}

.bt-cargo-icon-quotes:before {
  content: "\e666";
}

/* Gutenberg */

/* Separator */

.wp-block-separator {
  margin: 1em auto;
  max-width: 100px;
}

.wp-block-separator.is-style-wide {
  max-width: 100%;
}

.wp-block-separator:not(.is-style-wide):not(.is-style-dots) {
  border-bottom: 1px solid #8f98a1;
}

/* Paragraph */

p.has-drop-cap:not(:focus):first-letter {
  float: left;
  font-size: 0;
  line-height: .7;
  font-weight: 500;
}

p.has-drop-cap {
  line-height: 1.2;
}

p.has-background {
  border-radius: 0;
}

p.has-large-font-size {
  line-height: 1.2;
}

em {
  line-height: 1;
}

/* Quote, Pullquote */

.wp-block-quote p {
  font-size: 1em;
}

.wp-block-quote.is-large p,
.wp-block-quote.is-style-large p {
  font-size: 1.3em;
}

.wp-block-quote.is-large cite,
.wp-block-quote.is-style-large cite {
  text-align: center;
}

.wp-block-pullquote.alignleft p,
.wp-block-pullquote.alignright p {
  font-size: inherit;
}

/* Button */

.wp-block-file .wp-block-file__button {
  border-radius: none;
}

.wp-block-button__link {
  font-size: .8em;
  padding: .8em 2.2em;
  font-weight: 500;
  color: #fff !important;
}

.wp-block-button__link:hover {
  color: var(--secondry-color) !important;
}

.wp-block-button {
  margin-bottom: 1em !important;
}

.wp-block-button.alignnone {
  text-align: inherit !important;
}

/* Table */

.wp-block-table.is-style-stripes td {
  border-color: rgba(0, 0, 0, .07);
}

/* Page width */

.btNoSidebar .alignfull {
  width: 100vw;
  margin-left: -webkit-calc(-50vw + 0);
  margin-left: -moz-calc(-50vw + 0);
  margin-left: calc(-50vw + 0);
  margin-right: -webkit-calc(-50vw + 0);
  margin-right: -moz-calc(-50vw + 0);
  margin-right: calc(-50vw + 0);
  max-width: 100vw;
}

/* Gallery */

.wp-block-gallery {
  margin: 0;
}



/* cargo css end */








/* @media only screen and (min-width: 1024px) and (max-width: 1440px) {


  .login-card {
    position: absolute;
    top: 5em !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !;
    margin: 0 !important;
    background-color: white;
    border-radius: 0 !important;
    height: 80vh !important;
    padding: 0px !important;
  }
  .login-right-container .carousel-item img {
    height: 68vh !important;
  }
  } */



@media only screen and (min-width: 768px) and (max-width: 1020px) {


  .login-card {
    position: absolute;
    top: 4em !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !;
    margin: 0 !important;
    background-color: white;
    border-radius: 30px !important;
    height: 80vh !important;
    padding: 0px !important;
  }

  .login-right-container .carousel-item img {
    height: 68vh !important;
  }

  .dropdown-menu {
    width: 100% !important;
  }

  .nav-link {
    padding: 1rem .7rem !important;
    background: var(--primary-color) !important;
    color: white !important;
    width: 100%;
  }

  .top-insta-dropdown-style {
    width: 18em !important;
  }
}

/*------------------------------ media query for For typical mobile devices start---------------------- */
@media only screen and (min-width: 480px) and (max-width: 767px) {

  .login-right-container {
    display: none !important;
  }

  .login-card {
    position: absolute;
    top: 1em;
    left: 0 !important;
    right: 0 !important;
    width: 100% !;
    margin: 0 !important;
    background-color: white;
    border-radius: 0 !important;
    height: 100vh !important;
    padding: 0px !important;
  }

  .dropdown-menu {
    width: 100% !important;
  }

  .btSiteFooterCurve {
    display: block;
  }

  .nav-link {
    padding: 1rem .7rem !important;
    background: var(--primary-color) !important;
    color: white !important;
    width: 100%;
  }
}

/*------------------------------ media query for For typical mobile devices start---------------------- */
@media only screen and (min-width: 200px) and (max-width: 479px) {
  .top-header {
    display: block !important;
    justify-content: flex-start;
    margin-bottom: 10px !important;

  }

  .right-item {
    display: block !important;
    justify-content: space-between;

  }

  .social-media-section {
    justify-content: space-evenly !important;
  }

  nav {
    height: 64px !important;
  }

  .nav-link {
    padding: 1rem .7rem !important;
    background: var(--primary-color) !important;
    color: white !important;
    width: 100%;
  }

  .home-video-section {
    height: auto !important;
  }

  .btTextLeft .btIco .btIcoHolder span {
    font-size: 12px !important;
  }


  .slick-slide img {
    height: 100% !important;
  }


  .left-item {
    margin-bottom: 8px !important;
  }

  .image-gallery-grid {
    position: relative;
    height: 630px;
  }

  .image-gallery-grid .image-gallery-grid-one {
    position: absolute !important;
    width: 100% !important;
    left: 0 !important;
  }

  .image-gallery-grid .image-gallery-grid-second {
    position: absolute !important;
    width: 100% !important;
    left: 0 !important;
  }

  .image-gallery-grid .image-gallery-grid-third {
    position: absolute !important;
    width: 100% !important;
    left: 0 !important;
  }

  .image-gallery-grid .image-gallery-grid-four {
    position: absolute !important;
    width: 100% !important;
    left: 0 !important;
  }

  .login-right-container {
    display: none !important;
  }

  .login-card {
    position: absolute;
    top: 1em;
    left: 0 !important;
    right: 0 !important;
    width: 100% !;
    margin: 0 !important;
    background-color: white;
    border-radius: 0 !important;
    height: 100vh !important;
    padding: 0px !important;
  }

  .dropdown-menu {
    width: 100% !important;
  }

  .footer-playstore-icon img {
    height: 30px;
    width: 85px !important;

  }

  .btSiteFooterWidgets .btBox h4 {
    font-size: 14px !important;
  }

  .btSiteFooterWidgets .btBox a {
    font-size: 11px !important;
  }

  .topHeaderBtn button {
    font-size: 7px !important;
    padding: 6px 22px 6px 29px !important;
  }

  .fa-calculator {
    top: 3px !important;
  }

  .scrollShow {
    padding: 12px !important;
    width: 38px !important;
    font-size: 18px !important;
    height: 36px !important;
  }

  .left-item {
    justify-content: flex-start !important;
  }

  .btSiteFooterCurve {
    display: block;
  }

  .checked-item,
  .not-checked-item {
    width: 60vw !important;
    margin: 5px;
  }

  .nav-link,
  .dropdown-item {
    font-size: 14px !important;
  }

  .pages-faq .accordion-button {
    padding: .5rem 1.25rem !important;
  }

  .top-insta-dropdown-style {
    width: 18em !important;
    right: -15em !important;
  }
  .hide-sm{
    display: none !important;
  }

}